import {_} from "vue-underscore";
import {planungsdatenMutations} from "../../obsvervables/planungsdaten";
import httpClient from "../../utils/httpClient";

const state = {
    sortableProducts: null
}

const getters = {
    sortableProducts: (state) => {
        return state.sortableProducts
    }
}

const actions = {
    loadSortableProducts({commit}) {
        httpClient({
            url: process.env.VUE_APP_API_URL + 'tooladmin/product/sorting',
            method: 'GET'
        }).then(function (response) {
            commit('setSortableProducts', response.data)
        })
    },

    updateSortableProducts({commit}, list) {
        httpClient({
            url: process.env.VUE_APP_API_URL + 'tooladmin/product/sorting',
            method: 'POST',
            data: { list: list}
        }).then(function (response) {
            commit('setSortableProducts', response.data)
        })
    },

    resetSortableProduct({commit}, ArtikelNrLAG) {
        httpClient({
            url: process.env.VUE_APP_API_URL + 'tooladmin/product/sorting/' + ArtikelNrLAG,
            method: 'DELETE',
        }).then(function (response) {
            commit('setSortableProducts', response.data)
        })
    }
}

const mutations = {
    setSortableProducts(state, products) {
        state.sortableProducts = products;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}