<template>
  <div class="barometer-container">
    <div class="barometer">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span class="barometer-max-ist" v-bind="attrs" v-on="on">{{ maxIst }}</span>
        </template>
        <span>Jahrespauschale</span>
      </v-tooltip>
      <v-tooltip top v-if="!isWithinAllowance">
        <template v-slot:activator="{ on, attrs }" >
          <div class="barometer-ist" :style="borometerIstStyle" v-bind="attrs" v-on="on">
            <span v-if="!isTodayWithinAllowance">
              {{ (ist - allowance).toFixed(2) }}
            </span>
          </div>
        </template>
        <span>Vom Bewohner zu übernehmen</span>
      </v-tooltip>
      <div class="barometer-indicator" :style="indicatorStyle" v-if="isCurrentYear"></div>
      <div class="barometer-ist-value" :style="borometerIstValueStyle">
        {{ allowance.toFixed(2) }}
      </div>
      <div class="barometer-over-value" v-if="!isWithinAllowance">
        {{ costOverAllowance }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarometerBar',

  props: {
    ist: {
      type: Number,
      required: true
    },

    cost: {
      type: Number,
      required: true
    },

    allowance: {
      type: Number,
      required: true
    },

    year: {
      type: Number,
      required: true
    }
  },

  computed: {
    isCurrentYear() {
      return this.year === +this.moment().year()
    },

    isWithinAllowance() {
      return this.cost <= this.allowance
    },

    isTodayWithinAllowance() {
      return this.allowance < this.cost
    },

    maxIst () {
      return this.ist <= this.allowance? this.ist: this.allowance
    },

    costOverAllowance() {
      return ((this.allowance - this.cost) * -1).toFixed(2)
    },

    borometerIstStyle() {
      let width = 100 / this.cost * (this.cost - this.allowance);
      let style =  'width: ' + width  + '%;';

      if(width >= 99) {
        style += 'border-top-left-radius: 10px;border-bottom-left-radius: 10px;'
      }

      return style;
    },

    borometerIstValueStyle() {
      if(this.isWithinAllowance) {
        return 'right: 0;'
      }

      let percentage = 100 / this.cost * (this.cost - this.allowance)

      let style = 'right: ' + percentage + '%;'

      if(percentage < 5) {
        style += 'padding-right: 20px;'
      }

      return style;
    },

    indicatorStyle() {
      return 'left: ' + 100 / 365 * this.moment().dayOfYear() + '%;';
    }
  }
}
</script>

<style lang="scss" scoped>
.barometer {
  position: relative;
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 13px;
  background-color: #7ab95e;

  .barometer-max-ist {
    display: inline-block;
    width: 100%;
    text-align: left;
  }

  .barometer-ist {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 20%;
    padding: 0 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #ffffff;
    text-align: right;
    background-color: #d32630;
  }

  .barometer-indicator {
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 5px 0 5px;
    border-color: #000000 transparent transparent transparent;
  }

  .barometer-ist-value {
    position: absolute;
    bottom: -25px;
    height: 20px;
    color: #7ab95e;
    text-align: left;
  }

  .barometer-over-value {
    position: absolute;
    bottom: -25px;
    right: 0;
    height: 20px;
    color: #d32630;
  }
}
</style>