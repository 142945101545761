<template>
  <v-dialog v-model="showModal" persistent max-width="1180" id="calmodal">
    <v-card flat>
      <v-spacer></v-spacer>
      <v-container>
        <v-card-text class="body-2">
          <v-sheet
              height="45"
              class="d-flex justify-center"
          >
            <v-btn
                icon
                @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="text--primary text-h5 pt-2" style="min-width: 300px; text-align: center">{{ months[new Date(value).getMonth()] }} {{ new Date(value).getFullYear() }} </span>
            <v-btn
                icon
                @click="$refs.calendar.next()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet height="40" class="d-flex justify-center">
            <span v-for="(st,index) in selectedTypes" class="mr-2">
                 <v-switch
                     v-model="selectedTyp[index]"

                     :label="st.name"
                     :true-value="st.id"
                     :false-value="false"
                     :color="st.color"
                     class="text-sm-body-1 mt-0"
                     dense
                     small
                     light
                     flat
                 >
                    <template v-slot:label="">
                      <span class="caption small">
                          <v-icon size="17" color="#ccc">{{ st.icon }}</v-icon> {{ st.name }}
                      </span>
                    </template>
                 </v-switch>
            </span>
          </v-sheet>
          <v-sheet height="650">
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                locale="ch-de"
                :type="type"
                color="primary"
                 event-overlap-mode="column"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
            >

              <template v-slot:day="{ date }">
                <template v-for="(event,index) in orderCalendarData[date]">
                  <v-menu
                      :key="event.title+'-'+index"
                      offset-x
                      @click="openPop(index)"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          v-if="!event.time"
                          v-ripple
                          class="my-event cursor-pointer"
                          v-on="on"
                          :style="'background-color:'+event.color"
                      >
                        <v-icon v-if="event.status=='annahme'" color="white" class="pl-2 pr-0" small>mdi-help-circle</v-icon>
                        <v-icon color="white" class="pl-2" small>{{event.icon}}</v-icon>
                        <span v-if="event.entryType != 'holiday'" class="text-body-2 white--text text--white">
                           {{event.title}} WH | {{ event.kunden.length }} K
                        </span>
                        <span v-else class="text-body-2 white--text text--white">
                          Feiertag
                        </span>
                      </div>
                    </template>
                    <v-card
                        min-width="250px"
                        flat
                    >
                      <v-toolbar
                          :style="'background-color:'+event.color"
                          dark
                          dense
                          flat
                          height="30"
                      >
                        <v-toolbar-title v-if="event.entryType != 'holiday'" class="text-body-2">  {{event.title }} Wohneinheiten | {{ event.kunden.length }} Kunden</v-toolbar-title>
                        <v-toolbar-title v-else class="text-body-2">Feiertag</v-toolbar-title>

                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-title primary-title style="max-height: 600px; overflow: auto">
                        <span class="text-body-2" v-html="event.details"></span>
                      </v-card-title>
                    </v-card>
                  </v-menu>
                </template>
              </template>

            </v-calendar>
          </v-sheet>
        </v-card-text>
      </v-container>
      <v-card-actions class="mr-2 pb-4 justify-end">
        <v-row class="mt-0 ml-4">
          <v-col cols="9">

          </v-col>
          <v-col cols="3">
            <span class="float-right">
                <v-btn class="primary mr-2" small depressed @click="value = new Date()">heute</v-btn>
                <v-btn class="primary mr-3"  small depressed @click="closeAllPops();">schliessen</v-btn>
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import httpClient from "@/utils/httpClient";
export default {
  data(){
    return {
      orderPreviewData: [],
      orderCalendar: [],

      selectedTyp: ['bestell-regular','bestell-annahme','liefer-regular','liefer-annahme'],
      selectedTypes: [{'name': 'Bestellung (Definitiv)','id': 'bestell-regular', 'color': '#1cacf3','icon':'mdi-cart-check'},
        {'name': 'Bestellung (Annahme)','id': 'bestell-annahme','color':'#a6dcf7','icon':'mdi-cart-check'},
        {'name': 'Lieferung (Definitiv)','id': 'liefer-regular','color': '#f38b1c','icon':'mdi-truck-check'},
        {'name': 'Lieferung (Annahme)','id': 'liefer-annahme','color':'#f3d4b3','icon':'mdi-truck-check'}],
      months:['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
      //Cal
      type: 'month',
      types: [
          {
            'name': 'Monat',
            'id': 'month'
          },
        {
          'name': 'Woche',
          'id': 'week'
        }
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      value: new Date(),
      events: [],
    }
  },
  name: 'OrderPreview',
  props:['showModal'],

  computed:{
    orderCalendarData(){
      var res = [];

      var filterTypes = [];
      this.selectedTyp.forEach(function(t,i){
        filterTypes.push(t);
      });

      for(var i = 0; i <  this.orderCalendar.length; i++){

        if(
            filterTypes.indexOf(this.orderCalendar[i].frontendFilter) != -1 ||
            this.orderCalendar[i].entryType == 'holiday'
        ) {
          res.push({
            'date': this.orderCalendar[i].date,
            'title': this.orderCalendar[i].title + '',
            'icon': this.orderCalendar[i].icon,
            'open': this.orderCalendar[i].open,
            'status': this.orderCalendar[i].status,
            'kunden': this.orderCalendar[i].kunden,
            'entryType': this.orderCalendar[i].entryType,
            'details': this.orderCalendar[i].entryType == 'holiday' ? this.orderCalendar[i].details : '<table cellpadding="3" cellspacing="12"><tr><td><b class="heavy caption">Kunde</b></td><td><b class="heavy caption">Wohneinheit</b></td><td><b class="heavy caption">Intervall</b></td>' + this.orderCalendar[i].details + '</table>',
            'color': this.orderCalendar[i].color
          })
        }
      }

      const map = {}
      res.forEach(e => (map[e.date] = map[e.date] || []).push(e))
      return map

    }
  },

  methods: {
    delayFunc(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    openPop(index){
      this.orderCalendar.forEach((e,i) => (this.orderCalendar[i].open = false));
      this.orderCalendar[index].open = true;
    },

     async closeAllPops(){

      this.orderCalendar.forEach((e,i) => (this.orderCalendar[i].open = false));
      //this.orderCalendar.forEach((e,i) => (console.log(this.orderCalendar[i].open )));

      //await this.delayFunc(500);

      this.$emit('closeOrderPreview');

    },

    closeAllCalPops(){
      this.orderCalendar.forEach((e,i) => (this.orderCalendar[i].open = false));
    },

    async getOrderData(){
      const res = await httpClient({
        url: process.env.VUE_APP_API_URL+'get/orderpreview',
        method: 'get',
      })

      if(res.type === 'success'){
        this.orderPreviewData = res.orderpreview;
        this.orderCalendar = res.ordercalendar;
      }
    },

    getEventColor (event) {
      return event.color
    },

  },
  mounted() {
    this.getOrderData();
  }

}
</script>
<style lang="scss">
.my-event{
  padding-top: 3px;
  margin-bottom: 2px;
  border-radius: 4px;
}
.theme--light.v-input--switch .v-input--switch__thumb{
  color: #eee;
}

#pdrows{
  *{
    font-size: 13px;
  }

  .row:nth-child(even){
    background: #f5f3f3;
  }
  .row:nth-child(odd){
    background:#fff;
  }
}
.status{
  text-transform: capitalize !important;;
}
</style>
