import Vue from 'vue'
import httpClient from "@/utils/httpClient";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const produkteState = Vue.observable({
    "produkte": [],
    "deletedProdukte": [],
    "produktbilder": []
});

export const produkteGetters = {
    produkte: () => produkteState.produkte,
    deletedProdukte: () => produkteState.deletedProdukte,
    produktbilder: () => produkteState.produktbilder
}

export const produkteMutations = {
    setProdukte(produkte) {
        produkteState.produkte = produkte;
    },
    setDeletedProdukte(produkte) {
        produkteState.deletedProdukte = produkte
    },
    setProduktBilder(produktbilder) {
        produkteState.produktbilder = produktbilder;
    }
}

export const produkteActions = {
    fetchProdukte(){
        if(produkteState.produkte.length < 1){
            httpClient({
                url: process.env.VUE_APP_API_URL+'get/produkte',
                method: 'GET'
            }).then(function (res) {
                produkteMutations.setProdukte(res.produkte)
            })
        }
    },

    fetchDeletedProdukte(){
        if(produkteState.deletedProdukte.length < 1){
            httpClient({
                url: process.env.VUE_APP_API_URL+'get/deleted-produkte',
                method: 'GET'
            }).then(function (res) {
                produkteMutations.setDeletedProdukte(res.produkte)
            })
        }
    },

    async fetchProduktBilder(){
        if(produkteState.produktbilder.length < 1){
            let res = await httpClient({
                url: process.env.VUE_APP_API_BASEURL+'get/produktbilder',
                method: 'GET'
            })
            produkteMutations.setProduktBilder(res.produktbilder);
            return res.produktbilder;
        }
    },
}

export default {
    produkteState,
    produkteGetters,
    produkteMutations,
    produkteActions
}