import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {bewohnerMutations} from "@/obsvervables/bewohner";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const wohneinheitenState = Vue.observable({
    "wohneinheiten": []
});

export const wohneinheitenGetters = {
    wohneinheiten: () => wohneinheitenState.wohneinheiten
}

export const wohneinheitenMutations = {
    setWohneinheiten(wohneinheiten) {
        wohneinheitenState.wohneinheiten = wohneinheiten;
    },

    addWohneinheit(){

        let newWH =  wohneinheitenState.wohneinheiten.filter(function(el){
            return el.id == '-1';
        });

        if(newWH.length < 1){
            wohneinheitenState.wohneinheiten.unshift(
                {
                    'name': '',
                    'AdressNrADR': this.$store.state['benutzer'].AdressNrADR,
                    'Firma': '',
                    'Adresszeile1': '',
                    'Adresszeile2': '',
                    'Strasse': '',
                    'PLZ': '',
                    'Ort': '',
                    'id': '-1'
                }
            )
        }
    }
}

export const wohneinheitenActions = {
    async fetchWohneinheiten(){
        if(wohneinheitenState.wohneinheiten.length <= 0) {
            let res = await httpClient({
                url: process.env.VUE_APP_API_URL + 'get/wohneinheiten',
                method: 'GET'
            })

            wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
        }
    },

    deleteWohneinheit(whID){
        httpClient({
            url: process.env.VUE_APP_API_URL+'delete/wohneinheit',
            method: 'post',
            data: {'whid':whID }
        }).then(function (res) {
            if(res.type === 'success'){
                wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
            }
        })
    },

    saveWohneinheiten(){
        httpClient({
            url: process.env.VUE_APP_API_URL+'save/wohneinheiten',
            method: 'post',
            data: { 'wohneinheiten': wohneinheitenState.wohneinheiten }
        }).then(function (res) {
            if(res.type === 'success'){
                wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
            }
        })
    }
}

export default {
    wohneinheitenState,
    wohneinheitenGetters,
    wohneinheitenMutations,
    wohneinheitenActions
}