<template>
  <div class="custom-box px-12 mb-16">
    <v-row v-if="history.length === 0">
      <v-col cols="10" class="pb-0 pl-0">
        <p class="fs-18 mb-0 font-weight-bold">Pauschalbetrag für Fixierhosen und Krankenunterlagen</p>
      </v-col>
    </v-row>

    <v-row v-if="history.length > 0">
      <v-col cols="6" class="font-weight-bold pl-0">
        Pauschalbetrag für Fixierhosen und Krankenunterlagen
      </v-col>
      <v-col cols="2" class="font-weight-bold">
        von
      </v-col>
      <v-col cols="2" class="font-weight-bold">
        bis
      </v-col>
    </v-row>
    <template v-for="(item,index) in history" v-if="history.length > 0">
      <v-row v-if="history.length > 0" class="b-top" :class="index === history.length -1 ? 'b-bottom' : ''">
        <v-col cols="6" class="pl-0">
          <template v-if="item.monthly_budget > 0">
            {{ formattedMonthlyBudget(item.monthly_budget) }} CHF pro Monat
          </template>
          <template v-else>
            Keine Pauschale
          </template>
        </v-col>
        <v-col cols="2" :class="item.inkograd === 5 ? 'pl-2' : ''">
          {{ asMoment(item.start).format('MMM YYYY') }}
        </v-col>
        <v-col cols="2" :class="item.inkograd === 5 ? 'pl-2' : ''">
          {{ item.end ? asMoment(item.end).format('MMM YYYY') : '–' }}
        </v-col>
        <div class="flex-grow-1">
          <div class="float-right pt-3 pr-2">
            <ConfirmDialog class="float-right" title="Eintrag löschen" @confirm="del(item)">
              <template #button>
                <v-hover v-slot="{ hover }">
                  <v-img
                      :src="hover ? require('@/assets/img/ico/loeschen-hover2.svg') : require('@/assets/img/ico/loeschen.svg')"
                      width="20" class="icon"></v-img>
                </v-hover>
              </template>
              Möchten Sie diesen Eintrag wirklich löschen?
            </ConfirmDialog>
          </div>
        </div>
      </v-row>
      <v-row v-if="item.inkograd === 5" class="bg-white custom-box pb-0 pl-0 pt-0 pr-0 mb-4"
             style="border-radius: 0 0  15px 15px"
             :style="item.inkograd === 5 ? 'margin-left: -18px; width: calc(100% + 34px) !important; padding-right: 15px !important; margin-right: -5px !important;' : ''">
        <v-col>
          <Kostengutsprache :allow-kostengutsprache="true"
                            :min-date="asMoment(item.start)" :inkograd="5"
                            :form-values="formValues"/>
        </v-col>
      </v-row>
    </template>


    <v-row>
      <v-col cols="5" class="pt-4">
        <FixierhosenBudgetDialog @save="add($event)" :bewohner="formValues"
                                 :last-data="history.length > 0 ? {monthly_budget: history[history.length-1].monthly_budget, start:history[history.length-1].start} : null"
                                 :minDate="history.length > 0 ? asMoment(history[history.length-1].start).add(1,'Month') : false">
          <template #button="slotProps">
            <div class="add-icon-container cursor-pointer flex d-flex" @click="slotProps.open()">
              <div>
                <v-btn small fab light depressed color="primary" class="addbtn" style="margin-left: -18px;">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <div style="margin-top: 1px">
                <div class="text px-4 font-weight-bold" style="line-height: 21px; font-size: 14px" v-if="history.length > 0">Änderung<br>erfassen</div>
                <div class="text px-4 font-weight-bold"  style="line-height: 21px; font-size: 14px" v-else>Pauschale<br>erfassen</div>
              </div>
            </div>
          </template>
        </FixierhosenBudgetDialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FixierhosenBudgetDialog from '@/components/app/bewohner/Bewohnerverwaltung/dialogs/FixierhosenBudgetDialog.vue'
import { fixierhosenBudgetActions, fixierhosenBudgetGetters, fixierhosenBudgetMutations } from '@/obsvervables/fixierhosenBudget'
import moment from 'moment/moment'
import Kostengutsprache from '@/components/app/bewohner/Bewohnerverwaltung/steps/Kostengutsprache.vue'
import ConfirmDialog from '@/components/app/bewohner/Bewohnerverwaltung/dialogs/ConfirmDialog.vue'
import InkoGradIndicator from '@/components/app/InkoGrad/InkoGradIndicator.vue'

export default {
  name: 'FixierhosenBudget',
  components: { InkoGradIndicator, ConfirmDialog, Kostengutsprache, FixierhosenBudgetDialog },
  props: {
    formValues: {
      required: true
    },
  },
  computed: {
    ...fixierhosenBudgetGetters,
    loading: {
      get () {
        return (this.bewohnerFixierhosenBudgetHistory === null) ? true : false
      }
    },
    history: {
      get () {
        let history = []

        if (this.bewohnerFixierhosenBudgetHistory) {
          for (let i = 0; i < this.bewohnerFixierhosenBudgetHistory.length; i++) {
            let month = {
              id: this.bewohnerFixierhosenBudgetHistory[i].id,
              start: moment(this.bewohnerFixierhosenBudgetHistory[i].start),
              end: this.bewohnerFixierhosenBudgetHistory[i].end ? this.bewohnerFixierhosenBudgetHistory[i].end : null,
              monthly_budget: this.bewohnerFixierhosenBudgetHistory[i].monthly_budget
            }

            if (this.bewohnerFixierhosenBudgetHistory[i].end) {
              month.end = moment(this.bewohnerFixierhosenBudgetHistory[i].end)
            } else if (this.bewohnerFixierhosenBudgetHistory[i + 1]) {
              month.end = moment(this.bewohnerFixierhosenBudgetHistory[i + 1].start).subtract(1, 'months')
            }

            history.push(month)
          }
        }
        /*if (history.length > 0) {
          this.formValues.inkograd_start = null;
        }*/
        return history
      }
    }

  },
  methods: {
    ...fixierhosenBudgetActions,

    formattedMonthlyBudget (budget) {

      try {
        return budget.toFixed(2)
      } catch (e) {
        return budget
      }

    },

    add (data) {
      this.addFixierhosenBudgetEntry(data).then((res) => {

        this.$emit('updateFixierhose')
      })
    },

    del (data) {
      this.deleteFixierhosenBudgetEntry(data.id).then((res) => {
        this.$emit('updateFixierhose')
      })
    },

    asMoment (date = null, locale = 'de') {
      return date ? moment(date).locale(locale) : moment().locale(locale)
    },

    /*setDate(date) {
      this.formValues.inkograd_start = moment(date, "YYYY-MM", false).format("YYYY-MM-DD");
    }*/
  }
}
</script>

<style scoped lang="scss">

</style>

