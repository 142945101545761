<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="action-icon">
        <v-hover v-slot="{ hover }" open-delay="0">
          <span v-bind="attrs" v-on="on" class="cursor-pointer text-center align-center">
              <v-img :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')" alt="" max-width="35" class="cursor-pointer"></v-img>
              <div class="block pt-0 caption text-center small">
                 Inko-Barometer
              </div>
            </span>
        </v-hover>
      </div>
    </template>

    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col cols="11">
            Inko-Barometer drucken
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="body-2 multiprintpopup">
        Wählen Sie bitte die gewünschten Bewohner um mehrere Inko-Barometer zu drucken
        <v-divider class="my-4"></v-divider>

        <v-row>
          <v-col class="py-0">
            <v-combobox
                v-model="selectedWohneinheit"
                :items="wohneinheiten"
                item-text="name"
                label="Wohneinheit"
                v-if="wohneinheiten"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-divider class="my-4"></v-divider>

        <v-row>
          <v-col sm class="py-0 my-0" cols="10">
            <v-checkbox v-model="allBewohner" label="Alle auswählen" dense></v-checkbox>
          </v-col>
        </v-row>


        <v-row dense v-for="barometer in activeWohneinheitBarometers" :key="'mdnprint_'+barometer.bewohner.bewohnerID">
          <v-col sm class="py-0 my-0" cols="10">
            <v-checkbox v-model="selectedBewohnerIds" :label="barometer.bewohner.vorname+' '+barometer.bewohner.name" :value="barometer.bewohner.bewohnerID" dense></v-checkbox>
          </v-col>
          <v-col sm class="py-0 my-0" cols="2">
            {{ barometer.bewohner.zimmer }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="dialog = false;">Abbrechen</v-btn>
        <v-btn color="primary" depressed @click="print" :loading="printing">Drucken</v-btn>
      </v-card-actions>
    </v-card>
    <vue-html-2-pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="inko-barometer"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1015px"
        ref="barometerPDF">
      <section slot="pdf-content">
        <PrintContentBarometer :barometers="selectedBarometers"/>
      </section>
    </vue-html-2-pdf>

  </v-dialog>
</template>

<script>
import {barometerGetters, barometerMutations} from "../../../obsvervables/barometer";
import VueHtml2Pdf from "@/components/app/print/VueHtml2Pdf";
import PrintContentBarometer from "../print/PrintContentBarometer";
import {benutzerGetters, benutzerMutations} from "../../../obsvervables/benutzer";
import {wohneinheitenGetters} from "../../../obsvervables/wohneinheiten";

export default {
  name: 'BarometerPrintModalDialog',
  components: {PrintContentBarometer, VueHtml2Pdf},

  data() {
    return {
      dialog: false,
      printing: false,
      allBewohner: false,
      selectedBewohnerIds: [],
    }
  },

  computed: {
    ...barometerGetters,
    ...barometerMutations,
    ...benutzerGetters,
    ...wohneinheitenGetters,

    selectedWohneinheit: {
      get() {
        if (this.wohneinheiten[0] !== undefined && !this.activeWohneinheit) {
          benutzerMutations.setActiveWohneinheit(this.wohneinheiten[0].id)
        }

        return this.wohneinheiten.find(w => w.id === this.activeWohneinheit)
      },
      set(wh) {
        benutzerMutations.setActiveWohneinheit(wh.id)
      }
    },

    selectedBarometers() {
      return this.activeWohneinheitBarometers.filter(b => this.selectedBewohnerIds.includes(b.bewohner.bewohnerID))
    }
  },
  mounted() {
    // setTimeout(() => {
    //   console.log('mounted')
    //   console.log(this.activeWohneinheit)
    //   console.log(this.activeWohneinheitBarometers)
    //
    // }, 500)
  },

  methods: {
    print() {
      this.printing = true
      // pdf generation needs to be deleted otherwise the loading status will be lagging
      setTimeout(function () {
        this.generatePDF().then(() => this.printing = false)
      }.bind(this), 30)
    },

    async generatePDF() {
      await this.$refs.barometerPDF.generatePdf();
    }
  },

  watch: {
    allBewohner() {
      // reset selected bewohner ids
      this.selectedBewohnerIds = []

      // select all if needed
      if (this.allBewohner) {
        this.activeWohneinheitBarometers.forEach(barometer => {
          this.selectedBewohnerIds.push(barometer.bewohner.bewohnerID)
        })
      }
    },

    dialog() {
      if (!this.dialog) {
        this.$refs.barometerPDF.closePreview()
        this.allBewohner = false
        this.selectedBewohnerIds = []
      }
    }
  }

}
</script>

<style scoped>

</style>
