<template>
  <v-app>
    <Loader :visible="loading"/>
    <v-app-bar height="116" app extension-height="120" color="white" v-if="isLoggedIn() && this.$route.name != 'login'"
               style="z-index: 7">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/planung">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align" style="background-color: white">
                <div>
                  <router-link to="/planung" class="kunden-name" :class="{'small-text': kunde.NameFirma != undefined && kunde.NameFirma.length > 30}">
                    {{ kunde.NameFirma }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
          </v-col>
          <v-col cols="5">
            <span class="float-right">
              <router-link to="/planung">
                <v-img :src="require('@/assets/img/logo-inkopro.svg')" alt="" width="144" height="72"
                       class="mt-1"></v-img>
              </router-link>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="d-none d-lg-flex"  :style="wrongSettings ? 'display:none !important' : '' " :class="this.$route.name != 'login' ? 'pb-16' : 'pb-0'">
      <router-view :superadmin="benutzer.superadmin" :kunde="kunde.NameFirma" :style="$router.currentRoute.name != 'login' ? 'margin:0 110px' : ''" />
      <v-layout>
        <ProductNavigation ref="prodnavcomp" v-if="isLoggedIn() && this.$route.name == 'planung'"></ProductNavigation>
        <RightNavigation v-if="isLoggedIn()  && this.$route.name != 'login'" :superadmin="benutzer.superadmin" :tooladmin="benutzer.tooladmin"></RightNavigation>
      </v-layout>
    </v-main>
    <div id="settingserror" class="d-lg-none d-print-none app-error px-8" style="padding-top: 100px" :style="wrongSettings ? 'display:block !important' : '' ">
      <v-container>
        <v-row>
          <v-col>
            <v-img width="144" height="72" :src="require('@/assets/img/logo-inkopro.svg')" alt="" class="mt-16 my-0 py-0"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="mb-2">
              Warnung
            </h2>
            <div style="font-size: 20px">
              Um mit Puras Inko Pro zu arbeiten, verwenden Sie bitte einen grösseren Bildschirm<span v-if="wrongSettings"> und einen der folgenden Browser: Chrome, Firefox, Safari, Edge, Brave</span>.<br><br>
              <span class="caption" style="font-size: 14px !important">Puras AG – Normannenstrasse 8 – 3018 Bern –
              Telefon 031 996 85 85 –
                <a href="mailto:contact@puras.ch">contact@puras.ch</a></span>
            </div>
          </v-col>
        </v-row>
        <!--<v-row  class="mt-1">
          <v-col class="mt-1">
            <div class="secondary px-12 py-2" style="max-width: 740px;">
              <v-row>
                <v-col cols="5" class="text-h5" style="font-weight: 300">
                  <div class="pt-8">
                    Haben Sie Fragen?<br>Wir unterstützen Sie.
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-img class="rounded-circle" :src="require('@/assets/img/heike-gail-puras.jpg')" width="130" ></v-img>
                </v-col>
                <v-col cols="3" class="text-lg-body-1 pt-6">
                  <b>Heike Gail</b><br>
                  Kontinenzberaterin<br>
                  031 996 85 83<br>
                  <a href="mailto:Heike.Gail@puras.ch">
                    <v-hover v-slot="{ hover }" open-delay="0" >
                      <v-img class="mt-2" :class="hover ? 'opacity-50' : ''" :src="require('@/assets/img/mail.svg')" width="30" ></v-img>
                    </v-hover>
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>-->
      </v-container>
    </div>
    <v-snackbar v-model="showmsg"  timeout="3500"  color="secondary" class="black--text">
      <v-row dense>
        <v-col cols="1">
          <v-icon size="30" v-if="msgtype == 'error'"  color="error" class="pt-2">mdi-alert</v-icon>
          <v-icon size="30" v-if="msgtype == 'success'" color="green" class="pt-0">mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="10" class="body-1" >
          <div class="pl-4 pt-1 block black--text">
             {{ msg }}
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-dialog v-model="showLock" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30"  color="primary" class="pr-8">mdi-lock</v-icon>
            </v-col>
            <v-col cols="11" class="pl-8">
              Achtung Daten wurden<br> NICHT geändert!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          Es ist zur Zeit mindestens ein Planungs-Warenkorb aktiv. Nimm bitte die geplanten Warenkörbe zurück, um Daten in der Planung ändern zu können. Danach muss der Warenkorb wieder neu aktiviert werden.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed  @click="showLock = false;">Ok, Danke</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
  import RightNavigation from "@/components/layout/RightNavigation";
  import ProductNavigation from "@/components/layout/ProductNavigation";
  import Loader from "@/components/layout/Loader";
  import { mapState } from "vuex";
  import { isLoggedIn } from '@/utils/auth';
  import {kundeActions, kundeGetters} from "@/obsvervables/kunde";
  import {produkteActions, produkteGetters} from "@/obsvervables/produkte";
  import {benutzerGetters} from "@/obsvervables/benutzer";

  export default {



    name: 'App',
    components:{
      RightNavigation,
      ProductNavigation,
      Loader,

    },
    computed: {
      ...mapState("loader", ["loading",'msg','msgtype']),
      ...mapState("planungslock", ['showLockWarning']),
      ...kundeGetters,
      ...benutzerGetters,
      ...produkteGetters,

      wrongSettings(){
        return this.$browserDetect.isIE || this.$browserDetect.isOpera;
      },

      showmsg:{
        get () {
          return this.$store.state['loader'].showmsg
        },
        set (newValue) {
          return this.$store.commit('loader/setmsgvisible', newValue)
        }
      },
      showLock:{
        get () {
          return this.$store.state['planungslock'].showLockWarning
        },
        set (newValue) {
          return this.$store.commit('planungslock/setShowLockWarning', newValue)
        }
      },
    },
    methods: {
      ...kundeActions,
      ...produkteActions,
      isLoggedIn() {
        return isLoggedIn()
      }
    },

    async created() {
      const bilder = await this.fetchProduktBilder();
      this.$store.commit('bilder/setProduktBilder', bilder)
    }
  }
</script>
<style lang="scss">
  @import './scss/custom';
  h1{
    margin-top: 30px;
  }
  #settingserror{
    height: 100%;
    background-image: url("./assets/img/login-bg-2021.jpg");
    background-size: cover;
    background-position: left center;
  }

  #maincontent{
    height: 100%;
  }
  .kunden-name {
    font-size: 25px;
    color: #000 !important;
    text-decoration: none !important;

    &.small-text {
      font-size: 20px;
    }


  }
  .v-snack__wrapper{
    box-shadow: none !important;
  }

  .icon-container {
    position: relative;
    top: -5px;
    flex: 0 0 60px;
    max-width: 60px;
  }

  .name-container {
    flex: 0 0 calc(100% - 60px);
    max-width: calc(100% - 60px);
  }

</style>
