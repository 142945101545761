<template>
  <v-dialog v-model="show" persistent max-width="1100" content-class="custom-dialog xx">
    <template v-slot:activator="{}">
      <span @click="open" style="cursor: pointer; display: inline-block;">
        <slot name="button"/>
      </span>
    </template>
    <v-card class="main" v-if="bewohner" style="min-height: 600px;">
      <header>
        <div class="title">
          <h1 class="black--text">{{ tabs[tab].title }} {{ edit ? 'bearbeiten' : 'erfassen' }}
            <template v-if="bewohner.vorname || bewohner.name">- <span class="primary--text">{{
                bewohner.vorname + ' ' + bewohner.name
              }}</span>
            </template>
          </h1>
        </div>
        <div class="close" @click="close()"><img alt="" :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </header>

      <v-tabs
          v-model="tab" :class="{'hidden': !edit}" :hide-slider="true"
          class="custom-tabs">
        <v-tab :key="index" :value="index" v-for="(t, index) in tabs">{{ t.name }}</v-tab>
      </v-tabs>
      <div class="stepper" :class="{'hidden': edit}">
        <div class="previous-step-action" v-if="tab >= 1" @click="previousTab()">
          <img :src="require('@/assets/img/ico2023/previous.svg')" width="29"/>
          {{ tabs[tab - 1].name }}
        </div>
        <div class="next-step-action" v-if="tab <= tabs.length-2" @click="(validStep && !saving) && nextTab()"
             :class="{'disabled': !validStep || saving}">
          <span v-if="tabs[tab].save">Speichern und weiter zu </span>{{ tabs[tab + 1].name }}
          <img :src="require('@/assets/img/ico2023/next.svg')" width="29"/>
          <v-progress-circular indeterminate :size="29" v-if="saving"></v-progress-circular>
        </div>
      </div>
      <v-divider class="clear-both"></v-divider>
      <div class="content">
        <component v-bind:is="tabs[tab].component" v-bind:formValues="bewohner" :edit="edit"
                   :inkograd="inkograd"
                   :fixierhosenBudgetEntries="fixierhosenBudgetEntries"
                   :allowKostengutsprache="allowKostengutsprache"
                   :showRestoreButton="showRestoreButton"
                   :showDeleteButton="showDeleteButton"
                   :showEntryButton="showEntryButton"
                   @changeValidity="changeValidity($event, tabs[tab].component)"
                   @delete="del(bewohner.bewohnerID)"
                   @restore="restore(bewohner.bewohnerID)"
                   @reentry="reentry(bewohner.bewohnerID, $event)"
                   @updateInkograd="updateInkograd($event)"
                   @updateFixierhose="updateFixierhose($event)"

        ></component>
      </div>


      <v-card-actions class="edit-actions">
        <v-btn variant="flat" color="danger" elevation="0" rounded @click="close()">Abbrechen</v-btn>
        <v-btn color="primary" variant="flat" elevation="0" rounded @click="saveAndQuit()" :disabled="!validStep || saving">
          Speichern und schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Bewohnerdaten from '@/components/app/bewohner/Bewohnerverwaltung/steps/Bewohnerdaten.vue'
import Inkograd from '@/components/app/bewohner/Bewohnerverwaltung/steps/Inkograd.vue'
import FixierhosenBudget from '@/components/app/bewohner/Bewohnerverwaltung/steps/FixierhosenBudget.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { inkoGradActions, inkoGradMutations } from '@/obsvervables/inkograd'
import { bewohnerActions } from '@/obsvervables/bewohner'
import { kostengutspracheActions } from '@/obsvervables/kostengutsprache'
import { useBewohnerHelper } from '@/composables/bewohnerHelper'
import { fixierhosenBudgetActions, fixierhosenBudgetMutations } from '@/obsvervables/fixierhosenBudget'

export default {
  name: 'BewohnerverwaltungDialog',
  props: {
    bewohnerData: {
      required: false,
      default () {
        return {}
      }
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    let initTabs = [{
      name: 'Bewohnerdaten',
      title: 'Bewohner',
      component: Bewohnerdaten,
      save: true
    }, {
      name: 'Inkograd',
      title: 'Inkograd',
      component: Inkograd,
      save: false,
    }, {
      name: 'Fixierhosen und Krankenunterlagen',
      title: 'Fixierhosen und Krankenunterlagen',
      component: FixierhosenBudget,
      save: false,
    }]

    return {
      showRestoreButton: false,
      showDeleteButton: false,
      showEntryButton: false,
      inkograd: null,
      fixierhosenBudgetEntries: null,
      allowKostengutsprache: false,
      bewohner: {},
      bewohnerHelper: useBewohnerHelper,
      saving: false,
      show: false,
      tab: 0,
      initTabs: [...initTabs],
      tabs: [...initTabs],
      validStep: false,
      bewohnerBlueprint: {
        'vorname': '',
        'name': '',
        'geschlecht': '',
        'deletedAt': null,
        'createdAt': null,
        'wohneinheitenID': '',
        'zimmer': '',
        'inkograd': null,
        'inkograd_start': moment().startOf('month').format('YYYY-MM-DD'),
        'kostengutsprache': null,
        'kostengutsprache_start': moment().startOf('month').format('YYYY-MM-DD'),
        'anschrift': 'heim',
        'bewohnerID': '',
        'externalID': null,
        'ahvnr': null,
        'krankenkassen_kartennummer': null,
        'krankenkassen_ablaufdatum': null,
        'geburtsdatum': null,
        'krankenkassen_versichertennr': null,
        'krankenkassen_id': null,
        'arzt_id': null,
        'entry_at': null,
        'deleted_at': null,
        'austritt': null,
      },
    }
  },

  methods: {
    ...inkoGradActions,
    ...bewohnerActions,
    ...kostengutspracheActions,
    ...fixierhosenBudgetActions,
    open () {
      if (this.edit && this.bewohnerData && !this.bewohnerData.deleted) {
        //load anschriften when editing
        this.loadHistoryByBewohnerId(this.bewohnerData.bewohnerID)
        this.loadFixierhosenBudgetHistoryByBewohnerId(this.bewohnerData.bewohnerID).then((res) => {
          this.fixierhosenBudgetEntries = res.fixierhosenBudgetEntries
        })


        this.loadInkoGradHistoryByBewohnerId(this.bewohnerData.bewohnerID).then((res) => {
          this.inkograd = this.bewohnerHelper.getCurrentInkogradFromInkogradStore(this.bewohnerData.bewohnerID)
          this.allowKostengutsprache = this.bewohnerHelper.hasTotalInkogradStore(this.bewohnerData.bewohnerID)

        })
      } else {
        inkoGradMutations.setBewohnerInkoGradHistory([])
        fixierhosenBudgetMutations.setBewohnerFixierhosenBudgetHistory([])
      }

      this.show = true
      this.showRestoreButton = (this.edit || this.bewohnerData.bewohnerID) && (this.bewohnerData.deleted_at && !this.bewohnerData.austritt && !this.bewohnerSyncActive)
      this.showDeleteButton = (this.edit || this.bewohnerData.bewohnerID) && (!this.bewohnerSyncActive && (!this.bewohnerData.deleted_at || this.bewohnerData.austritt))
      this.showEntryButton = (this.edit || this.bewohnerData.bewohnerID) && (this.bewohnerData.deleted_at && this.bewohnerData.austritt && !this.bewohnerSyncActive)
    },

    close () {
      this.show = false
    },
    saveAndQuit () {
      if (this.tab == 0 && !this.edit) {
        this.saving = true
        this.saveNewBewohner().then((response) => {
          if (response) {
            this.show = false
          }
        }).finally(() => {
          this.saving = false
        })
      } else {
        this.saving = true
        this.update().then((response) => {
          if (response) {
            this.show = false
          }
        }).finally(() => {
          this.saving = false
        })
      }
      this.show = false
    },

    changeValidity (state) {
      this.validStep = state !== null && state !== false
    },

    previousTab () {
      this.tab--
    },

    nextTab () {
      if (this.tab == 0 && !this.edit && !this.bewohner.bewohnerID) {
        //save after first step
        this.saveNewBewohner().then((response) => {
          this.saving = false
          if (response) {
            this.bewohner.bewohnerID = response.object.bewohnerID
            inkoGradMutations.setBewohnerId(response.object.bewohnerID)
            fixierhosenBudgetMutations.setBewohnerId(response.object.bewohnerID)
            this.tab++
          }
        })
      } else {
        this.tab++
      }
    },

    getDefaultObject () {
      return {}
    },
    saveNewBewohner () {
      return this.saveBewohner({
        vorname: this.bewohner.vorname,
        name: this.bewohner.name,
        geschlecht: this.bewohner.geschlecht,
        wohneinheitenID: this.bewohner.wohneinheitenID,
        zimmer: this.bewohner.zimmer,
        externalID: this.bewohner.externalID,
        entry_at: this.bewohner.entry_at,
        deleted_at: this.bewohner.deleted_at,
        bewohnerID: '-1'
      })
    },
    update () {
      let value = { ...this.bewohner }
      if (value.inkograd === null) {
        delete value.inkograd
        delete value.inkograd_start
      }
      if (value.kostengutsprache === null) {
        delete value.kostengutsprache
        delete value.kostengutsprache_start
      }
      return this.saveBewohner(value)
    },

    del (bewohnerId) {

      this.show = false
      this.deleteBewohner(bewohnerId,this.bewohner.wohneinheitenID)
    },
    restore (bewohnerId) {
      this.restoreBewohner(bewohnerId, 'austritt').then((response) => {
        if (response.type == 'success') {
          this.show = false
        }
      })
    },
    reentry (bewohnerId, date) {
      let value = { ...this.bewohner }
      if (value.inkograd === null) {
        delete value.inkograd
        delete value.inkograd_start
      }
      if (value.kostengutsprache === null) {
        delete value.kostengutsprache
        delete value.kostengutsprache_start
      }
      value.entry_at = moment(date).format('YYYY-MM-DD')
      value.deleted_at = null
      value.austritt = false
      this.updateBewohner(value);
      this.show = false
    },
    updateInkograd (value = null) {
      this.loadHistoryByBewohnerId(this.bewohner.bewohnerID)
      if (value) {
        this.inkograd = value
        this.allowKostengutsprache = this.inkograd == 5
      } else {
        this.inkograd = this.bewohnerHelper.getCurrentInkogradFromInkogradStore(this.bewohner.bewohnerID)
        this.allowKostengutsprache = this.bewohnerHelper.hasTotalInkogradStore(this.bewohnerData.bewohnerID)
      }
    },

    updateFixierhose (value = null) {
      this.loadFixierhosenBudgetHistoryByBewohnerId(this.bewohner.bewohnerID).then((res) => {
        this.fixierhosenBudgetEntries = res.fixierhosenBudgetEntries
      })
    },
  },

  computed: {
    ...mapGetters({
      'isErpSyncActive': 'benutzer/isErpSyncActive',
    }),
  },
  watch: {
    show () {
      if (this.show) {
        this.tab = 0
        if (Object.keys(this.bewohnerData).length > 0) {
          //console.log(!!this.bewohnerData.deleted_at, !this.bewohnerData.austritt, (!!this.bewohnerData.deleted_at && !this.bewohnerData.austritt));
          if (this.bewohnerData.deleted_at && !this.bewohnerData.austritt) {
            this.tabs = [this.initTabs[0]]
          } else {
            this.tabs = this.initTabs
          }
          let tmpBewohner = Object.assign({}, this.bewohnerBlueprint)
          tmpBewohner.vorname = this.bewohnerData.vorname
          tmpBewohner.name = this.bewohnerData.name
          tmpBewohner.geschlecht = this.bewohnerData.geschlecht
          tmpBewohner.deleted_at = this.bewohnerData.deleted_at
          tmpBewohner.entry_at = this.bewohnerData.entry_at
          tmpBewohner.austritt = this.bewohnerData.austritt
          tmpBewohner.wohneinheitenID = this.bewohnerData.wohneinheitenID
          tmpBewohner.zimmer = this.bewohnerData.zimmer
          tmpBewohner.externalID = this.bewohnerData.externalID
          tmpBewohner.bewohnerID = this.bewohnerData.bewohnerID
          tmpBewohner.ahvnr = this.bewohnerData.ahvnr
          tmpBewohner.krankenkassen_kartennummer = this.bewohnerData.krankenkassen_kartennummer
          tmpBewohner.krankenkassen_ablaufdatum = this.bewohnerData.krankenkassen_ablaufdatum
          tmpBewohner.geburtsdatum = this.bewohnerData.geburtsdatum
          tmpBewohner.krankenkassen_versichertennr = this.bewohnerData.krankenkassen_versichertennr
          tmpBewohner.krankenkassen_id = this.bewohnerData.krankenkassen_id
          tmpBewohner.arzt_id = this.bewohnerData.arzt_id

          this.bewohner = tmpBewohner
        } else {
          this.bewohner = { ...this.bewohnerBlueprint }
        }
      }
    },
    inkograd () {
      if (this.inkograd == 5) {
        this.allowKostengutsprache = true
      }
    },
    allowKostengutsprache () {
      if (this.allowKostengutsprache) {
        this.getMaxKostengutspracheByInkograd(5)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 20px;
  }
}

.custom-tabs {
  margin-top: 10px;
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.v-card__actions {
  padding-top: 10px !important;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.stepper {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}

.previous-step-action, .next-step-action {
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
  cursor: pointer;

  img {
    top: 9px;
    position: relative;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    img {
      filter: grayscale(100%);
    }
  }
}

.previous-step-action {
  float: left;

  img {
    margin-right: 7px;
  }
}

.next-step-action {
  float: right;

  img {
    margin-left: 7px;
  }
}

.content {
  margin-top: 30px;
  margin-bottom: 30px;
}

.v-slide-group {
  overflow: visible;
}

.v-btn--rounded {
  border-radius: 10px;
}

.deactiveIcon {
  display: block;
  float: left;
  flex: none;
  cursor: pointer;
}

.clear-both {
  clear: both;
}

.edit-actions {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
</style>
