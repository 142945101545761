export const planungstate = {
  namespaced: true,
  state: {
    activeAccordion: false
  },
  actions: {

    setActiveAccordion({ commit },value) {
      commit("setActiveAccordion",value);
    }
  },
  mutations: {

    setActiveAccordion(state,val){
      state.activeAccordion = val;
    }
  }
};