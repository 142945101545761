import Vue from 'vue'
import httpClient from '@/utils/httpClient'

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const bewohnerState = Vue.observable({
  'bewohner': []
})

export const bewohnerGetters = {
  bewohner: () => bewohnerState.bewohner,

  getActiveBewohner: () => {
    return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => !b.deleted)
  },
  getDeletedBewohner: () => {
    return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => b.deleted && !b.austritt)
  },
  getAustritteBewohner: () => {
    return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => b.deleted && b.austritt)
  },
  getById: (state) => (id) => !state.bewohner ? null : state.bewohner.find(b => b.bewohnerID === +id),
}

export const bewohnerMutations = {

  setbewohner (bewohner) {
    bewohnerState.bewohner = bewohner
  },
}

export const bewohnerActions = {

  fetchBewohner (allAvailabilty = false, inclDeleted = false) {
    //if(bewohnerState.bewohner.length < 1){
    httpClient({
      url: process.env.VUE_APP_API_URL + 'get/bewohner',
      method: 'POST',
      data: { 'allAvailabilty': allAvailabilty, 'inclDeleted': inclDeleted }
    }).then(function (res) {
      bewohnerMutations.setbewohner(res.bewohner)
    })
    //}
  },

  saveBewohner (editBewohner, ignoreLock = false) {

    //darf ein Lock ignoriert werden? zb. von Notizchange in Planung

    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'save/bewohner',
        method: 'post',
        data: { 'bewohner': editBewohner, 'ignoreLock': ignoreLock }
      })
        .then(function (res) {
          bewohnerMutations.setbewohner(res.bewohner)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })

    })
  },

  deleteBewohner (bewohnerID, whID) {
    httpClient({
      url: process.env.VUE_APP_API_URL + 'delete/bewohner',
      method: 'post',
      data: { 'bewohnerid': bewohnerID, 'whid': whID }
    }).then(function (res) {
      if (res.type === 'success') {
        bewohnerMutations.setbewohner(res.bewohner)
      }
    })
  },

  updateBewohner (bewohner) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'save/bewohner', method: 'post', data: {
          id: bewohner.id,
          bewohner: bewohner
        }
      }).then(response => {
        if (response && response.type == 'success') {
          bewohnerMutations.setbewohner(response.bewohner)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  restoreBewohner (bewohnerID, whID) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'restore/bewohner',
        method: 'post',
        data: { 'bewohnerid': bewohnerID, 'whid': whID }
      }).then(function (res) {
        if (res.type === 'success') {
          resolve(res)
          bewohnerMutations.setbewohner(res.bewohner)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async savePlandaten (bewohnerID, dayornight, count, whid) {
    const res = await httpClient({
      url: process.env.VUE_APP_API_URL + 'save/bewohner-plandaten',
      method: 'post',
      data: { 'bewohnerid': bewohnerID, 'dayornight': dayornight, 'count': count, 'whid': whid }
    })

    if (res.type === 'success') {
      bewohnerMutations.setbewohner(res.bewohner)
    }
  }
}

export default {
  bewohnerState,
  bewohnerGetters,
  bewohnerMutations,
  bewohnerActions
}