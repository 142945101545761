<template>
  <v-row class="barometer-product-list" v-if="productInfo" style="page-break-inside: avoid;">
    <v-col cols="2" xl="3">
      <div class="product-preview mb-5">
        <span v-if="image" class="d-none d-xl-block">
          <img :alt="productInfo.Bezeichnung1" :src="image.path+'?width=80'" height="45" width="55"/>
        </span>
        <div>
          <div class="caption heavy">{{ productInfo.ArtikelNrLAG }}</div>
          <div class="heavy pt-1">{{ productInfo.Bezeichnung1 }}</div>
          <div class="pt-1">{{  productInfo.Verkaufseinheit }} </div>
        </div>
      </div>
    </v-col>
    <v-col cols="10" xl="9" class="detail-col">
      <v-row>
        <v-col v-for="month, i in product.calendar.months" :key="'month-'+i" :class="{ futureMonth: isFutureYear || (isCurrentYear && currentMonth < i) }">
          {{ month.stk > 0? month.stk: '' }}
        </v-col>
        <v-col class="highlighted" :class="{ futureMonth: isFutureYear || isCurrentYear }">
          {{ nProductPackages }}
        </v-col>
        <v-col class="highlighted" :class="{ futureMonth: isFutureYear|| isCurrentYear }">
          {{ yearTotal? yearTotal.toFixed(2): '' }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {produkteGetters} from '@/obsvervables/produkte';
import {mapGetters} from 'vuex'
import {barometerGetters} from "../../../obsvervables/barometer";

export default {
  name: 'BarometerProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      MiGeL: {
        '15.01.01.00.1': 1,
        '15.01.02.00.1': 2,
        '15.01.03.00.1': 3
      }
    }
  },

  computed: {
    ...barometerGetters,
    ...mapGetters({
      images: 'bilder/bilder'
    }),

    currentYear () {
      return +this.moment().year()
    },

    isCurrentYear() {
      return this.selectedYear === this.currentYear
    },

    isFutureYear() {
      return this.selectedYear > this.currentYear
    },

    currentMonth() {
      return +this.moment().month()
    },

    productMiGelGrad() {
      return this.MiGeL[this.productInfo.MiGeL] ?? 0
    },

    productInfo() {
      let productInfo = produkteGetters.produkte().find(p => p.ArtikelNrLAG == this.product.ArtikelNrLAG)

      if(productInfo === undefined) {
        return  produkteGetters.deletedProdukte().find(p => p.ArtikelNrLAG == this.product.ArtikelNrLAG)
      }

      return productInfo
    },

    nProductPackages() {
      return Math.ceil(this.product.calendar.totalStk / this.productInfo.Inhalt)
    },

    yearTotal() {
      return  Math.round((this.productInfo.preis * this.nProductPackages)/ 0.05) * 0.05
    },

    image() {
      var image = this.images[this.product.ArtikelNrLAG]

      if (image[0]['images'][0])
        return image[0]['images'][0]

      return null
    }
  }
}
</script>

<style lang="scss" scoped>

.barometer-product-list {
  font-size: 16px;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.detail-col {
  padding-top: 0;
  padding-bottom: 0;

  .col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:nth-child(even) {
      background-color: #fdf9f7;
    }

    &:last-child {
      padding-right: 12px;
    }

    &.futureMonth {
      color: #bab6b6;
      font-style: italic;
    }

    &.highlighted {
      background-color: #f4ede7;
    }
  }
}

.row {
  height: 100%;
}

.product-preview {
  display: flex;
  flex-flow: row;
  gap: 10px;
}

.migel-indicator {
  display: flex;
  align-items: center;
}

</style>
