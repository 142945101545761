<template>
  <v-row class="comp-bewohnerdrop">
    <v-col cols="3" :xl="'2'">
      <v-card color="secondary" light outlined style="height: 100%">
        <v-list-item>
          <v-list-item-content class="body-2">
            <v-row>
              <v-col :class="{ 'py-0': minimised}">
                <div class="pt-1 pb-2 subtitle-1 normal heavy"
                     :class="{ 'py-0 d-flex justify-space-between': noProductsAssigned}"
                     style="font-size: 18px !important;font-weight: normal !important;"
                >
                  {{ bewohner.vorname }} {{ bewohner.name }}

                  <div
                      v-if="noProductsAssigned"
                      @click="toggleMimised"
                      class="cursor-pointer"
                  >
                    <v-tooltip v-if="minimised" top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon>mdi-chevron-down</v-icon>
                        </div>
                      </template>
                      <span>mehr anzeigen</span>
                    </v-tooltip>

                    <v-tooltip v-else top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                        <v-icon>mdi-chevron-up</v-icon>
                        </div>
                      </template>
                      <span>weniger anzeigen</span>
                    </v-tooltip>
                  </div>
                </div>
                <template v-if="!minimised">
                  <div class="pt-0 d-flex flex-row justify-space-between">
                    <div>
                      {{ bewohner.zimmer }}
                    </div>
                    <InkoGradIndicator :inkograd="bewohner.inkogradHistory[0].inkograd"
                                       v-if="bewohner.inkogradHistory.length > 0"/>
                  </div>
                </template>
              </v-col>
            </v-row>

            <template v-if="!minimised">
              <v-divider class="mt-1 darkgrey"></v-divider>
              <v-row class="mt-4 ">
                <!--<v-col cols="4" class="pt-3">
                  <v-img :src="bewohner.geschlecht == 'w' ? require('@/assets/img/ico/weiblich-klein.svg') : require('@/assets/img/ico/maennlich-klein.svg')" alt="" max-width="21"></v-img>
                </v-col>-->
                <v-col cols="3">
                  <v-tooltip top v-if="bewohner.note && bewohner.note != ''" max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }" open-delay="0">
                        <v-img v-if="hover" @click="makeBewohnerNote($event,bewohner)" v-bind="attrs" v-on="on"
                               :src="require('@/assets/img/ico2021_samesize/notiz-hover.svg')" alt="" max-width="30"
                               class="mt-0 cursor-pointer"></v-img>
                        <v-img v-else @click="makeBewohnerNote($event,bewohner)" v-bind="attrs" v-on="on"
                               :src="require('@/assets/img/ico2021_samesize/notiz.svg')" alt="" max-width="30"
                               class="mt-0 cursor-pointer"></v-img>
                      </v-hover>
                    </template>
                    <span>{{ bewohner.note }}</span>
                  </v-tooltip>
                  <v-hover v-else v-slot="{ hover }" open-delay="0">
                    <v-img v-if="hover" @click="makeBewohnerNote($event,bewohner)"
                           :src="require('@/assets/img/ico2021_samesize/notiz-leer-hover.svg')" alt="" max-width="30"
                           class="mt-0 cursor-pointer"></v-img>
                    <v-img v-else @click="makeBewohnerNote($event,bewohner)"
                           :src="require('@/assets/img/ico2021_samesize/notiz-leer.svg')" alt="" max-width="30"
                           class="mt-0 cursor-pointer"></v-img>
                  </v-hover>
                </v-col>
                <v-col cols="3" class="px-0" style="margin-left:-7px;">
                  <v-tooltip top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-if="bewohner.netzhose && bewohner.netzhose != ''" v-slot="{ hover }" open-delay="0">
                        <v-img v-bind="attrs" v-on="on" v-if="hover" @click="makeNetzhose($event,bewohner)"
                               :src="require('@/assets/img/ico2021_samesize/netzhose-hover.svg')" alt="" max-width="26"
                               class="mt-0 cursor-pointer" style="height: 26px;"></v-img>
                        <v-img v-bind="attrs" v-on="on" v-else @click="makeNetzhose($event,bewohner)"
                               :src="require('@/assets/img/ico2021_samesize/netzhose.svg')" alt="" max-width="26"
                               class="mt-0 cursor-pointer" style="height: 26px;"></v-img>
                      </v-hover>
                      <v-hover v-else v-slot="{ hover }" open-delay="0">
                        <v-img v-if="hover" @click="makeNetzhose($event,bewohner)"
                               :src="require('@/assets/img/ico2021_samesize/netzhose-leer-hover.svg')" alt=""
                               max-width="30" class="mt-0 cursor-pointer fixierhose"
                               style="height: 20px; top: 3px;"></v-img>
                        <v-img v-else @click="makeNetzhose($event,bewohner)"
                               :src="require('@/assets/img/ico2021_samesize/netzhose-leer.svg')" alt="" max-width="30"
                               class="mt-0 cursor-pointer fixierhose" style="height: 20px;top: 3px;"></v-img>
                      </v-hover>
                    </template>
                    <span>{{ this.productById(bewohner.netzhose).Bezeichnung1 }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="3" class="px-0" style="margin-left:-15px;">
                  <v-hover v-slot="{ hover }" open-delay="0">
                    <v-img v-if="hover"
                           @click="sendPDF($event,[bewohner],[bewohnerprodukte[bewohner.bewohnerID+'-d']],[bewohnerprodukte[bewohner.bewohnerID+'-n']])"
                           :src="require('@/assets/img/ico2021_samesize/drucken-hover.svg')" alt="" max-width="30"
                           style="margin-top:1px;" class=" cursor-pointer"></v-img>
                    <v-img v-else
                           @click="sendPDF($event,[bewohner],[bewohnerprodukte[bewohner.bewohnerID+'-d']],[bewohnerprodukte[bewohner.bewohnerID+'-n']])"
                           :src="require('@/assets/img/ico2021_samesize/drucken.svg')" alt="" max-width="30"
                           style="margin-top:1px;" class=" cursor-pointer"></v-img>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="9" :xl="'10'">
      <div class="pl-1 bewohner d-flex align-center" style="height: 100%" v-if="minimised">
        <b>keine zugewiesene Produkte</b> <span @click="toggleMimised"
                                                class="ml-4 cursor-pointer text-decoration-underline">bearbeiten</span>
      </div>
      <div class="pl-1 bewohner" v-else>
        <v-row v-for="shift in shifts" :key="bewohner.bewohnerID+shift">
          <v-col cols="2" class="sdaynight-icon">
            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-img :src="require('@/assets/img/ico2021_samesize/bedarf-'+shift+'.svg')" alt="" class="my-5"
                       max-width="35"></v-img>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-img
                    v-if="bewohnerprodukte[bewohner.bewohnerID+'-'+shift] && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][0] != -1"
                    @click="addMoreProducts(shift,bewohner)"
                    :src="require('@/assets/img/ico2021_samesize/hinzufuegen-box.svg')" alt=""
                    style="margin-top:20px;" max-width="35" class="cursor-pointer"></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="dropholder">
            <v-row v-for="(bp, bpindex) in bewohnerprodukte[bewohner.bewohnerID+'-'+shift]"
                   :key="bpindex+'-'+bewohner.bewohnerID+'-'+shift+'slot'+bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1">
              <v-col cols="6" class="ma-0 pa-0 pt-0 plandrop">
                <drop
                    @click="$emit('showProdMenu')"
                    :key="'drop-'+bpindex+'-'+bewohner.bewohnerID+'-'+shift+'slot'+bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1"
                    class="copy proddrop cursor-pointer"
                    @drop="changeBewohnerProdukte($event,bewohner.bewohnerID,shift,'add',whid,bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1 ,1)"
                    :class="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] != -1 && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].new != 1  ? 'hasproduct' : ''"
                    style="width: 100%">
                  <v-row
                      v-if="bewohnerprodukte[bewohner.bewohnerID+'-'+shift] && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]  && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] != -1 && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['ArtikelNrLAG']"
                      dense class="product">
                    <v-col cols="2">
                         <span
                             v-if="bilder[bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG] && bilder[ bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images && bilder[ bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images[0] ">
                           <v-img
                               :src="bilder[bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images[0].path+'?width=90'"
                               width="90" height="90" :contain="true"></v-img>
                          </span>
                      <span v-else>
                        <v-img :src="require('@/assets/img/no-image-ip.svg')" width="90" ></v-img>

                        </span>
                    </v-col>
                    <v-col cols="10" class="pl-6 text-vertical-align">
                      <product-caption :p="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]"
                                       :artnr="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG"
                                       :name="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].Bezeichnung1 "
                                       :einheit=" bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].Verkaufseinheit"></product-caption>
                    </v-col>
                  </v-row>
                </drop>
              </v-col>
              <v-col cols="1" class="stk text-vertical-align">
                <v-text-field
                    v-if="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] != -1 && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].new != 1"
                    min="0" oninput="validity.valid||(value='');" light autocomplete="off"
                    @click="chkWartend(wartend)"
                    :readonly="wartend"
                    @input="changeBewohnerProdukte(-1,bewohner.bewohnerID,shift,'change',whid,bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1,bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['stk'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['stk'] : 1)"
                    v-model="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['stk']" style="width:70%;"
                    class="body-2" type="number">
                </v-text-field>
              </v-col>
              <v-col cols="5" class="text-vertical-align justify-right">
                <v-hover
                    v-if="bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex] != -1  && bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex].new != 1"
                    v-slot="{ hover }" open-delay="0">
                  <v-img v-if="hover"
                         @click="changeBewohnerProdukte(-1,bewohner.bewohnerID,shift,'remove',whid,bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1,-1)"
                         :src="require('@/assets/img/ico2021_samesize/loeschen-hover.svg')" alt="" max-width="35"
                         class="cursor-pointer"></v-img>
                  <v-img v-else
                         @click="changeBewohnerProdukte(-1,bewohner.bewohnerID,shift,'remove',whid,bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[bewohner.bewohnerID+'-'+shift][bpindex]['slot'] : 1,-1)"
                         :src="require('@/assets/img/ico2021_samesize/loeschen.svg')" alt="" max-width="35"
                         class="cursor-pointer"></v-img>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="ma-0 px-3 py-0" v-if="shift == 'd'">
            <v-divider light></v-divider>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import {Drop} from "vue-easy-dnd";
import {bewohnerprodukteActions} from '@/obsvervables/bewohnerprodukte';
import {planungsdatenActions} from '@/obsvervables/planungsdaten';
import InkoGradIndicator from "../InkoGrad/InkoGradIndicator";

export default {
  name: 'BewohnerDrop',
  components: {
    InkoGradIndicator,
    Drop
  },
  data() {
    return {
      'timer': false,
      'awaitingInput': false,
      'shifts': ['d', 'n'],
      minimised: false
    }
  },
  props: ["bewohner", "bewohnerprodukte", "whid", "wartend", "produkte"],
  computed: {
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    },

    noProductsAssigned() {
      return !(this.bewohnerprodukte[this.bewohner.bewohnerID + '-d'][0] != -1) && !(this.bewohnerprodukte[this.bewohner.bewohnerID + '-n'][0] != -1)
    }
  },
  methods: {
    ...bewohnerprodukteActions,
    ...planungsdatenActions,

    productById(id) {
      var prod = this.produkte.filter(function (prod) {
        return prod.ArtikelNrLAG == id;
      });

      if (prod.length > 0) {
        return prod[0];
      } else {
        return false;
      }
    },

    chkWartend(wartend) {
      if (wartend) {
        this.$store.commit('planungslock/setShowLockWarning', true)
      }
    },

    makeNetzhose($event, bewohnerID) {
      this.$emit('bewohnerNetzhose', bewohnerID)
    },

    makeBewohnerNote($event, bewohnerID) {
      this.$emit('bewohnerNote', bewohnerID)
    },

    sendPDF($event, bewohner, day, night) {
      this.$emit('createPdf', bewohner, day, night)
    },

    sendAddDayNightPdf() {
      this.$emit('multipleDayNightPopup')
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async changeBewohnerProdukte(e, bewohnerID, dayornight, changeType, whid, slot, count) {

      //  :accepts-data="(n) => n.InkoSortimentTyp != 'Zusatz'"
      if (e.data && e.data.InkoSortimentTyp && e.data.InkoSortimentTyp == 'Zusatz') {
        this.$store.commit('loader/setmsg', 'Achtung Sie können keine Zusatzprodukte dem Bewohner hinzufügen. Wechseln Sie bitte zum Tab "Bestell-Vorschlag".')
        this.$store.commit('loader/setmsgtype', 'error')
        this.$store.commit('loader/setmsgvisible', true)
        return false;
      }

      if (this.wartend) {
        this.$store.commit('planungslock/setShowLockWarning', true)
        return false;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(async () => {

        //window.console.log('SAVE IT')
        //console.log(e)
        let ArtikelNrLAG = 0;

        //löschen
        if (e == -1) {
          let b = this.bewohnerprodukte[bewohnerID + '-' + dayornight][(slot - 1)];

          ArtikelNrLAG = b.ArtikelNrLAG

          //hinzufügen oder ändern
        } else {

          ArtikelNrLAG = e.data.ArtikelNrLAG;
        }

        await this.saveBewohnerprodukte(ArtikelNrLAG, bewohnerID, dayornight, changeType, slot, whid, count);

        //planungs WK updaten (muss hier gemacht werden da wir uns in gleicher view befinden und es sonst kein Refresh gibt
        this.fetchPlanungsdaten();
      }, 600);

    },


    addMoreProducts(shift, bewohner) {

      if (this.wartend) {
        this.$store.commit('planungslock/setShowLockWarning', true)
        return false;
      }

      let slot = this.bewohnerprodukte[bewohner.bewohnerID + '-' + shift].length + 1;
      var alreadyHasEmptySlot = false;

      //erst wenn leerer Slot gefüllt darf ein neuer Slot geaddet werden
      this.bewohnerprodukte[bewohner.bewohnerID + '-' + shift].forEach(function (bewProd) {
        if (typeof bewProd.new != 'undefined' && bewProd.new == 1) {
          alreadyHasEmptySlot = true;
        }
      })

      if (!alreadyHasEmptySlot) {
        this.bewohnerprodukte[bewohner.bewohnerID + '-' + shift].push(
            {
              'new': 1,
              'slot': slot,
              'dayornight': shift
            }
        );
      }
    },

    toggleMimised() {
      this.minimised = !this.minimised
    }
  },
  created() {
  },

  mounted() {
    if (this.noProductsAssigned) {
      this.minimised = true
    }
  }
}
</script>
<style lang="scss">

.plandrop {
  .proddrop {
    padding: 20px;

    &:hover {
      opacity: 0.8;
    }
  }

}

.bewohner {
  border-top: 1px solid #999;
}

.v-divider.darkgrey {
  border-color: #999;
}

.comp-bewohnerdrop {

  &:last-child {
    .bewohner {
      border-bottom: 1px solid #999;
    }
  }


  .daynight-icons {
    border-right: 1px #fff solid !important;
  }

  .stk {
    border-left: 1px #fff solid !important;
  }

  .v-text-field__details {
    display: none;
  }

  .mdi-minus-circle-outline::before, .mdi-plus-circle-outline:before {
    font-size: 17px;
    opacity: 0.7;
  }

  .proddrop {

    * {
      color: #000;
    }
  }

  .fixierhose {
    .v-image__image--cover {
      background-size: auto 100%;
    }
  }

  .stk {
    padding-top: 5px !important;

    input {
      text-align: center;
      color: #5a5a5a;
    }

    .v-input {
      padding: 0 !important;
      margin: 0 !important;
    }

    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color: rgba(0, 0, 0, 0.15) !important;
    }

    .v-ripple__container {
      display: none !important;
    }

    .v-text-field__slot {
      caret-color: #ccc !important;
    }
  }
}
</style>
