<template>
  <div>
    <section class="pdf-item" style="max-width: 100%;padding-bottom: 10px;">
      <v-row>
        <v-col cols="6">
          <h2>
            Einräumliste | pro Woche
            <!--für {{ wh.wochenintervall }} Wochen (gemäss Intervall)-->
          </h2>

          <h3 class="whname">
            {{ wh.name }}
          </h3>
        </v-col>
        <v-col cols="6">
          <span style="float: right">
             <img :src="require('@/assets/img/printlogo.png')" height="40" />
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <span class="caption" style="float:right;padding-right:20px;">
              Stück pro Woche
          </span>
        </v-col>
      </v-row>
    </section>
    <section v-if="bewohnerprodukte[b.bewohnerID+'-n'][0] != -1 || bewohnerprodukte[b.bewohnerID+'-d'][0] != -1" class="pdf-item avoid" v-for="(b,bblockindex) in bewohner" :key="'beprint-'+bblockindex"  style="page-break-inside: avoid;">
      <v-row>
        <v-col cols="4"  >
          <v-card color="secondary"  class="my-0 py-0" light outlined style="height: 100%;" >
            <v-list-item class="mb-0 pb-0">
              <v-list-item-content class="body-2 mb-0 pb-0">
                <v-row>
                  <v-col  class="pt-1 mt-0 mb-0 pb-0">
                    <div class="pt-0 mt-0 mb-0 pb-0">
                      {{ b.zimmer }}
                    </div>
                    <div class="pt-2">
                      {{ b.vorname }} {{ b.name }}
                      <InkoGradIndicatorPrint :inkograd="b.inkogradHistory[0].inkograd" :show-drips="true" v-if="b.inkogradHistory.length > 0" class="mt-1"/>

                      <v-row v-if="productById(b.netzhose)"  style="page-break-inside: avoid;">
                        <v-col cols="12" class="mb-0 pb-0 mt-0 pt-2">
                          <v-divider light class="pt-2"></v-divider>
                          <v-row>
                            <v-col class="my-0 py-0">
                              <div class="heavy py-0 my-0 mb-1 caption">Fixierhose</div>
                              <v-row>
                                <v-col cols="3" class="pt-0 mt-0">
                                  <div class="mt-0" v-if="bilder[productById(b.netzhose)['ArtikelNrLAG']] && bilder[productById(b.netzhose)['ArtikelNrLAG']][0]['images'][0] ">
                                    <img :src="bilder[productById(b.netzhose)['ArtikelNrLAG']][0]['images'][0].path+'?width=80'" height="35" />
                                  </div>
                                </v-col>
                                <v-col cols="9" class="mb-0 pb-0">
                                  <div class="pb-2 pt-0 mt-0" style="font-size: 12px">
                                    {{ productById(b.netzhose)["Bezeichnung1"] }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="8" class="py-0 my-0">
          <v-row v-for="shift in shifts" :key="'print-'+bewohner.bewohnerID+shift">
            <div style="width: 100%">
              <v-row class="pa-0 ma-0">
                <v-col cols="1" class="daynight-icons py-0 my-0" >
                  <img :src="require('@/assets/img/ico/bedarf-'+shift+'.svg')" alt=""  class="mt-5 opacity-50" style="opacity: 0.5 !important;">
                </v-col>
                <v-col class="py-0 my-0" cols="11"  style="padding-left: 20px;margin-top:-25px;">
                  <v-row v-for="(bp, bpindex) in bewohnerprodukte[b.bewohnerID+'-'+shift]" :key="bpindex+'-'+b.bewohnerID+'-'+shift+'slot'+bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]['slot'] ? bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]['slot'] : 1">
                    <v-col v-if="bp.new != 1" cols="12" style="width:100%;min-height: 70px;">
                      <v-row style="width:100%" v-if="bewohnerprodukte[b.bewohnerID+'-'+shift] && bewohnerprodukte[b.bewohnerID+'-'+shift][0] != -1 && bewohnerprodukte[b.bewohnerID+'-'+shift] && bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]  && bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex] != -1 && bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]['ArtikelNrLAG']" dense >
                        <v-col cols="2" class="py-0 my-0" >
                         <span v-if="bilder[bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG] && bilder[ bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images && bilder[bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images[0] ">
                             <img :src="bilder[bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG][0].images[0].path+'?width=80'" height="50">
                          </span>
                          <span v-else>
                        </span>
                        </v-col>
                        <v-col cols="6" class="py-0 my-0">
                          <span class="caption">{{ bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].ArtikelNrLAG }}</span> |
                          <span class="caption"> {{ bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].Verkaufseinheit }}</span><br>
                          <span class="bezeichnung">{{ bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex].Bezeichnung1 }}</span>
                        </v-col>
                        <v-col cols="4" class="bezeichnung" style="padding-top:18px;text-align: center">
                        <!-- {{ wh.wochenintervall * 7 * bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]['stk'] }}-->
                          {{ 7 * bewohnerprodukte[b.bewohnerID+'-'+shift][bpindex]['stk'] }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col class="no pa-0 ma-0">
                      <v-divider light class="pa-0 ma-0"></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>
import InkoGradIndicatorPrint from "../InkoGrad/InkoGradIndicatorPrint";
export default {
  components: {InkoGradIndicatorPrint},
  props: ['bewohner','wh','bewohnerprodukte','produkte'],
  data () {
    return {
      'shifts': ['d','n']
    }
  }
  ,computed:{
    bilder:{
      get(){ return this.$store.state['bilder'].produktbilder }
    }
  },
  methods: {
    productById(id){
      var prod =  this.produkte.filter(function(prod){
        return prod.ArtikelNrLAG == id;
      });

      if(prod.length > 0){
        return prod[0];
      }else{
        return false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .no{
    margin: 0;
    padding: 0;
  }

  .whname{
    margin-top: 15px;
  }

  .caption{
    font-size: 12px;
    font-weight: normal;
  }

  .bezeichnung{
    font-size: 14px;
  }



</style>
