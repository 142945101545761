<template>
  <div v-if="bewohnerInkoGradHistory">
    <v-data-table
        hide-default-footer
        v-if="bewohnerInkoGradHistory"
        :headers="headers"
        :items="history"
        class="elevation-1"
    >
      <template v-slot:item.inkograd="{ item }">
        <InkoGradIndicator :inkograd="item.inkograd" />
      </template>
      <template v-slot:item.actions="{ item }" v-if="editable">
        <v-btn text icon>
          <v-hover v-slot="{ hover }" open-delay="0">
            <v-img v-if="hover" class="mt-0 cursor-pointer" @click="remove(item.id)" :src="require('@/assets/img/ico2021_samesize/loeschen-hover.svg')" alt="" max-width="25"></v-img>
            <v-img v-else class="mt-0 cursor-pointer" @click="remove(item.id)" :src="require('@/assets/img/ico2021_samesize/loeschen.svg')" alt="" max-width="25"></v-img>
          </v-hover>
        </v-btn>
      </template>
      <template v-slot:no-data>
        keine Daten erfasst
      </template>
    </v-data-table>
    <ConfirmtModalDialog ref="confirm"/>
  </div>
</template>

<script>
import {inkoGradActions, inkoGradGetters} from "../../../obsvervables/inkograd"
import ConfirmtModalDialog from "../core/ConfirmModalDialog";
import InkoGradIndicator from "./InkoGradIndicator";

export default {
  name: 'InkoGradHistory',
  components: {InkoGradIndicator, ConfirmtModalDialog},

  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false
    },

    bewohner_id: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      headers: [
        {text: 'Inkograd', value: 'inkograd', sortable: false},
        {text: 'von', value: 'start', sortable: false},
        {text: 'bis', value: 'end', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ]
    }
  },

  computed: {
    ...inkoGradGetters,

    history() {
      let history = []

      for (let i = 0; i < this.bewohnerInkoGradHistory.length; i++) {
        let month = {
          id: this.bewohnerInkoGradHistory[i].id,
          start: this.moment.unix(this.bewohnerInkoGradHistory[i].start).locale('de').format('MMM YYYY'),
          end: null,
          inkograd: this.bewohnerInkoGradHistory[i].inkograd
        }

        if (this.bewohnerInkoGradHistory[i].end) {
          month.end = this.moment.unix(this.bewohnerInkoGradHistory[i].end).locale('de').format('MMM YYYY')
        } else if (this.bewohnerInkoGradHistory[i + 1]) {
          month.end = this.moment.unix(this.bewohnerInkoGradHistory[i + 1].start).subtract(1, 'months').locale('de').format('MMM YYYY')
        }

        history.push(month)
      }

      return history
    }
  },

  created() {
    if (this.bewohner_id) {
      inkoGradActions.loadInkoGradHistoryByBewohnerId(this.bewohner_id)
    }
  },

  methods: {
    remove(id) {
      this.$refs.confirm.show('Löschen', 'Möchten Sie diesen Inkograd wirklich löschen? Sollte der Inkograd wechseln, bitte fügen Sie einen neuen Eintrag mit dem Start-Monat hinzu.').then((confirm) => {
        if (confirm) {
          inkoGradActions.deleteInkoGrad(id)
          this.$emit('edited')
        }
      })
    }
  },

  watch: {
    bewohner_id() {
      inkoGradActions.loadInkoGradHistoryByBewohnerId(this.bewohner_id)
    }
  }
}
</script>

<style scoped>
.v-data-table {
  box-shadow: none !important;
}
</style>