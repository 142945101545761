import Vue from 'vue'

//tab sync -> für zb wenn bestellungen ausgewählt default zusatzprodukte geöffnet
const prodtypState = Vue.observable({
    "prodtab": 0,
    "typeProdukte": "Standard"
});

export const prodtypGetters = {
    prodtab: () => prodtypState.prodtab,
    typeProdukte: () => prodtypState.typeProdukte,

}

export const prodtypMutations = {
    setProdtab(prodtab) {
        prodtypState.prodtab = prodtab;
    },

    setTypeProdukte(typeProdukte) {
        prodtypState.typeProdukte = typeProdukte;
    },
}



export const prodtypActions = {
    saveProdtab(prodtab){
        prodtypMutations.setProdtab(prodtab)
    },

    saveTypeProdukte(typeProdukte){
        prodtypMutations.setTypeProdukte(typeProdukte)
    }
}

export default {
    prodtypState,
    prodtypGetters,
    prodtypMutations,
    prodtypActions
}