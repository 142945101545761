import httpClient from './httpClient'

const actionScope = `loader`
const lockScope = `planungslock`
import router from '@/router'

export function setupInterceptors ({ dispatch }) {
  let requestsPending = 0
  const req = {
    pending: () => {
      requestsPending++
      dispatch(`${actionScope}/show`)
    },
    done: () => {
      requestsPending--
      if (requestsPending <= 0) {
        dispatch(`${actionScope}/hide`)
      }
    }
  }
  httpClient.interceptors.request.use(
    config => {
      req.pending()
      return config
    },
    error => {
      requestsPending--
      req.done()
      return Promise.reject(error)
    }
  )
  httpClient.interceptors.response.use(
    ({ data }) => {
      req.done()
      //normale fehlermeldung oder fehler popup aufgrund LOCK (da zb. offener WK existiert & Planungsdaten angepasst werden möchten)
      if (data.msg) {
        dispatch(`${actionScope}/setmsg`, data.msg)
        dispatch(`${actionScope}/setmsgtype`, data.type)
        dispatch(`${actionScope}/setmsgvisible`, true)
      } else if (data.lock) {
        dispatch(`${lockScope}/setShowLockWarning`, true)
      }

      return Promise.resolve(data)
    },
    error => {
      /*try {
        if (!error.response) {
          sessionStorage.clear()
          localStorage.clear()
          if (router.currentRoute.path != '/login') {
            router.push('/login')
          }
        }
      } catch (e) {
        console.log(e)
      }

      if (error.response.status == 503) {
        sessionStorage.clear()
        localStorage.clear()
        if (router.currentRoute.path != '/login') {
          router.push('/login')
        }
      }

      if (error.response.status == 401 || error.response.status == 403) {
        window.location.href = '/'
      }

      if (error.msg) {
        dispatch(`${actionScope}/setmsg`, error.msg)
        dispatch(`${actionScope}/setmsgtype`, error.type)
        dispatch(`${actionScope}/setmsgvisible`, true)
      }*/
      req.done()

      return Promise.reject(error)
    }
  )
}