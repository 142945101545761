<template>
  <div>
    <v-container id="kgv">
      <v-card flat>
        <v-card-text class="pa-0 ma-0">
          <v-row>
            <v-col cols="6">
              <h1>
                KVG
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <router-link to="/statistiken">&laquo; zurück zur Übersicht</router-link>
            </v-col>
          </v-row>
          <div>
            <v-row>
              <v-col cols="12">
                <v-divider light></v-divider>
              </v-col>
            </v-row>
            <v-row class="mb-0 pb-0">
              <v-col cols="4" class="mb-0 pb-0">
                <v-date-picker
                    v-model="dates"
                    type="month"
                    range
                    locale="ch-de"
                    color="primary"
                    selected-items-text="Zeitraum"
                    :show-current="true"
                    :max="new Date().toISOString().slice(0, 10)"
                ></v-date-picker>
              </v-col>
              <v-col cols="8">
                <div class="mb-2">Bitte wählen Sie die Wohneinheit aus</div>
                <v-radio-group
                    v-if="wohneinheiten"
                    v-model="selectedWohneinheiten"
                >
                  <div class="d-flex flex-row">
                    <div v-for="chunk, i in wohneinheitenSelect" :key="'chunk' + i" class="mr-10">
                      <v-radio
                          v-for="wh in chunk"
                          :key="'wh-' + wh.id"
                          :label="wh.name"
                          :value="wh.id"
                      />
                    </div>
                  </div>
                </v-radio-group>

              </v-col>

            </v-row>
            <v-banner
                sticky
                color="white"
                class="px-0"
                style="top: 100px"
            >
              <v-divider light class="mb-0"></v-divider>
              <v-row v-if="dateRange" class="align-center">
                <v-col cols="7">
                  <h2 class="primary--text mt-1" v-if="dateRange.start != dateRange.end">
                    Report 1. {{
                        moment(dateRange.start).locale('de').format('MMM YYYY')
                      }} - {{
                        moment(dateRange.end).daysInMonth()
                      }}. {{ moment(dateRange.end).locale('de').format('MMM YYYY') }}
                  </h2>
                  <h2 class="primary--text mt-1" v-else>
                    Report 1. - {{
                        moment(dateRange.start).daysInMonth()
                      }}. {{ moment(dateRange.start).locale('de').format('MMM YYYY') }}
                  </h2>
                </v-col>
                <v-col cols="5" class="text-right">
                  <StatisticsBewohnerPrintModalDialog :wohneinheit="selectedWohneinheit" @print="printBewohnerKvg"/>

                  <v-hover v-slot="{ hover }" open-delay="0">
                    <v-btn
                        @click="printIt()"
                        :disabled="!showResult || kvg === undefined"
                        text
                        class="print-btn"
                    >
                      <v-img :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')" alt="" style="margin:0 auto;" max-width="30" class="cursor-pointer"></v-img>
                      <div class="block pt-0 caption text-center normal">
                        ganze Wohneinheit drucken
                      </div>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-0 pt-0">
                  <v-divider light class="mb-3"></v-divider>
                </v-col>
              </v-row>

              <v-row v-if="selectedWohneinheit">
                <v-col cols="12">
                  <h2 class="mb-2">{{ selectedWohneinheit.wohneinheitenName }}</h2>
                </v-col>
              </v-row>
              <v-row v-if="selectedWohneinheit">
                <v-col cols="12">
                  <v-divider light></v-divider>
                </v-col>
              </v-row>
            </v-banner>
            <v-row v-if="showResult" v-for="(wh,index) in kvg" :key="'wh-'+index">
              <v-col cols="12">
                <div v-for="(bew,index2) in wh.bewohner" :key="'wh-'+index+'bew-'+index2" class="mb-16">
                  <v-row>
                    <v-col cols="2" class="pt-0 mt-0"></v-col>
                    <v-col cols="10" class="pt-0 mt-0 mb-0 pb-0">
                      <v-row v-if="bew.produkte.length > 0">
                        <v-col cols="4">

                        </v-col>
                        <div class="pr-4" :style="'width:'+columnWidths[0]+'px'">
                      <span class="caption heavy">
                         MiGeL
                      </span>
                        </div>
                        <div class="text-center pr-4 caption heavy pb-8" :style="'width:'+columnWidths[1]+'px'">
                          Stück
                        </div>
                        <div class="text-right pr-4 caption heavy pb-8" :style="'width:'+columnWidths[2]+'px'">
                          Stückpreis
                        </div>
                        <div class="text-right pr-4 caption heavy pb-8" :style="'width:'+columnWidths[3]+'px'">
                          Total<br>Stückpreis
                        </div>

                        <div class="text-center pl-3 pr-4 caption heavy pb-8" :style="'width:'+columnWidths[4]+'px'">
                          Beutel<br>(aufgerundet)
                        </div>
                        <div class="text-right pr-4  caption heavy pb-8" :style="'width:'+columnWidths[5]+'px'">
                          Total<br>Beutelpreis
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0 pb-16 mt-0 mb-12">
                    <v-col cols="2" class="py-0 my-0">
                      <v-card color="secondary" light outlined class="d-flex flex-column" style="height: 100%">
                        <v-list-item class="align-start">
                          <v-list-item-content class="body-2 align-self-start">
                            <v-row>
                              <v-col>
                                <div class="pt-1 pb-2 subtitle-1 normal" style="font-size: 18px !important;font-weight: normal !important;">
                                  {{ bew.vorname }} {{ bew.name }}
                                </div>
                                <div class="pt-0">
                                  {{ bew.zimmer }}
                                </div>
                                <div v-if="bew.deletedAt" class="bewohner-deleted">
                                  Bewohner gelöscht am {{ moment(bew.deletedAt).locale('de').format('DD.MM.YYYY') }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                        <v-card-actions>
                          <InkoGradIndicatorPrint :inkograd="bew.inkogradHistory[0].inkograd" :show-drips="true" v-if="bew.inkogradHistory.length > 0"/>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col v-if="bew.produkte.length > 0" cols="10" class="pt-0 mt-0 mb-0 pb-0">

                      <v-row v-for="(prod,index3) in bew.produkte" v-if="prod.stk > 0" :key="'wh-'+index+'bew-'+index2+'prod-'+index3">
                        <v-col cols="1" class="text-center">
                        <span v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0]['images'][0] ">
                       <img alt=""
                            :src="bilder[prod.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                            height="45"/>
                      </span>
                        </v-col>
                        <v-col cols="3">
                          <product-caption :artnr="prod.ArtikelNrLAG" :name="prod.Bezeichnung1"
                                           :einheit="prod.Verkaufseinheit"></product-caption>
                        </v-col>
                        <div class="pr-4" :style="'padding-top: 11px;width:'+columnWidths[0]+'px'">
                      <span class="caption">
                        {{ prod.MiGeL }}
                      </span>
                        </div>
                        <div class="text-center pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[1]+'px'">
                          {{ prod.stk }}
                        </div>
                        <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[2]+'px'">
                          CHF {{ ((prod.Preis / prod.Inhalt)).toFixed(4) }}
                        </div>
                        <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[3]+'px'">
                          CHF {{ (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100).toFixed(2) }}
                        </div>

                        <div class="text-center beutel pr-4 pl-3 caption" :style="'padding-top: 11px;width:'+columnWidths[4]+'px'">
                          {{ Math.ceil(prod.stk / prod.Inhalt) }}
                        </div>
                        <div class="text-right  beutel pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[5]+'px'">
                          CHF {{
                            (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100).toFixed(2)
                          }}
                        </div>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row class="py-0">
                        <v-col cols="4"></v-col>
                        <div class="text-left pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[0]+'px'">
                          <div class="font-weight-bold" style="font-size: 13px;">Total MiGeL</div>
                        </div>
                        <div class="text-left pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[1]+'px'">
                          <span class="caption">inkl. MwSt.</span>
                        </div>
                        <div class="text-center stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[2]+'px'">

                        </div>
                        <div class="text-center stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[3]+'px'">
                          <div class="flex-fill text-right caption" style="font-size: 13px;">CHF
                            {{ totalStk(bew.produkte,true).mwst }}
                          </div>
                          <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                            {{ totalStk(bew.produkte,true).total }}
                          </div>
                        </div>
                        <div class="text-center beutel  pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[4]+'px'">

                        </div>
                        <div class="text-center beutel  pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[5]+'px'">
                          <div class="flex-fill text-right caption" style="font-size: 13px;">CHF
                            {{ totalBeutel(bew.produkte,true).mwst }}
                          </div>
                          <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                            {{ totalBeutel(bew.produkte,true).total }}
                          </div>
                        </div>
                      </v-row>
                      <v-row class="py-0" v-if="totalStk(bew.produkte,true).total != totalStk(bew.produkte,false).total">
                        <v-col cols="4"></v-col>
                        <div class="text-left pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[0]+'px'">
                          <div class="font-weight-bold" style="font-size: 13px;">Total Gesamt</div>
                        </div>
                        <div class="text-left pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[1]+'px'">
                          <span class="caption">inkl. MwSt.</span>
                        </div>
                        <div class="text-center stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[2]+'px'">

                        </div>
                        <div class="text-center stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[3]+'px'">
                          <div class="flex-fill text-right caption" style="font-size: 13px;">CHF
                            {{ totalStk(bew.produkte,false).mwst }}
                          </div>
                          <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                            {{ totalStk(bew.produkte,false).total }}
                          </div>
                        </div>
                        <div class="text-center beutel pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[4]+'px'">

                        </div>
                        <div class="text-center beutel  pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[5]+'px'">
                          <div class="flex-fill text-right caption" style="font-size: 13px;">CHF
                            {{ totalBeutel(bew.produkte,false).mwst }}
                          </div>
                          <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                            {{ totalBeutel(bew.produkte,false).total }}
                          </div>
                        </div>
                      </v-row>
                      <v-row class="mb-0 pb-0">
                        <v-col class="mb-0 pb-0">
                          <v-divider class="mb-0 pb-0"></v-divider>
                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col v-else cols="10" class="pt-6 pb-0 d-flex flex-column justify-space-between">
                      keine Produkte in diesem Zeitraum verwendet
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <vue-html-2-pdf
          v-if="showResult && kvg !== undefined"
          ref="reportPdf"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="x"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="1015px"
      >
        <section slot="pdf-content">
          <print-content-kvg slot="pdf-content" :date-range="dateRangeForPrint" :results="kvg" :kunde="kunde"></print-content-kvg>
        </section>
      </vue-html-2-pdf>

      <vue-html-2-pdf
          v-if="kvg !== undefined && pdf.bewohnerKvg.render"
          ref="pdfKvgBewohner"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="x"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="1015px"
      >
        <section slot="pdf-content">
          <PrintContentKvgBewohner slot="pdf-content" :content="pdf.bewohnerKvg" />
        </section>
      </vue-html-2-pdf>
    </v-container>
  </div>

</template>
<script>
import wohneinheiten, {
  wohneinheitenGetters,
  wohneinheitenActions,
  wohneinheitenMutations
} from '@/obsvervables/wohneinheiten';
import {kvgGetters, kvgActions, kvgMutations} from '@/obsvervables/statistiken/kvg';
import VueHtml2Pdf from "@/components/app/print/VueHtml2Pdf";
import PrintContentKvg from "../../components/app/print/PrintContentKvg";
import InkoGradIndicatorPrint from "../../components/app/InkoGrad/InkoGradIndicatorPrint";
import StatisticsBewohnerPrintModalDialog from "../../components/app/Statistics/StatisticsBewohnerPrintModalDialog";
import PrintContentKvgBewohner from "../../components/app/print/PrintContentKvgBewohner";
import { kundeGetters } from  "@/obsvervables/kunde";
import {benutzerActions, benutzerGetters, benutzerMutations} from "../../obsvervables/benutzer";
import {ActiveWohneinheitMixin} from "../../mixins/ActiveWohneinheitMixin";

export default {
  name: 'Kvg',
  components: {
    PrintContentKvgBewohner,
    StatisticsBewohnerPrintModalDialog,
    InkoGradIndicatorPrint,
    VueHtml2Pdf,
    PrintContentKvg
  },
  mixins: [ActiveWohneinheitMixin],
  data() {
    return {
      columnWidths: [100, 100, 100, 100, 125, 100],
      wohneinheitenChunkSize: 7,
      monthRange: [],
      dates: [this.moment().format('YYYY-MM')],
      showResult: false,
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      pdf: {
        bewohnerKvg: {
          customer: null,
          render: false,
          dateRange: null,
          wohneinheit: null,
          bewohner: []
        }
      }
    }
  },

  computed: {
    ...wohneinheitenGetters,
    ...kvgGetters,
    ...kundeGetters,
    ...benutzerGetters,

    selectedWohneinheit() {
      return this.kvg.length > 0 ? this.kvg[0] : null
    },

    selectedWohneinheiten: {
      get() {
        return this.activeWohneinheit
      },
      set(val) {
        benutzerMutations.setActiveWohneinheit(val)
      }
    },

    dateRange() {
      if (this.dates[0]) {
        if (this.dates[1]) {

          let startdate = this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1]

          let enddate = this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1]

          return {
            'start': startdate,
            'end': enddate
          }
        } else {
          return {
            'start': this.dates[0],
            'end': this.moment(this.dates[0]).add(this.moment(this.dates[0]).daysInMonth() - 1, 'd').format('YYYY-MM')
          }
        }

      } else {
        return false;
      }
    },

    dateRangeForPrint()
    {
      if(!this.dateRange)
        return false

      return {
        start: this.dateRange.start,
        end: this.moment(this.dateRange.end).endOf('month')
      }
    },

    wohneinheitenSelect() {
      let wohneinheiten = this.wohneinheiten.map(function (el) {
        return {
          'name': el.name,
          'id': el.id
        }
      })

      let chunks = []

      for (let i = 0; i < wohneinheiten.length; i += this.wohneinheitenChunkSize) {
        const chunk = wohneinheiten.slice(i, i + this.wohneinheitenChunkSize);
        chunks.push(chunk);
      }

      return chunks
    },

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  watch: {

    dates() {
      if (this.dates.length < 2) {
        this.showResult = false;
      }
    },

    dateRange() {
      if (this.selectedWohneinheiten) {
        this.generateReport()
      }
    },

    selectedWohneinheiten() {
      if (this.dateRange) {
        this.generateReport()
      }
    },
  },


  methods: {
    ...wohneinheitenActions,
    ...wohneinheitenMutations,
    ...kvgActions,
    ...kvgMutations,
    ...benutzerActions,

    totalStk(bewProducts,onlyMigel) {

      var total = 0;
      var mwst = 0;
      bewProducts.forEach(function (prod) {
        if ((onlyMigel && (prod.MiGeL != '' && prod.MiGeL)) || !onlyMigel) {

          let p = parseFloat((prod.stk * (prod.Preis / prod.Inhalt)).toFixed(2));
          let mwstProd =  p * (prod.MwStSatz / 100);

          mwst += mwstProd ;
          total += (p + mwstProd) * 100 / 100;
        }
      })

      return {
        total:  total.toFixed(2),
        mwst:  mwst.toFixed(2),
      }
    },

    totalBeutel(bewProducts,onlyMigel) {
      var total = 0;
      var mwst = 0;
      bewProducts.forEach(function (prod) {
        if ((onlyMigel && (prod.MiGeL != '' && prod.MiGeL)) || !onlyMigel) {

          let p = parseFloat((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis).toFixed(2));
          let mwstProd =  p * (prod.MwStSatz / 100);

          mwst += mwstProd;
          total += (p + mwstProd) * 100 / 100;

        }
      })

      return {
        total:  total.toFixed(2),
        mwst:  mwst.toFixed(2),
      }
    },


    generateReport() {
      this.calculateKvg(this.dateRange, [this.selectedWohneinheiten]);
      this.showResult = true;
    },

    async printIt() {
      await this.$refs.reportPdf.generatePdf();
    },

    async printBewohnerKvg(selectedBewohner) {
      if(selectedBewohner.length > 0) {
        this.pdf.bewohnerKvg.customer = this.kunde
        this.pdf.bewohnerKvg.dateRange = this.dateRangeForPrint
        this.pdf.bewohnerKvg.wohneinheit = this.selectedWohneinheit
        this.pdf.bewohnerKvg.bewohner = selectedBewohner
        this.pdf.bewohnerKvg.render = true

        // we need a tiny delay to ensure the pdf container is ready
        setTimeout(async function () {
          await this.$refs.pdfKvgBewohner.generatePdf();
        }.bind(this), 300)
      }
    }
  },

  async created() {
    this.fetchWohneinheiten();
    this.fetchBenutzer();
  },

  mounted() {
    this.initActiveWohneinheit()
    this.generateReport()
  }
}
</script>
<style lang="scss">
.v-picker__title {
  display: none;
}

.v-banner__wrapper {
  padding: 0 !important;
  border: none !important;
}

.print-btn {
  height: initial !important;
  padding: 4px 16px 2px !important;

  .v-btn__content {
    flex-direction: column;
  }
}

#kgv {

  .bewohner-deleted {
    margin-top: 5px;
    font-size: 0.75rem;
    color: #b71c1c;
  }

  .stk {
    background: rgba(253, 249, 247, 0.6);
  }

  .beutel {
    background: rgba(249, 245, 243, 1.6);
  }
}

</style>