import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Planung from "@/views/Planung";
import Sortiment from "@/views/Sortiment";
import Wohneinheiten from "@/views/Wohneinheiten";
import Lieferoptionen from "@/views/Lieferoptionen";
import Bewohnerverwaltung from "@/views/Bewohnerverwaltung";
import Account from "@/views/Account";
import Superadmin from "@/views/Superadmin";
import Passwortreset from "@/views/Passwortreset";
import Tooladmin from "@/views/Tooladmin";

import {store} from "../store";
import { isLoggedIn } from '@/utils/auth';
import Statistiken from "@/views/Statistiken";
    import Upcoming from "@/views/statistiken/Upcoming";
    import Demo from "@/views/statistiken/Demo";
    import Werhatwas from "@/views/statistiken/Werhatwas";
    import Kvg from "@/views/statistiken/Kvg";
import InkoBarometer from "@/views/InkoBarometer";
import ProductSorting from "../views/SuperAdmin/ProductSorting";
import UserManagement from "../views/SuperAdmin/UserManagement";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "login"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/neues-passwort",
    name: "passwortreset",
    component: Passwortreset,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/logut",
    name: "logut",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/planung",
    name: "planung",
    component: Planung
  },
  {
    path: "/sortiment",
    name: "sortiment",
    component: Sortiment
  },
  {
    path: "/wohneinheiten",
    name: "wohneinheiten",
    component: Wohneinheiten
  },
  {
    path: "/lieferoptionen",
    name: "lieferoptionen",
    component: Lieferoptionen
  },
  {
    path: "/bewohnerverwaltung",
    name: "bewohnerverwaltung",
    component: Bewohnerverwaltung
  },
  {
    path: '/barometer',
    name: 'barometer',
    component: InkoBarometer
  },
  {
    path: "/statistiken",
    name: "statistiken",
    component: Statistiken
  },
  {
    path: "/statistiken/upcoming",
    name: "upcoming",
    component: Upcoming
  },
  {
    path: "/statistiken/wer-hat-was",
    name: "werhatwas",
    component: Werhatwas
  },
  {
    path: "/statistiken/demo",
    name: "demo",
    component: Demo
  },
  {
    path: "/statistiken/kvg",
    name: "kvg",
    component: Kvg
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
  {
    path: "/superadmin",
    name: "superadmin",
    component: Superadmin
  },
  {
    path: "/superadmin/produktsortierung",
    name: "porductsorting",
    component: ProductSorting
  },
  {
    path: "/superadmin/user-management",
    name: "user-management",
    component: UserManagement
  },
  {
    path: "/tooladmin",
    name: "tooladmin",
    component: Tooladmin
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

//protected routes -> wenn nicht "allowAnonymous: true" dann redirect auf login sofern keine authorisierung vorhanden ist
router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous  && (store.state.benutzer.id === undefined || store.state.benutzer.id == 0  || !store.state.benutzer.id || !isLoggedIn())) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})


export default router
