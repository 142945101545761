<template>
  <div class="pr-3">
    <v-row class="head-row">
      <v-col cols="2" xl="3">

      </v-col>
      <v-col>
        <v-row class="month-row" :class="{ isCurrentYear: isCurrentYear }">
          <v-col v-for="i in 12" :key="'month-'+i" :class="{ currentMonth: isCurrentYear && currentMonth+1 == i}">
            <span class="heavy">{{ month[i - 1] }}</span><br>
            Stk
          </v-col>
          <v-col class="highlighted">
            <span class="heavy">Total</span><br>
            Beutel
          </v-col>
          <v-col class="highlighted">
            <span class="heavy">Preis</span><br>
            Beutel
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <BarometerProduct v-for="product,i in products" :key="'product-'+i" :product="product"/>

    <div style="page-break-inside: avoid;">
      <v-row class="summary-row bewohner-cost-row">
        <v-col cols="2" xl="3">
          Inko-Kosten Bewohner (CHF inkl. MwSt)
        </v-col>
        <v-col>
          <v-row>
            <v-col v-for="i, month in 12" :key="'month-'+i" :class="{ futureMonth: isFutureYear || (isCurrentYear && currentMonth < month) }">
              {{ inkoCostMonth[month] ? inkoCostMonth[month].toFixed(2) : '' }}
            </v-col>
            <v-col class="highlighted">

            </v-col>
            <v-col class="highlighted" :class="{ futureMonth: isFutureYear|| isCurrentYear }">
              {{ inkoCostTotal.toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="summary-row">
        <v-col cols="2" xl="3">
          Pauschale MiGel (CHF)
        </v-col>
        <v-col>
          <v-row>
            <v-col v-for="i, month in 12" :key="'month-'+i" :class="{ futureMonth: isFutureYear || (isCurrentYear && currentMonth < month) }">
              {{ inkoAllowanceMonth[month] ? inkoAllowanceMonth[month].toFixed(2) : '' }}
            </v-col>
            <v-col class="highlighted">

            </v-col>
            <v-col class="highlighted" :class="{ futureMonth: isFutureYear || isCurrentYear }">
              {{ inkoAllowanceTotal.toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="summary-row" v-if="kostengutspracheHistory && kostengutspracheHistory.length > 0 && kostengutspracheTotal > 0">
        <v-col cols="2" xl="3">
          Kostengutsprache (CHF)
        </v-col>
        <v-col>
          <v-row>
            <v-col v-for="i, month in 12" :key="'month-'+i" :class="{ futureMonth: isFutureYear || (isCurrentYear && currentMonth < month) }">
            <span v-if="kostengutspracheMonth[month] > 0">
              {{ kostengutspracheMonth[month].toFixed(2) }}
            </span>
            </v-col>
            <v-col class="highlighted">

            </v-col>
            <v-col class="highlighted" :class="{ futureMonth: isFutureYear || isCurrentYear }">
              {{ kostengutspracheTotal.toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="summary-row total-row">
        <v-col cols="2" xl="3">
          Abweichung
        </v-col>
        <v-col>
          <v-row>
            <v-col v-for="(i, month) in 12" :key="'month-'+i" :class="{ futureMonth: isFutureYear || (isCurrentYear && currentMonth < month) }">
              {{ inkoAllowanceDifferneceMonth[month].toFixed(2) }}
            </v-col>
            <v-col class="highlighted">

            </v-col>
            <v-col class="highlighted" :class="{ futureMonth: isFutureYear || isCurrentYear }">
              {{ inkoAllowanceDifferneceTotal.toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" xl="3" class="pt-6">
          <strong class="barometer-bar-title">Überblick Guthaben</strong>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="barometer-col" :style="isPrint? 'margin-top: 15px;': ''">
              <BarometerBar :ist="inkoCurrentCost" :cost="inkoCostTotal" :allowance="inkoAllowanceTotal + kostengutspracheTotal" :year="year" class="pt-6"/>
              <v-row class="barometer-row">
                <v-col v-for="i in 12" :key="'colfiller-'+i"></v-col>
              </v-row>
            </v-col>
            <v-col class="total-col heavy">
              Kosten bisher {{ inkoCurrentCost.toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BarometerProduct from "./BarometerProduct";
import BarometerBar from "./BarometerBar";

export default {
  name: 'BarometerProductList',
  components: {BarometerBar, BarometerProduct},

  props: {
    year: {
      type: Number,
      required: true
    },

    products: {
      type: Array,
      required: true
    },

    inkogradHistory: {
      type: Array,
      required: true
    },

    kostengutspracheHistory: {
      type: Array,
      required: true
    },

    isPrint: {
      type:Boolean,
      default: false
    }
  },

  data() {
    return {
      month: ['JAN', 'FEB', 'MÄR', 'APR', 'MAI', 'JUNI', 'JULI', 'AUG', 'SEPT', 'OKT', 'NOV', 'DEZ']
    }
  },

  computed: {
    currentYear() {
      return +this.moment().year()
    },

    isCurrentYear() {
      return this.year === this.currentYear
    },

    isFutureYear() {
      return this.year > this.currentYear
    },

    currentMonth() {
      return +this.moment().month()
    },

    inkoCostMonth() {
      var months = new Array(12).fill(0)

      this.products.forEach((product) => {
        for (let i = 0; i < 12; i++) {
          months[i] += Math.round(product.calendar.months[i].total / 0.05) * 0.05
        }
      })

      return months
    },

    inkoCostTotal() {
      return Math.round((this.inkoCostMonth.reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    },

    inkoCurrentCost() {
      if (this.year !== +this.moment().format('YYYY')) {
        return this.inkoCostTotal
      } else {
        let total = 0

        for (let i = 0; i < this.moment().format('MM'); i++) {
          total += this.inkoCostMonth[i]
        }

        return Math.round((total + Number.EPSILON) * 100) / 100
      }
    },

    /**
     *
     */
    inkoAllowanceMonth() {
      let months = new Array(12).fill(null)


      for (let i = 0; i < 12; i++) {

        let date = this.moment([this.year, i])
        let inkogradHistory = this.inkogradHistory.filter(ih => ih.start <= date.unix())

        // check if a inkogradHistory exists and if this month has any costs (no costs = no allowance)
        if (inkogradHistory && inkogradHistory.length > 0 && this.inkoCostMonth[i] > 0) {
          months[i] = Math.round((this.getInkogradAllowanceByMonth(inkogradHistory[inkogradHistory.length - 1], this.year, i + 1) / 12) / 0.05) * 0.05
        } else {
          months[i] = 0
        }

      }

      return months
    },

    kostengutspracheMonth() {
      let months = new Array(12).fill(null)


      for (let i = 0; i < 12; i++) {
        let date = this.moment([this.year, i])
        let inkogradHistory = this.inkogradHistory.filter(ih => ih.start <= date.unix())

        // only calculate gutsprache if bewohner has total inkontinenz
        if (inkogradHistory && inkogradHistory.length > 0 && inkogradHistory[inkogradHistory.length - 1].inkograd === 5 && this.kostengutspracheHistory) {

          let kostengutsprache = this.kostengutspracheHistory.filter(a => a.start <= date.unix() && this.moment.unix(a.start).year() === this.year)

          if (kostengutsprache && kostengutsprache.length > 0) {
            months[i] = kostengutsprache[0].terminated ? 0 : kostengutsprache[0].amount / 12
          } else {
            months[i] = 0
          }
        }
      }

      return months
    },

    /**
     *
     */
    inkoAllowanceTotal() {
      return Math.round((this.inkoAllowanceMonth.reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    },

    /**
     *
     */
    kostengutspracheTotal() {
      return Math.round(this.kostengutspracheMonth.reduce((a, b) => a + b, 0) / 0.05) * 0.05
    },

    /**
     *
     */
    inkoAllowanceDifferneceMonth() {
      let months = new Array(12).fill(null)

      for (let i = 0; i < 12; i++) {
        let diffrence = 0

        if (this.kostengutspracheHistory && this.kostengutspracheHistory.length > 0) {
          diffrence = this.inkoAllowanceMonth[i] + this.kostengutspracheMonth[i] - this.inkoCostMonth[i]
        } else {
          diffrence = this.inkoAllowanceMonth[i] - this.inkoCostMonth[i]
        }


        months[i] = (Math.round(diffrence / 0.05) * 0.05) * -1
      }

      return months
    },

    inkoAllowanceDifferneceTotal() {
      return (Math.round((this.inkoAllowanceDifferneceMonth.reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100)
    }
  },

  methods: {
    getInkogradAllowanceByMonth(inkograd, year, month) {
      if (inkograd.allowance && inkograd.allowance.length > 0) {
        let date = this.moment(year + '-' + month + '-01', 'YYYY-MM-DD')

        let validAllowances = inkograd.allowance.filter(a => a.start <= date.unix())

        if (validAllowances && validAllowances.length > 0) {
          return validAllowances[validAllowances.length - 1].HVB_Pflege
        }
      }

      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.barometer-col {
  position: relative;
  flex: 0 0 84.8%;
  padding-top: 0 !important;

  .barometer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}


.row {
  height: 100%;

  &.month-row {
    padding-left: 2px;
  }

  .col {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;

    &:first-child {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.currentMonth span {
      color: #f38b1c;
    }

    &.futureMonth {
      color: #bab6b6;
      font-style: italic;
    }

    .col {
      //padding-top: 10px;
      //padding-bottom: 10px;
      padding: 10px 0;

      &:nth-child(even) {
        background-color: #fdf9f7;
      }

      &.highlighted {
        background-color: #f4ede7;
      }
    }
  }
}

.head-row {
  font-size: 13px;
}

.barometer-bar-title,
.total-col {
  font-size: 14px;
}

.head-row,
.barometer-row {
  margin-left: 0;

  .col {
    padding: 0;
    text-align: center !important;

    &:nth-child(even) {
      background-color: #fdf9f7;
    }

    &:last-child {
      padding-right: 12px;
    }
  }
}

.summary-row {
  font-size: 14px;

  &.bewohner-cost-row {
    border-top: 1px solid #616161;
  }

  .col {
    &:last-child {
      padding-right: 12px;
    }
  }

  .highlighted {
    background-color: #e9dcd1;
  }
}

.total-row {
  .col-2 {
    background-color: #f4ede7;
  }

  .col {
    background-color: #f4ede7;

    .col {
      &:nth-child(even) {
        background-color: #f1e6dd;
      }

      &:last-child {
        padding-right: 12px;
      }

      &.highlighted {
        background-color: #e9dcd1;
      }
    }
  }
}

.total-col {
  display: flex;
  align-items: center;
  padding: 15px 0 0 12px !important;
}

</style>
