<template>
  <v-dialog
      v-model="show"
      persistent
      width="900"
      content-class="custom-dialog"
  >
    <template v-slot:activator="{ props }">
      <slot name="button" :open="open"></slot>
    </template>

    <v-card class="main">
      <header>
        <div class="title">
          <h1 class="black--text">Pauschale Fixierhosen und Krankenunterlagen erfassen
            <template v-if="bewohner.vorname || bewohner.name">- <span class="primary--text">{{
                bewohner.vorname + " " + bewohner.name
              }}</span>
            </template>
          </h1>
        </div>
        <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
        </div>
      </header>
      <v-divider></v-divider>
     <div class="content">
        <v-form  ref="form" validate-on="input">
          <v-row>
            <v-col cols="4" class="pb-2">
              <strong class="custom-label">Monatliche Pauschale CHF</strong><br/>
              <v-text-field type="number" placeholder="0.00" :disabled="disabledBudget" label="" v-model="formData.monthly_budget" variant="underlined"></v-text-field>
              <div style="margin-top: -20px">
                <v-checkbox v-model="formData.no_budget"  @change="setNoBudget()" label="Keine Pauschale" dense></v-checkbox>
              </div>
            </v-col>
            <v-col cols="1">
            </v-col>
            <v-col cols="4">
              <strong class="custom-label">Beginnend ab welchem Monat</strong><br/>
              <custom-month-picker :key="'cal-'+formData.start" @updateDate="formData.start = $event" :dateValue="formData.start" :minDate="minDate" :rules="rules.ruleNotEmpty"></custom-month-picker>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-card-actions>
        <v-btn color="danger"  elevation="0" variant="flat" rounded @click="cancel()">Abbrechen</v-btn>
        <v-btn color="primary"  elevation="0" variant="flat" rounded @click="save()" :disabled="formData.monthly_budget === ''">Speichern und
          schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {globalRules} from "@/composables/rules";
import moment from "moment";
import ConfirmDialog from '@/components/app/bewohner/Bewohnerverwaltung/dialogs/ConfirmDialog.vue'
import CustomDatePicker from '@/components/form/CustomDatePicker.vue'
import CustomMonthPicker from '@/components/form/CustomMonthPicker.vue'
import InkoGradIndicator from '@/components/app/InkoGrad/InkoGradIndicator.vue'

export default {
  name: 'FixierhosenBudgetDialog',
  components: { InkoGradIndicator, CustomMonthPicker, CustomDatePicker, ConfirmDialog},
  emits: ['cancel', 'save'],
  props: {
    'data': {
      required: false,
      default: null
    },
    'bewohner': {
      required: false,
      default: null
    },
    'lastData': {
      required: false,
      default: null
    },
    'minDate': {
      required: true,
      default: moment()
    }
  },
  data() {
    return {
      show: false,
      disabledBudget: false,
      form: null,
      formData: {},
      dateMenu: false,
      valid: false,
      rules: globalRules,
      fixierhosenBlueprint: {
        'monthly_budget': 0,
        'start': this.minDate ? this.minDate.format("YYYY-MM") : moment().format("YYYY-MM"),
      }
    }
  },
  computed: {


  },
  methods: {

    setNoBudget() {
      if (this.formData.no_budget) {
        this.disabledBudget = true;
        this.formData.monthly_budget = 0;
      }else{
        this.disabledBudget = false;
        this.formData.monthly_budget = '';
      }
    },

    open() {
      this.show = true
    },

    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    save() {
      this.$emit('save', {
        monthly_budget: this.formData.monthly_budget,
        start: moment(this.formData.start).format("YYYY-MM-DD")
      });
      this.show = false
    },

    initData() {
      if (this.show) {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
        if (this.data) {
          this.formData = Object.assign({}, this.data)
        } else {
          this.formData = Object.assign({}, {
            'monthly_budget': '', // this.lastData ? this.lastData.grade : -1,
            'start': this.minDate ? this.minDate.format("YYYY-MM") : moment().format("YYYY-MM"),
          })
        }
      }
    }
  },

  watch: {
    show() {
      this.initData();
    }
  },

  mounted() {
    this.initData();
  }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.stepper {
  float: left;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}

.previous-step-action, .next-step-action {
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
  cursor: pointer;

  img {
    top: 9px;
    position: relative;
  }
}

.previous-step-action {
  float: left;

  img {
    margin-right: 7px;
  }
}

.next-step-action {
  float: right;

  img {
    margin-left: 7px;
  }
}


.v-radio {
  .name {
    width: 160px;
    font-size: 16px;
    font-weight: 300;
    font-family: "Avenir LT Std";
  }

  .since {
    padding-left: 20px;
  }
}

.v-radio--is-disabled {
  &::v-deep {
    label {
      color: rgba(0, 0, 0, 0.6);

      .name {
        opacity: 0.5;
      }
    }

    .v-input--selection-controls__input {
      opacity: 0.5;

    }
  }
}

.custom-tabs {
  margin: 0 -10px 20px;

  &::v-deep {
    .v-slide-group__next, .v-slide-group__prev {
      display: none !important;
    }
  }

  .v-tab {
    border: 1px solid black;
    border-radius: 10px !important;
    border-width: 0;
    margin-right: 10px;
    padding: 0 10px;
    font-size: 16px;
    font-family: "Avenir LT Std Heavy";
    height: 39px;

    &:last-of-type {
      margin-right: 0;
    }

    &.v-tab--selected {
      border-width: 1px;
    }

    &::v-deep {
      .v-btn__content {
        .v-tab__slider {
          opacity: 0 !important;
        }
      }
    }
  }
}

.content {
  margin-top: 30px;
  margin-bottom: 30px;
}


.v-btn--rounded {
  border-radius: 10px;
}

.deactiveIcon {
  display: block;
  float: left;
  flex: none;
  cursor: pointer;
}
</style>
