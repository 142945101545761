import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store';
import vuetify from './plugins/vuetify';
import underscore from 'vue-underscore';
import moment from 'moment-timezone';
require('moment/locale/de')

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

Vue.use(underscore);

moment.tz.setDefault('Europe/Zurich');

Vue.prototype.moment = moment


import { setupInterceptors } from './utils/httpInterceptors';

Vue.component('product-caption', () => import("./components/app/products/ProductCaption"));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    setupInterceptors(store);
  },
}).$mount('#app')

