import {barometerMutations} from "../obsvervables/barometer";

export const ActiveWohneinheitMixin = {

    methods: {
        initActiveWohneinheit() {
            if(this.activeWohneinheit && this.activeWohneinheit > 0) {
                this.panel = this.wohneinheiten.findIndex(wh => wh.id === +this.activeWohneinheit)
                barometerMutations.setActiveAccordion(this.panel)
            }
        }
    },

    watch: {
        activeWohneinheit () {
            this.initActiveWohneinheit()
        },

        wohneinheiten() {
            this.initActiveWohneinheit()
        },
    }
}