import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {_} from 'vue-underscore';

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const planungsdatenState = Vue.observable({
    "planungsdaten": []
});

export const planungsdatenGetters = {
    planungsdaten: () => planungsdatenState.planungsdaten
}

export const planungsdatenMutations = {
    setPlanungsdaten(planungsdaten) {
        planungsdatenState.planungsdaten = planungsdaten;
    }
}

export const planungsdatenActions = {
    fetchPlanungsdaten(){
        //MUSS IMMER AKTUELL SEIN AUF KLICK DA ERRECHNET!!
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/planungsdaten',
            method: 'GET'
        }).then(function (res) {

            for(var i in res.planungsdaten){
                res.planungsdaten[i] = _.sortBy(res.planungsdaten[i],'SortKey');
            }

            planungsdatenMutations.setPlanungsdaten(res.planungsdaten)
        })
    },

    savePlanungsdaten(whid){
        httpClient({
            url: process.env.VUE_APP_API_URL+'save/planungsdaten',
            method: 'post',
            data: { 'planungsdaten': planungsdatenState.planungsdaten,'whid':whid }
        }).then(function (res) {
            //console.log(res)
            if(res.type === 'success'){
                for(var i in res.planungsdaten.planungsdaten){
                    res.planungsdaten.planungsdaten[i] = _.sortBy(res.planungsdaten.planungsdaten[i],'SortKey');
                }

                planungsdatenMutations.setPlanungsdaten(res.planungsdaten.planungsdaten)
            }
        })
    }
}

export default {
    planungsdatenState,
    planungsdatenGetters,
    planungsdatenMutations,
    planungsdatenActions
}