<template>
  <div>
    <div v-for="inkograd, i in inkogradHistorySubsidised" :key="'inkograd-'+i" class="mt-3">
      <InkoGradIndicator :inkograd="inkogradHistorySubsidised[i].inkograd" />
      <div class="mt-3">
        {{ moment.unix(inkogradHistorySubsidised[i].start).locale('de').format('MMM YYYY') }}
        <span v-if="inkogradHistorySubsidised[i].end">- {{
            moment.unix(inkogradHistorySubsidised[i].end).locale('de').format('MMM YYYY')
          }}</span>
        <span v-else-if="inkogradHistorySubsidised[i+1]">- {{
            moment.unix(inkogradHistorySubsidised[i + 1].start).subtract(1, 'months').locale('de').format('MMM YYYY')
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import InkoGradIndicator from "./InkoGradIndicator";

export default {
  name: 'InkoGradList',
  components: {InkoGradIndicator},
  props: {
    year: {
      type: Number,
      required: true
    },

    inkogradHistory: {
      type: Array,
      required: true
    }
  },


  computed: {
    orderedHistory() {
      return this.inkogradHistory.sort(function(a,b){
        return this.moment.unix(a.start) - this.moment.unix(b.start);
      }.bind(this));
    },

    inkogradHistorySubsidisedLastYear() {
      let lastYearInko = this.orderedHistory.filter(i => i.inkograd > 1 && (this.moment.unix(i.start).format('YYYY') < this.year))

      return lastYearInko.length > 0 ? lastYearInko[lastYearInko.length - 1] : null
    },

    inkogradHistorySubsidised() {
      let history = this.orderedHistory.filter(i => i.inkograd > 1 && (this.moment.unix(i.start).format('YYYY') == this.year))

      if (this.inkogradHistorySubsidisedLastYear) {
        history.unshift(this.inkogradHistorySubsidisedLastYear)
      }

      return history
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
