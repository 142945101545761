import Vue from 'vue'
import httpClient from "@/utils/httpClient";

httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const kvgState = Vue.observable({
    "kvg": [],
});

export const  kvgGetters = {
    kvg: () =>  kvgState.kvg,
}

export const kvgMutations = {
    setKvg(kvg) {
        kvgState.kvg = kvg;
    },
}


export const kvgActions = {
    calculateKvg(dateRange,whIDs){
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/statistik/kvg',
            method: 'POST',
            data:{'wh': whIDs,'dates': dateRange}
        }).then(function (res) {
            kvgMutations.setKvg(res.kvg)
        })
    },
}

export default {
    kvgState,
    kvgGetters,
    kvgMutations,
    kvgActions
}