<template>
  <div style="position: relative;">
    <span class="caption heavy">{{ artnr }}</span> |
    <span class="caption heavy"> {{ einheit }}</span><br>
    <div class="body-2 pt-1">{{ name }}
      <span v-if="p && (p.geloescht === 1 || (p.nachrichten && p.nachrichten.length > 0))">
        <v-tooltip top class="body-2 py-1 px-2 ">
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }" open-delay="0">
              <v-icon :color="p.geloescht === 1 ? 'red' : 'orange'" v-bind="attrs" size="18" v-on="on"
                      style="margin-top: -2px;">
                mdi-information-outline
              </v-icon>
            </v-hover>
          </template>
          <div>
           <span v-if="p.geloescht === 1">
              <span style="font-size: 13px;">Dieses Produkte ist nicht mehr im Puras Sortiment erhältlich.</span>
              <span v-if="p.ersatzartikel" style="font-size: 13px;"><br>Bestellnummer wurden geändert: Neu {{
                  p.ersatzartikel
                }}
            </span>
           <span v-if="p.nachrichten && p.nachrichten.length > 0 && p.geloescht === 1">
              <br><br>
           </span>
          </span>
            <div v-for="(n,index) in p.nachrichten">
              <span style="font-size: 13px;">{{ n.Titel_DE }}<br>{{ n.Text_DE }}</span>
            </div>
          </div>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductCaption',
  props: ['artnr', 'einheit', 'name','p'],
}
</script>