export const benutzer = {
  namespaced: true,
  state: {
    id: 0,
    authToken: false,
    AdressNrADR: 0,
    accessRights: '',
    erpActive: false,
    bewohnerSyncActive: false
  },

  getters: {
    settings: (state) => {
      return state.settings
    },
    info: (state) => {
      return state.info
    },
    isErpSyncActive: (state) => {
      return state.erpActive;
    },

    isBewohnerSyncActive: (state) => {
      return state.bewohnerSyncActive;
    }
  },

  actions: {
    setID({ commit },value) {
      commit("setID",value);
    },
    setAuthToken({commit}, value) {
      commit("setAuthToken", value);
    },
    setAdressNrADR({commit},val){
      commit("setAdressNrADR",val)
    },
    setAccessRights({commit},val){
      commit("setAccessRights",val)
    },
    setErpActive({commit},val){
      commit("setErpActive",val)
    },
    setBewohnerSyncActive({commit},val){
      commit("setBewohnerSyncActive",val)
    },
  },
  mutations: {
    setID(state,val){
      state.id = val;
    },
    setAuthToken(state, val) {
      state.authToken = val;
    },
    setAdressNrADR(state,val){
      state.AdressNrADR = val
    },
    setAccessRights(state,val){
      state.accessRights = val
    },
    setErpActive(state,val){
      state.erpActive = val
    },
    setBewohnerSyncActive(state,val){
        state.bewohnerSyncActive = val
    }
  }
};