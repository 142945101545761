<template>
  <section slot="pdf-content" id="kvgprint">
    <section class="pdf-item" style="max-width: 100%;padding-bottom: 0px;">
      <v-row>
        <v-col cols="8">
          <h1>{{ kunde.Name }}</h1>
          <h2>
            KVG Report | <span>{{
              new Date(dateRange.start).toLocaleDateString() || ''
            }} - {{ new Date(dateRange.end).toLocaleDateString() || '' }} - <span v-if="results && results.length > 0 && results[0].wohneinheitenName">{{ results[0].wohneinheitenName }}</span></span>
          </h2>
        </v-col>
        <v-col cols="4">
              <span style="float: right">
                 <img :src="require('@/assets/img/printlogo.png')" height="40"/>
              </span>
        </v-col>
      </v-row>
    </section>
    <section v-for="(wh,index) in results" :key="'wh-'+index" :style="index>0? 'page-break-before: always;': ''" class="pdf-item avoid">
      <v-row >
        <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
          <v-row class="pt-0 mt-0 mb-0 pb-0">
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-divider light></v-divider>
            </v-col>
          </v-row>
          <div class="pt-0 mt-0 mb-4 pb-0 pdf-item avoid" v-for="(bew,index2) in wh.bewohner" :key="'wh-'+index+'bew-'+index2" style="page-break-inside: avoid;">
            <v-row>

              <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
                <v-row>
                  <div class="pt-0 mt-0 mb-0 pb-0 justify-start" style="width: 262px;padding-left: 13px;">
                     <span class="heavy justify-start" style="vertical-align: top;font-size: 14px; !important">{{ bew.vorname }} {{ bew.name }} | {{bew.zimmer }}</span>
                    <InkoGradIndicatorPrint :inkograd="bew.inkogradHistory[0].inkograd" :show-drips="true" v-if="bew.inkogradHistory.length > 0" />
                    <div v-if="bew.deletedAt" class="bewohner-deleted">
                      Bewohner gelöscht am {{ moment(bew.deletedAt).locale('de').format('DD.MM.YYYY') }}
                    </div>
                  </div>

                  <div class="pr-4 caption heavy pb-6" :style="'width:'+columnWidths[0]+'px'">
                      MiGeL
                  </div>
                  <div class="text-center pr-4 caption heavy pb-6" :style="'width:'+columnWidths[1]+'px'">
                    Stück
                  </div>
                  <div class="text-right pr-4 caption heavy pb-6" :style="'width:'+columnWidths[2]+'px'">
                    Stückpreis
                  </div>
                  <div class="text-right pr-4 caption heavy pb-6" :style="'width:'+columnWidths[3]+'px'">
                    Total<br>Stückpreis
                  </div>

                  <div class="text-center pl-3 pr-4 caption heavy pb-6" :style="'width:'+columnWidths[4]+'px;padding-left: 20px'">
                    Beutel<br>(aufgerundet)
                  </div>
                  <div class="text-right pr-4  caption heavy pb-6" :style="'width:'+columnWidths[5]+'px'">
                    Total<br>Beutelpreis
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <v-row >
              <v-col v-if="bew.produkte.length > 0" cols="12" class="pt-0 mt-0 mb-0 pb-0">

                <v-row class="mb-0 pb-0" v-for="(prod,index3) in bew.produkte" v-if="prod.stk > 0" :key="'wh-'+index+'bew-'+index2+'prod-'+index3">
                  <v-col cols="3" class="print-caption">

                    <div style="position: relative;">
                      <span class="caption" >{{ prod.ArtikelNrLAG }} | </span>
                      <span class="caption"> {{ prod.Verkaufseinheit }}</span><br>
                      <div class="caption pt-1">{{ prod.Bezeichnung1 }}

                      </div>
                    </div>

                  </v-col>
                  <div class="pr-4" :style="'padding-top: 11px;width:'+columnWidths[0]+'px'">
                      <span class="caption">
                        {{ prod.MiGeL }}
                      </span>
                  </div>
                  <div class="text-center pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[1]+'px'">
                    {{ prod.stk }}
                  </div>
                  <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[2]+'px'">
                    CHF {{ ((prod.Preis / prod.Inhalt)).toFixed(4) }}
                  </div>
                  <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[3]+'px'">
                    CHF {{ (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100).toFixed(2) }}
                  </div>

                  <div class="text-center beutel pr-4 pl-3 caption" :style="'padding-top: 11px;width:'+columnWidths[4]+'px;padding-left: 20px'">
                    {{ Math.ceil(prod.stk / prod.Inhalt) }}
                  </div>
                  <div class="text-right  beutel pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[5]+'px'">
                    CHF {{ (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100).toFixed(2) }}
                  </div>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mt-0 mb-0 pb-0">
                    <v-divider  class="pt-0 mt-0 mb-0 pb-0"></v-divider>
                  </v-col>
                </v-row>
                <v-row  class="py-0 my-0">
                 <div  style="width: 262px;padding-left: 13px;"> </div>
                  <div class="text-left pr-4 caption mt-0 pt-0" :style="'padding-top: 8px;width:'+columnWidths[0]+'px'">
                    <div class="font-weight-bold" style="font-size: 13px;">Total MiGeL</div>
                  </div>
                  <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[1]+'px'">
                    <span class="caption" style="font-size: 13px;">inkl. MwSt.</span>
                  </div>
                  <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[2]+'px'">

                  </div>
                  <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[3]+'px'">
                    <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF {{ totalMigelStk(bew.produkte) }}</div>
                  </div>
                  <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[4]+'px;padding-left: 20px'">

                  </div>
                  <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[5]+'px'">
                    <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF {{ totalMigelBeutel(bew.produkte) }}</div>
                  </div>
                </v-row>
                <v-row class="py-0" v-if="totalMigelStk(bew.produkte) != totalAllStk(bew.produkte)">
                  <div  style="width: 262px;padding-left: 13px;"></div>
                  <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[0]+'px'">
                    <div class="font-weight-bold" style="font-size: 13px;">Total Gesamt</div>
                  </div>
                  <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[1]+'px'">
                    <span class="caption" style="font-size: 13px;">inkl. MwSt.</span>
                  </div>
                  <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[2]+'px'">

                  </div>
                  <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[3]+'px'">
                    <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF {{ totalAllStk(bew.produkte) }}</div>
                  </div>
                  <div class="text-center beutel pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[4]+'px;padding-left: 20px'">

                  </div>
                  <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[5]+'px'">
                    <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF {{ totalAllBeutel(bew.produkte) }}</div>
                  </div>
                </v-row>
                <v-row  class="mb-0 pb-0">
                  <v-col  class="mb-0 pb-0">
                    <v-divider class="mb-0 pb-0"></v-divider>
                  </v-col>
                </v-row>

              </v-col>
              <v-col v-else cols="9" class="pt-6">
                keine Produkte in diesem Zeitraum verwendet
              </v-col>

            </v-row>
          </div>


        </v-col>
      </v-row>


    </section>
  </section>
</template>

<script>

import InkoGradIndicatorPrint from "../InkoGrad/InkoGradIndicatorPrint";
export default {
  components: {InkoGradIndicatorPrint},
  data(){
    return {
      columnWidths: [100,100,100,100,110,100],
    }
  },

  name: 'PrintContentKvg',

  props: ['dateRange', 'results', 'kunde'],

  computed: {
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  methods:{
    totalMigelStk(bewProducts){

      var total = 0;
      bewProducts.forEach(function (prod) {
        if(prod.MiGeL != '' && prod.MiGeL){
          total += (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100);
        }
      })

      return total.toFixed(2);
    },

    totalAllStk(bewProducts){
      var total = 0;
      bewProducts.forEach(function (prod) {
        total += (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100);
      })

      return total.toFixed(2);
    },

    totalMigelBeutel(bewProducts){
      var total = 0;
      bewProducts.forEach(function (prod) {
        if(prod.MiGeL != '' && prod.MiGeL) {
          total += (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100);
        }
      })

      return total.toFixed(2);
    },

    totalAllBeutel(bewProducts){
      var total = 0;
      bewProducts.forEach(function (prod) {
        total += (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100);
      })

      return total.toFixed(2);
    },
  }
}
</script>

<style  lang="scss">

#kvgprint{

  h1 {
    margin: 0 0 5px;
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  .bewohner-deleted {
    margin-top: 5px;
    font-size: 0.65rem;
    color: #b71c1c;
  }

  .content-text {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }

  .text-right {
    text-align: right;
    justify-content: flex-end;
  }

  .text-center {
    text-align: center;
    justify-content: center;
  }

  .caption.heavy {
    font-family: "Avenir LT Std Heavy";
    font-size: 0.75rem;
    font-weight: 300;
  }

  .caption {
    font-size: 11px;
    font-weight: normal;
  }

  .bezeichnung {
    font-size: 13px;
  }
}


body .v-application {
  .print-caption{
    *{
      font-size: 12px !important;
    }
    .body-2{
      font-size: 12px !important;
    }
  }
}
</style>