<template>
  <div slot="pdf-content" id="kvgprint-bewohner">
    <section v-for="(bewohner, index2) in content.bewohner" :style="index2>0? 'page-break-before: always;': ''" class="pdf-item avoid">
      <section class="pdf-item" style="max-width: 100%;padding-bottom: 0px;">
        <v-row>
          <v-col cols="8">
            <h1>{{ content.customer.Name }} <span v-if="content.bewohner && content.bewohner.length > 0">- {{ content.wohneinheit.wohneinheitenName }}</span></h1>
            <h2>
              KVG Report | <span>{{ starDateFormated }} - {{
                endDateFormated
              }}</span>
            </h2>
            <div style="display: flex; flex-direction: row;">
              <h3 style="margin-top: 10px">
                {{ bewohner.vorname }} {{ bewohner.name }} | {{ bewohner.zimmer }}
              </h3>
              <InkoGradIndicatorPrint :inkograd="bewohner.inkogradHistory[0].inkograd" :show-drips="true" v-if="bewohner.inkogradHistory.length > 0" style="margin-top: 8px; margin-left: 20px;"/>
            </div>

            <div v-if="bewohner.deletedAt" class="bewohner-deleted">
              Bewohner gelöscht am {{ moment(bewohner.deletedAt).locale('de').format('DD.MM.YYYY') }}
            </div>
          </v-col>
          <v-col cols="4">
              <span style="float: right">
                 <img :src="require('@/assets/img/printlogo.png')" height="40"/>
              </span>
          </v-col>
        </v-row>
      </section>
      <section>
        <v-row>
          <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
            <v-row class="pt-0 mt-0 mb-0 pb-0">
              <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
                <v-divider light></v-divider>
              </v-col>
            </v-row>
            <div class="pt-0 mt-0 mb-4 pb-0 pdf-item avoid" style="page-break-inside: avoid;">
              <v-row v-if="bewohner.produkte.length > 0">
                <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
                  <v-row>
                    <div style="width: 50px;"></div>
                    <div class="pt-0 mt-0 mb-0 pb-0 justify-start" style="width: 262px;padding-left: 13px;">

                    </div>

                    <div class="pr-4 caption heavy pb-6" :style="'width:'+columnWidths[0]+'px'">
                      MiGeL
                    </div>
                    <div class="text-center pr-4 caption heavy pb-6" :style="'width:'+columnWidths[1]+'px'">
                      Stück
                    </div>
                    <div class="text-right pr-4 caption heavy pb-6" :style="'width:'+columnWidths[2]+'px'">
                      Stückpreis
                    </div>
                    <div class="text-right pr-4 caption heavy pb-6" :style="'width:'+columnWidths[3]+'px'">
                      Total<br>Stückpreis
                    </div>

                    <div class="text-center pl-3 pr-4 caption heavy pb-6" :style="'width:'+columnWidths[4]+'px;padding-left: 20px'">
                      Beutel<br>(aufgerundet)
                    </div>
                    <div class="text-right pr-4  caption heavy pb-6" :style="'width:'+columnWidths[5]+'px'">
                      Total<br>Beutelpreis
                    </div>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col v-if="bewohner.produkte.length > 0" cols="12" class="pt-0 mt-0 mb-0 pb-0">

                  <v-row class="mb-0 pb-0" v-for="(prod,index3) in bewohner.produkte" v-if="prod.stk > 0" :key="'bew-'+index2+'prod-'+index3">
                    <div style="padding-top: 5px; width: 50px;">
                      <img :src="bilder[prod.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                           width="45" height="45" style="object-fit: contain;" v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0]['images'][0]"/>
                    </div>
                    <v-col cols="3" class="print-caption">
                      <div style="position: relative;">
                        <span class="caption">{{ prod.ArtikelNrLAG }} | </span>
                        <span class="caption"> {{ prod.Verkaufseinheit }}</span><br>
                        <div class="caption pt-1">{{ prod.Bezeichnung1 }}

                        </div>
                      </div>

                    </v-col>
                    <div class="pr-4" :style="'padding-top: 11px;width:'+columnWidths[0]+'px'">
                      <span class="caption">
                        {{ prod.MiGeL }}
                      </span>
                    </div>
                    <div class="text-center pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[1]+'px'">
                      {{ prod.stk }}
                    </div>
                    <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[2]+'px'">
                      CHF {{ ((prod.Preis / prod.Inhalt)).toFixed(4) }}
                    </div>
                    <div class="text-right stk pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[3]+'px'">
                      CHF {{ (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100).toFixed(2) }}
                    </div>

                    <div class="text-center beutel pr-4 pl-3 caption" :style="'padding-top: 11px;width:'+columnWidths[4]+'px;padding-left: 20px'">
                      {{ Math.ceil(prod.stk / prod.Inhalt) }}
                    </div>
                    <div class="text-right  beutel pr-4 caption" :style="'padding-top: 11px;width:'+columnWidths[5]+'px'">
                      CHF {{ (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100).toFixed(2) }}
                    </div>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 mt-0 mb-0 pb-0">
                      <v-divider class="pt-0 mt-0 mb-0 pb-0"></v-divider>
                    </v-col>
                  </v-row>
                  <v-row class="py-0 my-0">
                    <div style="width: 50px;"></div>
                    <div style="width: 262px;padding-left: 13px;"></div>
                    <div class="text-left pr-4 caption mt-0 pt-0" :style="'padding-top: 8px;width:'+columnWidths[0]+'px'">
                      <div class="font-weight-bold" style="font-size: 13px;">Total MiGeL</div>
                    </div>
                    <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[1]+'px'">
                      <span class="caption" style="font-size: 13px;">inkl. MwSt.</span>
                    </div>
                    <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[2]+'px'">

                    </div>
                    <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[3]+'px'">
                      <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                        {{ totalMigelStk(bewohner.produkte) }}
                      </div>
                    </div>
                    <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[4]+'px;padding-left: 20px'">

                    </div>
                    <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[5]+'px'">
                      <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                        {{ totalMigelBeutel(bewohner.produkte) }}
                      </div>
                    </div>
                  </v-row>
                  <v-row class="py-0" v-if="totalMigelStk(bewohner.produkte) != totalAllStk(bewohner.produkte)">
                    <div style="width: 50px;"></div>
                    <div style="width: 262px;padding-left: 13px;"></div>
                    <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[0]+'px'">
                      <div class="font-weight-bold" style="font-size: 13px;">Total Gesamt</div>
                    </div>
                    <div class="text-left pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[1]+'px'">
                      <span class="caption" style="font-size: 13px;">inkl. MwSt.</span>
                    </div>
                    <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[2]+'px'">

                    </div>
                    <div class="text-center stk pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[3]+'px'">
                      <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                        {{ totalAllStk(bewohner.produkte) }}
                      </div>
                    </div>
                    <div class="text-center beutel pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[4]+'px;padding-left: 20px'">

                    </div>
                    <div class="text-center beutel  pr-4 caption" :style="'padding-top: 8px;width:'+columnWidths[5]+'px'">
                      <div class="flex-fill text-right font-weight-bold" style="font-size: 13px;">CHF
                        {{ totalAllBeutel(bewohner.produkte) }}
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mb-0 pb-0">
                    <v-col class="mb-0 pb-0">
                      <v-divider class="mb-0 pb-0"></v-divider>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col v-else cols="9" class="pt-6">
                  keine Produkte in diesem Zeitraum verwendet
                </v-col>

              </v-row>
            </div>


          </v-col>
        </v-row>
      </section>
    </section>
  </div>
</template>

<script>
import InkoGradIndicatorPrint from "../InkoGrad/InkoGradIndicatorPrint";


export default {
  name: 'PrintContentKvgBewohner',

  components: {InkoGradIndicatorPrint},

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      columnWidths: [100, 100, 100, 100, 110, 100],
    }
  },

  computed: {
    starDateFormated() {
      return new Date(this.content.dateRange.start).toLocaleDateString() || ''
    },

    endDateFormated() {
      return new Date(this.content.dateRange.end).toLocaleDateString() || ''
    },

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  methods: {
    totalMigelStk(bewProducts) {

      var total = 0;
      bewProducts.forEach(function (prod) {
        if (prod.MiGeL != '' && prod.MiGeL) {
          total += (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100);
        }
      })

      return total.toFixed(2);
    },

    totalAllStk(bewProducts) {
      var total = 0;
      bewProducts.forEach(function (prod) {
        total += (prod.stk * (prod.Preis / prod.Inhalt) * 100 / 100);
      })

      return total.toFixed(2);
    },

    totalMigelBeutel(bewProducts) {
      var total = 0;
      bewProducts.forEach(function (prod) {
        if (prod.MiGeL != '' && prod.MiGeL) {
          total += (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100);
        }
      })

      return total.toFixed(2);
    },

    totalAllBeutel(bewProducts) {
      var total = 0;
      bewProducts.forEach(function (prod) {
        total += (Math.round((Math.ceil(prod.stk / prod.Inhalt) * prod.Preis) * 100) / 100);
      })

      return total.toFixed(2);
    },
  }
}
</script>

<style lang="scss">

#kvgprint-bewohner {

  h1 {
    margin: 0 0 5px;
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  .bewohner-deleted {
    margin-top: 5px;
    font-size: 0.85rem;
    color: #b71c1c;
  }

  .content-text {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }

  .text-right {
    text-align: right;
    justify-content: flex-end;
  }

  .text-center {
    text-align: center;
    justify-content: center;
  }

  .caption.heavy {
    font-family: "Avenir LT Std Heavy";
    font-size: 0.75rem;
    font-weight: 300;
  }

  .caption {
    font-size: 11px;
    font-weight: normal;
  }

  .bezeichnung {
    font-size: 13px;
  }
}


body .v-application {
  .print-caption {
    * {
      font-size: 12px !important;
    }

    .body-2 {
      font-size: 12px !important;
    }
  }
}
</style>