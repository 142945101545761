<template>
  <v-text-field class="search-field" v-model="filter" append-icon="mdi-magnify" hide-details="auto"
                clearable light placeholder="Suche nach Name, Vorname, Zimmer" autocomplete="off" :disabled="!barometersLoaded" @click:clear="filter=''"></v-text-field>
</template>

<script>
import {barometerGetters, barometerMutations} from '@/obsvervables/barometer'

export default {
  name: 'BarometerSearchFilter',

  computed: {
    ...barometerGetters,

    filter: {
      get() {
        return this.filterText
      },
      set(value) {
        barometerMutations.setFilterText(value)
      }
    }
  }
}
</script>

<style scoped>

</style>
