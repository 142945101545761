<template>
  <div>
    <div class="header-overlay">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/planung">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align" style="background-color: white">
                <div>
                  <router-link to="/planung" class="kunden-name" :class="{'small-text': kunde.length > 30}">
                    {{ kunde }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="mt-0 fixed-break-pad text-vertical-align" style="background-color: white">
            <v-text-field class="search-field" color="primary" v-model="searchProdukte" clearable light append-icon="mdi-magnify" hide-details="auto"
                          placeholder="Suche nach Produktname oder ArtikelNr" autocomplete="off"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="sortiment" v-if="produkte">
      <v-app-bar flat dense fixed class="px-4 mx-5 pt-0 mr-3" height="auto" style="top: 116px;background: #fff;height: 200px;">
        <v-container class="pt-0" style="background: #fff">
          <v-row>
            <v-col cols="12">
              <h1>
                Meine Produkte
              </h1>
              Fügen Sie Artikel mittels Drag&Drop ihrer Liste hinzu oder entfernen Sie diese.
              <v-divider light class="mt-8 mb-4"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h3 class="mb-4">
                Meine Produkte
              </h3>
              <v-tabs v-model="purasTab" color="secondary" class="">
                <v-tab class="body-1" @click="typeProdukte = 'Standard'; $vuetify.goTo(0, {})">
                  Hauptartikel (<span v-show="searchProdukte != ''">{{
                    resultCountMeineprodukteStandard
                  }}/</span>{{ totalCountMeineprodukteStandard }})
                </v-tab>
                <v-tab class="body-1" @click="typeProdukte = 'Zusatz';$vuetify.goTo(0, {})">
                  Zusatzartikel (<span v-show="searchProdukte != ''">{{
                    resultCountMeineprodukteZusatz
                  }}/</span>{{ totalCountMeineprodukteZusatz }})
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="6">
              <h3 class="mb-4">
                Puras Sortiment
              </h3>
              <v-tabs v-model="purasTab" color="secondary" class="">
                <v-tab class="body-1" @click="typeProdukte = 'Standard'; $vuetify.goTo(0, {})">
                  Hauptartikel (<span v-show="searchProdukte != ''" left>{{
                    resultCountProdukteStandard
                  }}/</span>{{ totalCountProdukteStandard }})
                </v-tab>
                <v-tab class="body-1" @click="typeProdukte = 'Zusatz'; $vuetify.goTo(0, {})">
                  Zusatzartikel (<span v-show="searchProdukte != ''">{{
                    resultCountProdukteZusatz
                  }}/</span>{{ totalCountProdukteZusatz }})
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-container style="margin-top: 380px;">
        <v-row>
          <v-col cols="6">

            <v-tabs-items v-model="purasTab">
              <v-tab-item>
                <drop :accepts-data="(n) => n.ismyproduct != 1" class="copy proddrop fill-height"
                      @drop="addToMeineProdukte($event)">
                  <SortimentList ismylist="true" @removeFromMeineProdukte="removeFromMeineProdukte($event)"
                                 :meineprodukteIDs="[]" :products="filteredMeineprodukte"></SortimentList>
                </drop>
              </v-tab-item>
              <v-tab-item>
                <drop :accepts-data="(n) => n.ismyproduct != 1" class="copy proddrop fill-height"
                      @drop="addToMeineProdukte($event)">
                  <SortimentList ismylist="true" @removeFromMeineProdukte="removeFromMeineProdukte($event)"
                                 :meineprodukteIDs="[]" :products="filteredMeineprodukte"></SortimentList>
                </drop>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
          <v-col cols="6">

            <v-tabs-items v-model="purasTab">
              <v-tab-item>
                <drop :accepts-data="(n) => n.ismyproduct == 1" class="copy proddrop fill-height"
                      @drop="removeFromMeineProdukte($event)" style="width: 100%">
                  <SortimentList ismylist="false" :meineprodukteIDs="meineprodukteIDs"
                                 :products="filteredProdukte"></SortimentList>
                </drop>
              </v-tab-item>
              <v-tab-item>
                <drop :accepts-data="(n) => n.ismyproduct == 1" class="copy proddrop fill-height"
                      @drop="removeFromMeineProdukte($event)" style="width: 100%">
                  <SortimentList ismylist="false" :meineprodukteIDs="meineprodukteIDs"
                                 :products="filteredProdukte"></SortimentList>
                </drop>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import {Drag, Drop} from "vue-easy-dnd";
import {produkteGetters, produkteActions} from '@/obsvervables/produkte';
import {meineprodukteGetters, meineprodukteActions} from '@/obsvervables/meineprodukte';
import SortimentList from "@/components/app/sortiment/SortimentList";
import SortimentListHeader from "@/components/app/sortiment/SortimentListHeader";

export default {
  name: 'Sortiment',
  components: {
    SortimentListHeader,
    Drag, Drop, SortimentList
  },
  props: ['kunde'],
  data() {
    return {
      purasTab: [],
      searchProdukte: '',
      typeProdukte: 'Standard'
    }
  },
  computed: {
    ...produkteGetters,
    ...meineprodukteGetters,
    filteredProdukte() {

      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }

      if (this.produkte !== undefined) {
        return this.produkte.filter(o => {
          try {
            return (
                (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
                && o.ismyproduct != 1 && this.typeProdukte == o.InkoSortimentTyp
            )
          } catch (e) {
            return false;
          }
        })
      } else {
        return false;
      }
    },
    filteredMeineprodukte() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }

      if (this.meineprodukte !== undefined) {
        return this.meineprodukte.filter(o => {
          try {
            return (
                (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
                && o.ismyproduct == 1 && this.typeProdukte == o.InkoSortimentTyp
            )
          } catch (e) {
            return false;
          }
        })
      } else {
        return false;
      }
    },
    resultCountMeineprodukteStandard() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.meineprodukte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct == 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , resultCountMeineprodukteZusatz() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.meineprodukte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct == 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    }
    , totalCountMeineprodukteStandard() {
      var x = this.meineprodukte.filter(o => {
        return o.ismyproduct == 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , totalCountMeineprodukteZusatz() {
      var x = this.meineprodukte.filter(o => {
        return o.ismyproduct == 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    },
    resultCountProdukteStandard() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.produkte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct != 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , resultCountProdukteZusatz() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.produkte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct != 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    }
    , totalCountProdukteStandard() {
      var x = this.produkte.filter(o => {
        return o.ismyproduct != 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , totalCountProdukteZusatz() {
      var x = this.produkte.filter(o => {
        return o.ismyproduct != 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    }
  },
  methods: {
    ...produkteActions,
    ...meineprodukteActions,

    addToMeineProdukte(e) {
      this.saveMeineProdukte(e.data.ArtikelNrLAG, 'add');
    },

    removeFromMeineProdukte(e) {
      this.saveMeineProdukte(e.data.ArtikelNrLAG, 'remove');
    },
  },
  created() {
    this.fetchProdukte();
    this.fetchMeineprodukte();
  }
}
</script>
<style lang="scss">
#sortiment {
  .list-prod {
    border-bottom: 1px #ccc solid;
  }

  .v-chip {
    margin-top: 3px !important;
    height: 20px !important;
    padding-top: 2px !important;;
  }

  .v-item-group.theme--light.v-tabs-items, .v-window-item.v-window-item--active {
    height: 100%;
  }
}

</style>
