import httpClient from "./httpClient";
import {store} from "../store/index";
import {benutzerMutations} from "@/obsvervables/benutzer";
import {wohneinheitenMutations} from "@/obsvervables/wohneinheiten";
import {kundeMutations} from "@/obsvervables/kunde";
import {bewohnerMutations} from "@/obsvervables/bewohner";
import {bewohnerprodukteMutations} from "@/obsvervables/bewohnerprodukte";
import {meineprodukteMutations} from "@/obsvervables/meineprodukte";
import {produkteMutations} from "@/obsvervables/produkte";

// const REST_ENDPOINT = process.env.VUE_APP_API_URL + 'login'
const REST_ENDPOINT = process.env.VUE_APP_API_BASEURL+'login'
const DATAPROTECTION_ENDPOINT = process.env.VUE_APP_API_BASEURL + 'dataprotection'

const AUTH_TOKEN_KEY = 'authToken'
const AUTH_UID_KEY = 'uid'
const benutzerScope = `benutzer`;

export async function acceptDataProtection(email) {

    return httpClient({
        url: `${DATAPROTECTION_ENDPOINT}`,
        method: 'POST',
        data: {
            email: email
        }
    });
}

export async function loginUser(username, password) {

        try {
            let res = await httpClient({
                url: `${REST_ENDPOINT}`,
                method: 'POST',
                data: {
                    tool: 'inkoplaner',
                    email: username,
                    password: password,
                }
            })

            if (res.type === "success") {
                store.dispatch(`${benutzerScope}/setID`, res.uid);
                store.dispatch(`${benutzerScope}/setAuthToken`, res.token);
                store.dispatch(`${benutzerScope}/setAdressNrADR`, res.info.AdressNrADR);
                store.dispatch(`${benutzerScope}/setAccessRights`, res.info.accessRights);
                store.dispatch(`${benutzerScope}/setErpActive`, res.info.erpActive);
                store.dispatch(`${benutzerScope}/setBewohnerSyncActive`, res.info.bewohnerSyncActive);
                setAuthToken({token: res.token, uid: res.uid});
            }

            return res

        } catch (err) {
            clearAuthToken();
            return false
        }
}

export function logoutUser() {
    wohneinheitenMutations.setWohneinheiten([]);
    kundeMutations.setKunde(false);
    bewohnerprodukteMutations.setBewohnerprodukte([]);
    bewohnerMutations.setbewohner([]);
    meineprodukteMutations.setMeineprodukte([])
    produkteMutations.setProdukte([])

    httpClient({
        url: process.env.VUE_APP_API_URL + 'logout',
        method: 'GET'
    }).finally(() => {
        benutzerMutations.setBenutzer(false);
        sessionStorage.clear()
        clearAuthToken()
    })

}

export function setAuthToken(auth) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`
    httpClient.defaults.headers.common['uid'] = `${auth.uid}`
    sessionStorage.setItem(AUTH_TOKEN_KEY, auth.token)
    sessionStorage.setItem(AUTH_UID_KEY, auth.uid)
}

export function getAuthToken() {
    return sessionStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    httpClient.defaults.headers.common['Authorization'] = ''
    httpClient.defaults.headers.common['uid'] = ''

    sessionStorage.removeItem(AUTH_TOKEN_KEY)
    sessionStorage.removeItem(AUTH_UID_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken /*&& !isTokenExpired(authToken)*/
}

/*
function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}*/