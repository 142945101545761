<template>
  <svg width="100%" height="100%" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.000000 484.000000"
       preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,484.000000) scale(0.100000,-0.100000)"
       fill="currentColor" stroke="none">
      <path d="M2160 4825 c-960 -105 -1766 -770 -2052 -1695 -274 -887 -9 -1865
674 -2488 365 -333 784 -534 1281 -614 188 -30 526 -30 714 0 802 129 1465
614 1812 1327 125 255 190 471 233 770 17 117 16 473 -1 595 -81 588 -354
1110 -783 1498 -505 456 -1201 681 -1878 607z m1145 -2763 l-910 -908 -648
-143 c-356 -79 -650 -141 -652 -139 -3 2 61 296 141 654 l145 649 907 908 907
907 510 -510 510 -510 -910 -908z"/>
      <path d="M2375 2960 l-820 -820 95 -95 c52 -52 99 -95 105 -95 5 0 377 367
825 815 l815 815 -100 100 -100 100 -820 -820z"/>
      <path d="M2677 2652 l-817 -817 100 -100 100 -100 820 820 820 820 -97 97
c-54 54 -100 98 -103 98 -3 0 -373 -368 -823 -818z"/>
      <path d="M2985 2350 l-820 -820 95 -95 c52 -52 99 -95 105 -95 5 0 377 367
825 815 l815 815 -100 100 -100 100 -820 -820z"/>
      <path d="M1391 1534 c-56 -251 -99 -459 -96 -462 5 -5 880 186 902 197 8 4
-121 140 -345 364 l-358 358 -103 -457z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoVM"
}
</script>

<style scoped>
svg {
  color: #9fc76f;
}
</style>