<template>
  <v-navigation-drawer id="prodnav" ref="prodnav"
                       style="margin-right:-20px;min-width: 77px !important;right: 89px !important;border:none;z-index:5"
                       :style="!drawer ? 'border-left:1px #ccc solid !important;' :''" permanent dark height="100%"
                       width="500" fixed z :mini-variant="drawer" :class="!drawer ? 'open': ''" right>
    <v-btn style="height:50px;" id="prodmenubtn" ref="prodmenu" depressed @click.stop="drawer = !drawer"
           class="katalog-btn py-7 heavy" color="primary" v-show="drawer"><b>Meine Produkte</b>
      &nbsp;
    </v-btn>
    <div v-show="!drawer">
      <v-app-bar flat dense fixed class="px-0 mx-0 pt-6 mr-3" style="top: 140px;background: #fff;height: 180px;">
        <v-container class="px-0 mx-0">
          <v-row>
            <v-col cols="9">
              <v-text-field v-model="searchProdukte" clearable light placeholder="Suche" class="search-field" append-icon="mdi-magnify"
                            autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="3">
              <img :src="require('@/assets/img/ico2023/schliessen.svg')" @click.stop="drawer = !drawer" width="24px" height="24px" class="close-btn float-right cursor-pointer mt-1"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0 pl-2 my-0 py-0">
              <v-tabs v-model="prodtab" color="secondary" light>
                <v-tab class="body-1" @click="typeProdukte = 'Standard'">
                  Hauptartikel <span class="pl-2"> (<span v-show="searchProdukte != ''">{{
                    resultCountMeineprodukteStandard
                  }} /</span>{{ totalCountMeineprodukteStandard }})</span>
                </v-tab>
                <v-tab class="body-1" @click="typeProdukte = 'Zusatz'">
                  Zusatzartikel <span class="pl-2"> (<span v-show="searchProdukte != ''">{{
                    resultCountMeineprodukteZusatz
                  }} /</span>{{ totalCountMeineprodukteZusatz }})</span>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-row style="margin-top: 320px;" class="mx-0">
        <v-col class="pl-5">
          <v-tabs-items v-model="prodtab">
            <v-tab-item>
              <SortimentList :meineprodukteIDs="[]" :products="filteredMeineprodukte"></SortimentList>
            </v-tab-item>
            <v-tab-item>
              <SortimentList :meineprodukteIDs="[]" :products="filteredMeineprodukte"></SortimentList>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {meineprodukteGetters, meineprodukteActions, meineprodukteMutations} from '@/obsvervables/meineprodukte';
import {prodtypGetters, prodtypActions, prodtypMutations} from '@/obsvervables/producttabs';
import SortimentList from "@/components/app/sortiment/SortimentList";

export default {
  name: 'ProductNavigation',
  components: {
    SortimentList
  },
  data() {
    return {
      prodNavTab: [],
      drawer: true,
      searchProdukte: ''
    }
  },
  computed: {
    ...meineprodukteGetters,

    prodtab: {
      get() {
        return prodtypGetters.prodtab()
      },
      set(value) {
        prodtypActions.saveProdtab(value)
      }
    },
    typeProdukte: {
      get() {
        return prodtypGetters.typeProdukte()
      },
      set(value) {
        prodtypActions.saveTypeProdukte(value)
      }
    },

    filteredMeineprodukte() {

      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }


      if (this.meineprodukte !== undefined) {
        return this.meineprodukte.filter(o => {
          try {
            return (
                (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
                && this.typeProdukte == o.InkoSortimentTyp
            )
          } catch (e) {
            return false;
          }
        })
      } else {
        return false;
      }
    },
    resultCountMeineprodukteStandard() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.meineprodukte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct == 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , resultCountMeineprodukteZusatz() {
      if (!this.searchProdukte) {
        this.searchProdukte = '';
      }
      var x = this.meineprodukte.filter(o => {
        return (o.ArtikelNrLAG.toString().indexOf(this.searchProdukte) > -1 || o.Bezeichnung1.toLowerCase().indexOf(this.searchProdukte.toLowerCase()) > -1)
            && o.ismyproduct == 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    }
    , totalCountMeineprodukteStandard() {
      var x = this.meineprodukte.filter(o => {
        return o.ismyproduct == 1 && o.InkoSortimentTyp == 'Standard'
      });
      return x && x.length
    }
    , totalCountMeineprodukteZusatz() {
      var x = this.meineprodukte.filter(o => {
        return o.ismyproduct == 1 && o.InkoSortimentTyp == 'Zusatz'
      });
      return x && x.length
    },
  },
  methods: {
    ...meineprodukteMutations,
    ...meineprodukteActions,
    ...prodtypMutations,
    ...prodtypActions

  },
  created() {
    this.fetchMeineprodukte();
    this.saveProdtab(0);
    this.saveTypeProdukte('Standard')
  }
}
</script>
<style lang="scss">
.katalog-btn {
  position: fixed !important;
  top: calc(50vh - 82px + 116px) !important;
  left: -25px;
  z-index: 999 !important;
  transform: rotate(270deg);

  .v-btn__content {
    font-size: 18px;
    font-family: 'Avenir LT Std Heavy';
  }
}


#prodnav {
  background: transparent;

  &.open {
    background: white;
  }
  .myprodico {
    margin-left: 10px;
  }

  .v-chip {
    margin-top: 3px !important;
    height: 20px !important;
    padding-top: 2px !important;;
  }

  .v-item-group.theme--light.v-tabs-items, .v-window-item.v-window-item--active {
    height: 100%;
  }
}
</style>
