import Vue from 'vue'
import httpClient from '@/utils/httpClient'
import moment from 'moment'

const fixierhosenBudgetState = Vue.observable({
  bewohnerId: null,
  fixierhosenBudgetHistory: null,
})

export const fixierhosenBudgetGetters = {
  bewohnerId: () => fixierhosenBudgetState.bewohnerId,

  bewohnerFixierhosenBudgetHistory: () => fixierhosenBudgetState.fixierhosenBudgetHistory,
}

export const fixierhosenBudgetMutations = {
  setBewohnerId (bewohnerId) {
    fixierhosenBudgetState.bewohnerId = bewohnerId
  },

  setBewohnerFixierhosenBudgetHistory (fixierhosenBudgetEntries) {
    fixierhosenBudgetState.fixierhosenBudgetHistory = fixierhosenBudgetEntries
  },

  addBewohnerFixierhosenBudgetEntry (fixierhosenBudgetEntry) {
    fixierhosenBudgetState.fixierhosenBudgetHistory.push(fixierhosenBudgetEntry)

    fixierhosenBudgetState.fixierhosenBudgetHistory.sort(function (a, b) {
      return moment.unix(a.start) - moment.unix(b.start)
    })
  },

  removeBewohnerFixierhosenBudgetEntry (id) {
    let index = fixierhosenBudgetState.fixierhosenBudgetHistory.findIndex(i => i.id === id)

    if (index > -1) {
      fixierhosenBudgetState.fixierhosenBudgetHistory.splice(index, 1)
    }
  },
}

export const fixierhosenBudgetActions = {
  

  loadFixierhosenBudgetHistoryByBewohnerId (bewohnerId) {

    // reset history
    fixierhosenBudgetMutations.setBewohnerFixierhosenBudgetHistory(null)

    fixierhosenBudgetMutations.setBewohnerId(bewohnerId)

    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'fixier-hosen-budget/' + bewohnerId,
        method: 'GET'
      }).then((response) => {
        fixierhosenBudgetMutations.setBewohnerFixierhosenBudgetHistory(response.data)
      })
    })
  },

  addFixierhosenBudgetEntry (entry) {
    return new Promise((resolve, reject) => {

      httpClient({
        url: process.env.VUE_APP_API_URL + 'fixier-hosen-budget/' + fixierhosenBudgetGetters.bewohnerId(),
        method: 'POST',
        data: entry
      }).then((response) => {
        fixierhosenBudgetMutations.addBewohnerFixierhosenBudgetEntry(response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  deleteFixierhosenBudgetEntry (id) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'fixier-hosen-budget/' + fixierhosenBudgetGetters.bewohnerId() + '/' + id,
        method: 'DELETE',
      }).then((response) => {
        fixierhosenBudgetMutations.removeBewohnerFixierhosenBudgetEntry(id)
        resolve(response)
      })
    })
  }
}