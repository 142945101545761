<template>
  <div class="inkograd-print">
    <div v-if="showDrips" class="drips">
      <div v-if="hasInkograd" class="drips">
        <DripUnknownIcon v-if="inkograd===0"/>
        <DripCancledIcon v-if="inkograd===1"/>
        <DripOutlinedIcon v-else-if="inkograd===2" />
        <DripIcon v-else v-for="i in (inkograd - 2)" :key="'drip-'+i"/>
      </div>
      <div v-else>
        <DripCancledIcon />
      </div>
    </div>
    <div class="caption text-no-wrap">
      {{ grade.name }}
    </div>
  </div>
</template>

<script>
import {inkoGradGetters} from "../../../obsvervables/inkograd";
import DripIcon from "./Icons/DripIcon";
import DripOutlinedIcon from "./Icons/DripOutlinedIcon";
import DripCancledIcon from "./Icons/DripCancledIcon";
import DripUnknownIcon from "./Icons/DripUnknownIcon";

export default {
  name: 'InkoGradIndicatorPrint',
  components: {DripUnknownIcon, DripCancledIcon, DripOutlinedIcon, DripIcon},
  props: {
    inkograd: {
      type: Number,
      required: true
    },

    showDrips: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...inkoGradGetters,

    hasInkograd() {
      return this.inkograd > 1
    },

    grade() {
      return this.grades.find(g => g.grade === this.inkograd)
    }
  }
}
</script>

<style scoped lang="scss">
.inkograd-print {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  svg {
    width: 9px !important;
    height: 10px !important;
  }

  .text-no-wrap {
    white-space: nowrap;
  }

  .caption {
    font-size: 12px;
  }
}
</style>