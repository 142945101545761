<template>
  <div class="pdf-item avoid">
    <div v-for="(barometer, i) in barometers" :key="'barometer-'+barometer.bewohner.bewohnerID" style="page-break-after: always">
      <section class="pdf-item" style="max-width: 100%;padding-bottom: 0px; padding-top: 20px;">
        <div style="display: flex; justify-content: space-between">
          <div style="font-size: 14px; color: #000000;">
            {{ kunde.Name }}, {{ currentWohneinheit.name }} - {{ barometer.year }}
          </div>
          <div>
            <img :src="require('@/assets/img/printlogo.png')" height="40"/>
          </div>
        </div>
      </section>
      <section class="pdf-item avoid">
        <BewohnerBarometer :isPrint="true" :barometer="barometer"/>
      </section>
    </div>
  </div>
</template>

<script>
import BewohnerBarometer from "../Barometer/BewohnerBarometer";
import {kundeGetters} from "../../../obsvervables/kunde";
import {wohneinheitenGetters} from "../../../obsvervables/wohneinheiten";

export default {
  name: 'PrintContentBarometer',
  components: {BewohnerBarometer},
  props: {
    barometers: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...kundeGetters,
    ...wohneinheitenGetters,

    currentWohneinheit() {
      return this.wohneinheiten.find(wh => wh.id === this.barometers[0].bewohner.wohneinheitenID)
    }
  }
}
</script>

<style scoped>

</style>