import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {_} from "vue-underscore";


// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const warenkorbState = Vue.observable({
    "openwarenkorbe": [],
    "orderedwarenkorbe": [],
    "upcomingwarenkorbe": [],
});

export const warenkorbGetters = {
    openwarenkorbe: () => warenkorbState.openwarenkorbe,
    orderedwarenkorbe: () => warenkorbState.orderedwarenkorbe,
    upcomingwarenkorbe: () => warenkorbState.upcomingwarenkorbe
}

export const warenkorbMutations = {
    setOpenWarenkorbe(warenkorb) {
        warenkorbState.openwarenkorbe = warenkorb;
    },

    setOrderedWarenkorbe(orderedwarenkorbe) {
        warenkorbState.orderedwarenkorbe = orderedwarenkorbe;
    },

    setUpcomingWarenkorbe(upcomingwarenkorbe) {
        warenkorbState.upcomingwarenkorbe = upcomingwarenkorbe;
    },

}

export const warenkorbActions = {
    fetchOpenWarenkorbe(){
        //MUSS aktuell bleiben -> zb änderung Intervall
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/openwarenkorbe',
            method: 'GET'
        }).then(function (res) {
            warenkorbMutations.setOpenWarenkorbe(res.openwarenkorbe)
        })
    },
    addToOpenWarenkorb(artNr,whid,count,bemerkungen = ''){

        httpClient({
            url: process.env.VUE_APP_API_URL+'save/openwarenkorb',
            method: 'post',
            data: {'artNr': artNr,'whid':whid,'count':count,'bemerkungen': bemerkungen}
        }).then(function (res) {
            //console.log(res)
            if(res.type === 'success'){
                warenkorbMutations.setOpenWarenkorbe(res.openwarenkorbe)
            }
        })
    },
   removeFromOpenWarenkorb(artNr,whid,bemerkungen = ''){

        httpClient({
            url: process.env.VUE_APP_API_URL+'removefrom/openwarenkorb',
            method: 'post',
            data: {'artNr': artNr,'whid':whid,'bemerkungen': bemerkungen}
        }).then(function (res) {
            //console.log(res)
            if(res.type === 'success'){
                warenkorbMutations.setOpenWarenkorbe(res.openwarenkorbe)
            }
        })
    },

    fetchOrderedWarenkorbe(){
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/orderedwarenkorbe',
            method: 'GET'
        }).then(function (res) {
            warenkorbMutations.setOrderedWarenkorbe(res.orderedwarenkorbe)
        })
    },

    fetchUpcomingWarenkorbe(){
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/upcomingwarenkorbe',
            method: 'GET'
        }).then(function (res) {
            warenkorbMutations.setUpcomingWarenkorbe(res.upcomingwarenkorbe)
        })
    },

    async orderOpenWarenkorb(whid,bemerkungen){
        await httpClient({
            url: process.env.VUE_APP_API_URL+'order/openwarenkorb',
            method: 'post',
            data: {'whid':whid,'bemerkungen':bemerkungen}
        })
    },

    async rejectOpenWarenkorb(whid){
        await httpClient({
            url: process.env.VUE_APP_API_URL+'reject/openwarenkorb',
            method: 'post',
            data: {'whid':whid}
        })
    },

    async cancelOpenWarenkorb(whid){
       await httpClient({
            url: process.env.VUE_APP_API_URL+'cancel/openwarenkorb',
            method: 'post',
            data: {'whid':whid}
        })
    }
}

export default {
    warenkorbState,
    warenkorbGetters,
    warenkorbMutations,
    warenkorbActions
}