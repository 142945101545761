<template>
  <div>
    Die letzten 5 Bestellungen nach Lieferdatum
    <v-divider light class="mb-4"></v-divider>
    <v-tabs v-model="bestellTab[whid]"  color="secondary">
      <v-tab class="body-2" v-for="(b,index) in bestellArr" :key="'tab-'+whid+index">
        {{ moment(b.lieferdatum).format('DD.MM.YYYY')  }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="bestellTab[whid]">
      <v-tab-item  v-for="(b,index) in bestellArr" :key="'best-'+whid+index">
        <div v-if="b.status == 'verstrichen'">
          <div class="pt-8 pb-4">
            <v-row>
              <v-col cols="3">
                <v-icon class="pr-4" color="primary">mdi-alert-circle-outline</v-icon> keine Bestellung
              </v-col>
              <v-col cols="3">
                <v-btn @click="showSuperadminResend = true; resendWk = b" color="primary" depressed small v-if="$props.superadmin === 1 && index < 1">
                  <v-icon size="18" color="white" class="pr-2">mdi-alpha-s-circle-outline</v-icon>
                  <span class="heavy" style="padding-top:1px">
                     Superadmin: Bestellung manuell auslösen
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider light></v-divider>
        </div>
        <div v-if="b.status == 'abgelehnt'">
          <div class="pt-8 pb-4">
            <v-row>
              <v-col cols="3">
                <v-icon class="pr-4" color="primary">mdi-alert-circle-outline</v-icon> Bestellung abgelehnt
              </v-col>
              <v-col cols="3">
                <v-btn @click="showSuperadminResend = true; resendWk = b" color="primary" depressed small v-if="$props.superadmin === 1 && index < 1">
                  <v-icon size="18" color="white" class="pr-2">mdi-alpha-s-circle-outline</v-icon>
                  <span class="heavy" style="padding-top:1px">
                     Superadmin: Bestellung manuell auslösen
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider light></v-divider>
        </div>
        <div v-if="b.status == 'versendet'">
          <div class="py-8">
            <v-icon color="primary" class="pr-4">mdi-cart-check</v-icon>
            BestellNr: {{ b.bestellNr }}
          </div>
          <v-divider light></v-divider>
        </div>
        <div>
          <v-row class="planunglegends">
            <v-col cols="4" class="caption stk">
              Bezeichnung
            </v-col>
            <v-col cols="1" class="caption">
              Anzahl
            </v-col>
            <v-col cols="2" class="caption">
            <span class="float-right">
              Preis
            </span>
            </v-col>
          </v-row>
          <v-row  v-for="(prod,index2) in b.warenkorb" :key="'prod-'+whid+'_'+index2+'_'+prod.artNr" >
            <v-col cols="1">
                <span v-if="bilder[prod.artNr] && bilder[prod.artNr][0]['images'][0] ">
               <img alt="" :src="bilder[prod.artNr][0]['images'][0].path+'?width=80'" height="45"/>
              </span>
            </v-col>
            <v-col cols="3" class="stk">
              <product-caption :artnr="prod.artNr" :name="prod.bezeichnung" :einheit="prod.Verkaufseinheit"></product-caption>
            </v-col>
            <v-col cols="1" class="">
              <span v-if="$props.superadmin === 1 && b.status == 'verstrichen' && index < 1">
                  <v-text-field dense light autocomplete="off"  min="0" oninput="validity.valid||(value='');" max="1000" v-model="prod.menge" class="body-2 mt-0 pt-0" type="number"></v-text-field>
              </span>
              <span v-else>
                {{ prod.menge }}
              </span>
            </v-col>
            <v-col cols="2" class="">
            <span class="float-right">
              CHF {{ (prod.menge * prod.stkPreis).toFixed(2) }}
            </span>
            </v-col>
          </v-row>
          <v-row v-for="(prod,index2) in b.zusatzartikel" :key="'prod-'+whid+index2">
            <v-col cols="1">
                <span v-if="bilder[prod.artNr] && bilder[prod.artNr][0]['images'][0] ">
               <img alt="" :src="bilder[prod.artNr][0]['images'][0].path+'?width=80'" height="45"/>
              </span>
            </v-col>
            <v-col cols="3" class="stk">
              <product-caption :artnr="prod.artNr" :name="prod.bezeichnung" :einheit="prod.Verkaufseinheit"></product-caption>
            </v-col>

            <v-col cols="1" class="">
              <span v-if="$props.superadmin === 1 && b.status == 'verstrichen' && index < 1">
                  <v-text-field dense light autocomplete="off"  min="0" oninput="validity.valid||(value='');" max="1000" v-model="prod.menge" class="body-2 mt-0 pt-0" type="number"></v-text-field>
              </span>
              <span v-else>
                {{ prod.menge }}
              </span>
            </v-col>
            <v-col cols="2" class="">
            <span class="float-right">
                  CHF {{ (prod.menge * prod.stkPreis).toFixed(2) }}
            </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-divider light></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <b>TOTAL</b> (exkl. MwSt.)
            </v-col>
            <v-col cols="4">
              <span class="float-right">
                CHF {{ getOrderTotal(b) }}
              </span>
            </v-col>
          </v-row>
          <span v-if="$props.superadmin === 1 && b.status == 'verstrichen' && index < 1">
             Bemerkungen:
              <v-textarea class="body-1 mt-1 opacity-75"
                          rows="2"
                          v-model="b.bemerkungen"
              ></v-textarea>
          </span>
          <span v-else>

            <div v-if="b.bemerkungen && b.bemerkungen != ''">
                <v-divider light></v-divider>
              <v-row>
                <v-col>
                  Bemerkungen: {{ b.bemerkungen }}
                </v-col>
              </v-row>
            </div>
          </span>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="showSuperadminResend" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30" color="primary" class="pr-8">mdi-alpha-s-circle-outline</v-icon>
            </v-col>
            <v-col cols="11" class="pl-4">
              Superadmin: Bestellung auslösen
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          Möchten Sie die verpasste Bestellung jetzt sofort mit dem gleich bleibenden Bestelldatum auslösen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showSuperadminResend = false;">Abbrechen</v-btn>
          <v-btn color="success" depressed @click="superadminResend();showSuperadminResend = false;">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import httpClient from "@/utils/httpClient";
import {bewohnerMutations} from "@/obsvervables/bewohner";

export default {
  name: 'Bestellungen',
  props:['bestellungen','whid','bilder','superadmin'],
  data(){
    return{
      bestellTab: [],
      bestellArr: [],
      showSuperadminResend: false,
      resendWk: false,
    }
  },
  methods:{
    getOrderTotal(b){
      var total = 0;
      var self = this;

      for(var prod in b.warenkorb) {

        total += b.warenkorb[prod].stkPreis * b.warenkorb[prod].menge;
      }
      for(var prod in b.zusatzartikel) {

        total += b.zusatzartikel[prod].stkPreis * b.zusatzartikel[prod].menge;
      }

      return total.toFixed(2);
    },

    async superadminResend(){

      // console.log(this.resendWk)

      const res = await httpClient({
        url: process.env.VUE_APP_API_URL+'resend/cart',
        method: 'post',
        data: { 'cart': this.resendWk}
      })

      if(res.type === 'success'){
        this.bestellArr = res.orderedwarenkorbe;

      }
    }
  },
  created() {
    this.bestellArr = this.$props.bestellungen;
  }
}
</script>