<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h1>
          Wer hat was?
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="pt-2">
        <router-link to="/statistiken">&laquo; zurück zur Übersicht</router-link>
      </v-col>
    </v-row>
    <div  v-if="produkteMitBewohner">

      <v-row v-for="(prod,index) in produkteMitBewohner" :key="'up-'+index">
        <v-col cols="12">
          <v-divider light></v-divider>
        </v-col>
        <v-col cols="1">
          <span v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0]['images'][0] ">
          <img alt="" :src="bilder[prod.ArtikelNrLAG][0]['images'][0].path+'?width=80'" height="45"/>
        </span>
        </v-col>
        <v-col cols="4">
          <product-caption :artnr="prod.ArtikelNrLAG" :name="prod.Bezeichnung1" :einheit="prod.Verkaufseinheit"></product-caption>
        </v-col>
        <v-col cols="7" class="pt-0">
          <v-row v-for="(bew,index2) in prod['Bewohner']" :key="'bew-'+index2">
            <v-col col="3" class="caption">
              {{ bew.vorname }}
            </v-col>
            <v-col col="3" class="caption">
              {{ bew.name }}
            </v-col>
            <v-col col="3" class="caption">
              {{ bew.zimmer }}
            </v-col>
            <v-col col="3" class="caption">
              {{ bew.wh }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

  </v-container>

</template>
<script>
import {werhatwasGetters, werhatwasActions, werhatwasMutations} from '@/obsvervables/statistiken/werhatwas';
export default {
  name: 'Werhatwas',

  computed: {
    ...werhatwasGetters,
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  methods: {
    ...werhatwasActions,
    ...werhatwasMutations,

  },
  created() {
    this.fetchWerhatwas();
  }

}
</script>