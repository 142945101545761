<template>
  <section style="max-width: 100%;padding-bottom: 50px;">

    <div style="page-break-inside: avoid;" :style="bIndex != 0 ? 'page-break-before: always !important;' : ''"
         class="pdf-item" v-for="(b,bIndex) in bewohner" :key="'pcdn__'+b.bewohnerID">
      <v-row>
        <v-col cols="6">
          <h2>
            Tag-Nacht-Karte
          </h2>
        </v-col>
        <v-col cols="6">
        <span style="float: right">
           <img :src="require('@/assets/img/printlogo.png')" height="40"/>
        </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>


      <v-row>
        <v-col cols="4">
          <h2>
            {{ b.vorname }} {{ b.name }}
          </h2>
        </v-col>
        <v-col v-if="wh" cols="3">
          <span class="caption">Wohneinheit</span><br>
          <h3>
            {{ wh.name }}
          </h3>
        </v-col>
        <v-col cols="2">
          <span class="caption">Zimmer</span><br>
          <h3>
            {{ b.zimmer }}
          </h3>
        </v-col>
        <v-col cols="3">
          <span class="caption">
            Inkograd
          </span>
          <InkoGradIndicatorPrint :inkograd="b.inkogradHistory[0].inkograd" :show-drips="true"
                                  v-if="b.inkogradHistory.length > 0"/>
        </v-col>


      </v-row>
      <v-divider></v-divider>

      <v-row v-if="b.note">
        <v-col>
          <b>Bemerkungen:</b> {{ b.note }}
        </v-col>
      </v-row>
      <v-divider v-if="productById(b.netzhose)"></v-divider>
      <v-row v-if="productById(b.netzhose)">
        <v-col cols="2" style="max-width: 80px !important;">
          <div style="padding-top: 9px;"><b>Fixierhose: </b></div>
        </v-col>
        <v-col cols="1" style="max-width: 35px !important;">
          <img alt=""
               style="padding-top: 1px;"
               :src="bilder[productById(b.netzhose)['ArtikelNrLAG']][0]['images'][0].path+'?width=80'"
               width="30" height="30"/>
        </v-col>
        <v-col>
          <div style="padding-top: 9px;">
            {{ productById(b.netzhose)["ArtikelNrLAG"] }} - {{ productById(b.netzhose)["Bezeichnung1"] }}
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-container>
        <v-row class="mt-12 flex-wrap">
          <v-col v-if="day[bIndex] != -1" cols="6" class="pb-0" v-for="(d,index) in day[bIndex]" :key="'day'+index"
                 style="page-break-inside: avoid;">
            <v-card v-if="d.new != 1" light outlined class="pa-4 pb-0 pt-0 mt-0 daynight-card pdf-item avoid"
                    style="break-inside: avoid !important;">
              <v-row>
                <v-col cols="2" class="pt-0 pb-0">
                  <img :src="require('@/assets/img/ico/bedarf-d.svg')" alt="" class="daynighticon mt-2 opacity-50"
                       height="22" max-width="40">
                </v-col>
                <v-col cols="10" class="pt-0 pb-0">
                 <span class="prodname">
                  {{ d.ArtikelNrLAG }}<br>
                  {{ d.Bezeichnung1 }}
                </span>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0">
                <v-col cols="4" class="pt-0 pb-0">
                  <span class="stk">{{ d.stk }}</span><span class="stk-caption">Stk</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                  <div class="flex d-flex">
                    <div class="float-left" style="width:80px;float:left;" v-if="bilder[d.ArtikelNrLAG] && bilder[d.ArtikelNrLAG][0]['images'][1] ">
                      <img alt=""
                           :src="bilder[d.ArtikelNrLAG][0]['images'][1].path+'?width=80'"
                           width="60" height="60"/>
                    </div>
                    <div class="ml-3 float-left" style="width:80px;float:left;" v-if="bilder[d.ArtikelNrLAG] && bilder[d.ArtikelNrLAG][0]['images'][0] ">
                      <img alt=""
                           :src="bilder[d.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                           width="60" height="60"/>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col v-if="night[bIndex] != -1" cols="6" class="pb-0" v-for="(d,index) in night[bIndex]"
                 :key="'night'+index" style="page-break-inside: avoid;">
            <v-card v-if="d.new != 1" light outlined class="pa-4 pb-0 pt-0 mt-0 daynight-card pdf-item avoid"
                    style="page-break-inside: avoid;">
              <v-row>
                <v-col cols="2" class="pt-0 pb-0">
                  <img :src="require('@/assets/img/ico/bedarf-n.svg')" alt="" class="daynighticon mt-2 opacity-50"
                       height="22" max-width="40"/>
                </v-col>
                <v-col cols="10" class="pt-0 pb-0">
                  <span class="prodname">
                    {{ d.ArtikelNrLAG }}<br>
                    {{ d.Bezeichnung1 }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="pt-0 pb-0">
                  <span class="stk">{{ d.stk }}</span><span class="stk-caption">Stk</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                  <div class="flex d-flex">
                    <div class="float-left" style="width:80px;float:left;" v-if="bilder[d.ArtikelNrLAG] && bilder[d.ArtikelNrLAG][0]['images'][1] ">
                      <img alt=""
                           :src="bilder[d.ArtikelNrLAG][0]['images'][1].path+'?width=80'"
                           width="60" height="60"/>
                    </div>
                    <div class="ml-3 float-left" style="width:80px;float:left;" v-if="bilder[d.ArtikelNrLAG] && bilder[d.ArtikelNrLAG][0]['images'][0] ">
                      <img alt=""
                           :src="bilder[d.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                           width="60" height="60"/>
                    </div>
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-divider light></v-divider>
      </v-container>
    </div>
    <!-- <div class="html2pdf__page-break pbbreakafter"></div>-->

  </section>
</template>
<script>

import InkoGradIndicatorPrint from "../InkoGrad/InkoGradIndicatorPrint";

export default {
  components: {InkoGradIndicatorPrint},
  props: ['bewohner', 'day', 'night', 'wh', 'netzhose', 'produkte'],
  data() {
    return {}
  }
  , computed: {
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }

  },
  methods: {
    productById(id) {
      var prod = this.produkte.filter(function (prod) {
        return prod.ArtikelNrLAG == id;
      });

      if (prod.length > 0) {
        return prod[0];
      } else {
        return false;
      }
    },
  }
}

</script>
<style scoped lang="scss">
img.daynighticon {
  margin: 0 auto 7px auto;
  width: 21px;
}

.daynight-card {
  padding: 5px 25px;
  height: 100%;
}

.prodname {
  font-size: 14px;
  display: block;
}

.stk {
  display: inline-block;
  font-size: 32px;
  margin-top: 10px;
}

.stk-caption {
  display: inline-block;
  padding-left: 7px;
  font-size: 13px;
}
</style>
