<template>
  <div id="lieferoptionen" :key="rerender" >
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12">
          <h1>
            Lieferoptionen
          </h1>
          Wählen Sie die Häufigkeit der Bestellung, den gewünschten Tag für die Lieferung und die gewünschten Benachrichtigungen.
          <v-switch
              v-model="optionsUnlocked"
              label="Daten bearbeiten"
              @change="changeOptionsEditLock()"
          ></v-switch>
          <v-divider light class="mt-8 mb-4"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">

          <h2 class="mb-2">
            <v-avatar left class="primary white--text body-2 font-weight-bold" size="32">
              1
            </v-avatar> Account live schalten (Prodsystem)
          </h2>
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-row>
                  <v-col cols="8">
                    Wird der Account "live" geschalten werden:
                    <ul>
                      <li>
                        <b>Die Bestellungen ausgeführt</b>
                      </li>
                      <li>
                        Daten in das richtige Export-Verzeichnis geschrieben / verarbeitet
                      </li>
                      <li>
                        Interne Slack-Benachrichtigungen bei verpassten Bestellungen versendet
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-switch
                        :disabled="optionsUnlocked ? false : true"
                        v-model="kunde.live_env"
                        label="Online (Prodsystem / Bestellungen auf Live-Server ausführen)"
                        @change="changeEnv()"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
          <h2 class="mb-2">
            <v-avatar left class="primary white--text body-2 font-weight-bold" size="32">
              2
            </v-avatar> Lieferungsregelmässigkeit auf Wohneinheiten
          </h2>
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" class="px-8">

                <v-row>
                  <v-col  cols="4" v-for="(wh,index) in wohneinheiten" :key="'id-'+index">
                    <h3>
                      Intervall {{ wh.name }}
                    </h3>
                    <v-radio-group v-if="intervalle[wh.id]" v-model="intervalle[wh.id].wochenintervall" dense :mandatory="false">
                      <v-radio :disabled="optionsUnlocked ? false : true" @change="changeLieferoptionen('wochenintervall',w.val,wh.id)"  v-for="w in wochenintervall"  :key="'wi'+w.key"  :label="w.key" :value="w.val"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card>

          <br><br>
          <h2 class="mb-2">
            <v-avatar left class="primary white--text body-2 font-weight-bold" size="32">
              3
            </v-avatar> Bestellauslösung
          </h2>
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-row>
                  <v-col cols="8">

                    <v-radio-group dense v-model="lieferoptionen.ausloesung" :mandatory="false">
                      <v-radio :disabled="optionsUnlocked ? false : true"  @change="changeLieferoptionen('ausloesung',w.val)" v-for="w in ausloesung"  :key="'as'+w.key" :label="w.key" :value="w.val"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
          <h2 class="mb-2">
            <v-avatar left class="primary white--text body-2 font-weight-bold" size="32">
              4
            </v-avatar> Feiertage und Startdatum
          </h2>
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-row>
                  <v-col cols="4">
                    <h3 class="mt-2 mb-4">
                      Feiertags-Regeln
                    </h3>
                    <v-radio-group dense v-model="lieferoptionen.feiertagsregel"  :mandatory="false">
                      <v-radio :disabled="optionsUnlocked ? false : true" @change="changeLieferoptionen('feiertagsregel',w.val)"  v-for="w in feiertagsregel"  :key="'ft'+w.key" :label="w.key" :value="w.val"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4">
                    <h3 class="mt-2 mb-4">
                      <span v-if="lieferoptionen.startdate">
                      Anpassung Intervall
                      </span>
                      <span v-else>
                      Erste Lieferung
                      </span>
                    </h3>
                    <div v-if="lieferoptionen">
                      <div v-if="calUnlocked || !lieferoptionen.startdate">
                        <v-date-picker :disabled="optionsUnlocked==false" first-day-of-week="1" locale="ch-de" :show-current="true" :show-week="true" @change="changeLieferoptionen('startdate',lieferoptionen.startdate)" v-model="lieferoptionen.startdate" color="primary"></v-date-picker>
                      </div>
                      <div v-else>
                        <b>Erste Lieferung</b>: {{ moment(lieferoptionen.startdate).format('DD.MM.YYYY')  }}<br>
                        <b>Intervall-Liefertag</b>: {{ moment(lieferoptionen.startdate).locale("de").format('dddd')  }}<br>
                        <v-switch
                            :disabled="optionsUnlocked ? false : true"
                            v-model="calUnlocked"
                            label="Intervall bearbeiten"
                            @change="changeCalLock()"
                        ></v-switch>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
          <h2 class="mb-2">
            <v-avatar left class="primary white--text body-2 font-weight-bold" size="32">
              5
            </v-avatar> Benachrichtigungen
          </h2>
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-row>
                  <v-col cols="10" class="notificationchecks"><br>
                    <v-checkbox  color="primary" :disabled="optionsUnlocked ? false : true" v-model="lieferoptionen[w.dbkey]"  @change="changeLieferoptionen(w.dbkey,lieferoptionen[w.dbkey])" dense v-for="w in benachrichtigungen"  :key="'bn'+w.key"  :label="w.key" :value="w.val"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br><br>
    <v-dialog v-model="showOptionsLockPopup" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30"  color="primary" class="pr-8">mdi-lock</v-icon>
            </v-col>
            <v-col cols="11" class="pl-6">
              Achtung!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          Bitte nehmen Sie hier nur Änderungen vor, welche Sie mit Ihrem Inko-Verantwortlichen abgesprochen haben. Änderungen beziehen sich auf ALLE zukünftigen Lieferungen.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" class="pt-1" depressed  @click="showOptionsLockPopup = false; optionsUnlocked = false">Abbrechen</v-btn>
          <v-btn color="primary" class="pt-1" depressed  @click="showOptionsLockPopup = false;">Ok, weiter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCalLockPopup" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30"  color="error" class="pr-8">mdi-lock</v-icon>
            </v-col>
            <v-col cols="11" class="pl-8">
              Achtung!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          Anpassungen am Intervall werden die Bestellplanung beeinflussen. Das Datum sollte NUR in Absprache mit Deinem Inko-Verantwortlichen vorgenommen werden!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" class="pt-1" depressed  @click="showCalLockPopup = false;calUnlocked = false;">Dann lass' ich es</v-btn>
          <v-btn color="primary" class="pt-1" depressed  @click="showCalLockPopup = false; calUnlocked = true;">Ok, weiter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {lieferoptionenGetters,lieferoptionenActions } from '@/obsvervables/lieferoptionen';
import {wohneinheitenGetters,wohneinheitenActions} from '@/obsvervables/wohneinheiten';
import {kundeGetters, kundeActions, kundeMutations} from '@/obsvervables/kunde';

export default {
  name: 'Lieferoptionen',
  data() {
    return {
      //startdate: new Date().toISOString().substr(0, 10),
      wochentag: [{key:'Mo',val:1},{key:'Di',val:2},{key:'Mi',val:3},{key:'Do',val:4},{key:'Fr',val:5}],
      wochenintervall: [{key:'Jede Woche',val:1},{key:'Alle 2 Wochen',val:2},{key:'Alle 3 Wochen',val:3},{key:'Alle 4 Wochen',val:4},{key:'Alle 5 Wochen',val:5},{key:'Alle 6 Wochen',val:6}],
      feiertagsregel: [{key:'Ein Tag vorher',val:'vorher'},{key:'Ein Tag nachher',val:'nachher'}], // {key:'Vereinbarter Tag',val:'vereinbart'}
      ausloesung: [{key:'Bestellung soll jeweils automatisch ausgelöst werden',val:'automatisch'},{key:'Bestellung möchten wir manuell auslösen',val:'manuell'}],
      benachrichtigungen: [{key:'Hinweis für Freigabe Bestellliste',val:1,'dbkey':'hinweis_freigabe'},{key:'Hinweis vor automatischer Bestellung',val:1,'dbkey':'hinweis_autobestell'},{key:'Bestellbestätigung (an hinterlegte Mailadresse)',val:1,'dbkey':'hinweis_bestaetigung'}],
      optionsUnlocked: false,
      showOptionsLockPopup: false,
      showCalLockPopup: false,
      calUnlocked: false,

      //update models wenn error zurückkommt zb
      rerender: 1
    }
  },
  computed:{
    ...lieferoptionenGetters,
    ...wohneinheitenGetters,
    ...kundeGetters,

  },
  methods: {
    ...lieferoptionenActions,
    ...wohneinheitenActions,
    ...kundeActions
    ,changeEnv(){
      this.saveKunde();
    },

    //wenn whid -> dann intervall auf wohneinheit
    async changeLieferoptionen(field,val,whid = false){
        await this.saveLieferoptionen(field,val,whid);
        this.rerender = this.rerender+1;

    },
    changeOptionsEditLock(){
      if(this.optionsUnlocked){
        this.showOptionsLockPopup = true;
      }
    },
    changeCalLock(){
      if(this.calUnlocked){
        this.showCalLockPopup = true;
      }
    }
  },
  created() {
    this.fetchLieferoptionen();
    this.fetchWohneinheiten();
    this.fetchKunde();

  },

}
</script>
<style>
.notificationchecks .v-input--selection-controls{
  margin-top: 0px !important;
  padding-top: 0 !important;
}

</style>
