<template>
  <div id="login">
    <v-container>
      <v-row>
        <v-col cols="5">
          <v-img width="196" height="98" :src="require('@/assets/img/logo-inkopro.svg')" alt="" class="mt-16"></v-img>
          <h1>Willkommen bei Puras Inko Pro</h1>
        </v-col>
      </v-row>
      <v-row v-if="maintenanceInfo && maintenanceInfo.inkopro_show_text" style="margin-top: -50px">
        <v-col cols="4">
          <div class="maintenance-info pt-4 pb-2 px-6">
            <div class="font-weight-bold">
              {{ maintenanceInfo.inkopro_title }}
            </div>
            <div v-html="maintenanceInfo.inkopro_text">

            </div>
          </div>
        </v-col>
      </v-row>
      <template v-if="!maintenanceInfo || !maintenanceInfo.inkopro_maintenance_mode">

        <v-row>
          <v-col cols="5">
            <v-text-field @keyup.enter="login" append-icon="mdi-account" autocomplete="off" clearable label="E-Mail" @keyup="error = false" hide-details="auto" v-model="input.username"
                          class="mr-9"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <div class="d-flex">
              <v-text-field
                  @keyup.enter="login"
                  :type="showPassword ? 'text' : 'password'"
                  append-icon="mdi-key"
                  autocomplete="off"
                  clearable @keyup="error = false"
                  label="Passwort"
                  hide-details="auto"
                  v-model="input.password"
              ></v-text-field>
              <v-btn icon @click="showPassword = !showPassword">
                <v-icon v-if="!showPassword">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-alert outlined dense transition="appear" v-model="error">
              <v-row dense>
                <v-col cols="1">
                  <v-icon size="30" color="error">mdi-alert</v-icon>
                </v-col>
                <v-col cols="10" class="body-1" style="line-height: 30px">
                  <div class="pl-4 block">
                    Falsche Zugangsdaten
                  </div>
                </v-col>
              </v-row>
            </v-alert>
            <v-row>
              <v-col v-if="disableLogin" cols="12">
                <b style="color:#ff0000">ACHTUNG Puras Inko Pro ist heute, 13.09.2021 bis ca. 16:30 Uhr, aufgrund
                  Wartungsarbeiten nicht verfügbar. In dringenden Fällen bitten wir Sie uns zu kontaktieren.</b>
              </v-col>
              <v-col cols="3">

                <v-btn v-if="!disableLogin" depressed color="primary" @click="login">Anmelden</v-btn>
              </v-col>
              <v-col cols="6">
                <v-hover v-slot="{ hover }" open-delay="0">
                  <div v-if="!disableLogin" @click="showForgotPWPopup = true" class="inline-block caption pt-2 cursor-pointer" :class="hover ? 'text-decoration-underline' : ''">
                    Passwort vergessen?
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <!--
    ******************************************************************************************************************************
    ForgotPW Popup
    ******************************************************************************************************************************
    -->
    <v-dialog v-model="showForgotPWPopup" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              Passwort vergessen? Kein Problem!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          Bitte geben Sie Ihre Mailadresse ein, Sie erhalten umgehend per Mail einen Bestätigungslink. Sobald Sie Ihre
          Mailadresse bestätigt haben, wird Ihnen erneut eine Mail-Nachricht mit einem neuen Passwort zugestellt. Aus
          Sicherheitsgründen bitten wir Sie das Passwort schnellst möglich zu ändern.
          <v-divider class="my-4"></v-divider>

          <v-row>
            <v-col sm class="py-0 my-0" cols="10">
              <v-text-field ref="forgotinput" type="email" append-icon="mdi-account" autocomplete="off" clearable label="E-Mail" hide-details="auto" :rules="emailRules"
                            v-model="forgotmail"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showForgotPWPopup = false;">Abbrechen</v-btn>
          <v-btn v-if="$refs.forgotinput" color="primary" :disabled="$refs.forgotinput.hasError" depressed @click="sendResetLink()">
            Passwort-Link zustellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--
  ******************************************************************************************************************************
  DataProtection Popup
  ******************************************************************************************************************************
  -->
    <v-dialog v-model="showWarningPopUp" persistent max-width="500">
      <v-card>
        <v-card-title class="headline pb-0 mb-0">
          <v-row>
            <v-col cols="11">
              {{ warningTitle }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          <v-divider class="mt-2 mb-4"></v-divider>

          <v-row>
            <v-col sm class="py-0 my-0 body-3" cols="10">
              {{ warningText }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showWarningPopUp = false;"> {{ warningDeclineButton }}</v-btn>
          <v-btn color="primary" depressed @click="acceptDataProtection()">
            {{ warningAcceptButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<script>
import { loginUser, acceptDataProtection } from '@/utils/auth'
import httpClient from '@/utils/httpClient'
import { bewohnerprodukteMutations } from '@/obsvervables/bewohnerprodukte'
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      error: false,
      disableLogin: false,
      maintenanceInfo: false,
      forgotmail: '',
      emailRules: [
        v => !!v || 'Bitte geben Sie eine gültige Mailadresse ein',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Bitte geben Sie eine gültige Mailadresse ein',
      ],
      showForgotPWPopup: false,
      showWarningPopUp: false,
      warningTitle: '',
      warningText: '',
      warningAcceptButton: '',
      warningDeclineButton: '',
      showPassword: false,
      input: {
        username: '',
        password: ''
      }
    }
  },
  methods: {

    async acceptDataProtection () {
      let response = await acceptDataProtection(this.input.username)

      if (response.type === 'success') {
        await this.login()
      }
    },

    async login () {

      let response = false
      if (!this.disableLogin) {
        response = await loginUser(this.input.username, this.input.password)
      }

      if (response.type === 'success') {
        this.$router.push('/planung')
        return true
      }

      if (response.type === 'warning') {
        this.warningTitle = response.warning_title
        this.warningText = response.warning_text
        this.warningAcceptButton = response.warning_accept_button
        this.warningDeclineButton = response.warning_decline_button
        this.showWarningPopUp = true
        return false
      }

      this.error = true
      return false
    },

    async sendResetLink () {
      const res = await httpClient({
        url: process.env.VUE_APP_API_BASEURL + 'send/resetlink',
        method: 'post',
        data: { 'mail': this.forgotmail }
      })

      this.showForgotPWPopup = false
    },

    async getMaintenanceStatus () {
      const res = await axios.get(process.env.VUE_APP_PURAS_CONTENT_API + 'globals/maintenance')

      if (res) {
        this.maintenanceInfo = res.data.data
      }
    }

  },
  mounted () {
    if (window.location.href.indexOf('admin') != -1) {
      this.disableLogin = false
    }

    this.getMaintenanceStatus()
  }
}
</script>
<style scoped>
#login {
  height: 100%;
  background-image: url("../assets/img/login-bg-2021.jpg");
  background-size: cover;
}

h1 {
  margin-bottom: 60px;
  margin-top: 50px;
  font-size: 55px;
  color: #000;
  line-height: 60px;
  font-weight: 300;
}

.v-text-field {
  padding-top: 0 !important;
  margin-top: 0;
}

.maintenance-info {
  border-radius: 10px;
  border: 1px #D13840 solid;
}


</style>
