<template>
  <div>
    <v-container id="statistiken" class="pt-0">
      <!--
      ******************************************************************************************************************************
      UPCOMING - STATS
      ******************************************************************************************************************************
      -->
      <v-row>
        <v-col cols="12">
          <h1>
            Statistiken
          </h1>
          <v-row>
            <v-col cols="4" class="stat-box">
              <router-link to="/statistiken/upcoming">
                <v-card flat outlined class="px-4 py-4">
                  <v-card-title>
                    <h2>Upcoming</h2>
                  </v-card-title>
                  <v-card-text class="body-3">
                    Übersicht bevorstehender Bestellungen
                  </v-card-text>
                  <v-card-actions class="pl-4 pb-4">

                    <v-btn depressed color="primary" style="width: 160px !important;">
                      Statistik ansehen
                    </v-btn>

                  </v-card-actions>

                </v-card>
              </router-link>
            </v-col>

            <v-col cols="4" class="stat-box">
              <router-link to="/statistiken/wer-hat-was">
                <v-card flat outlined class="px-4 py-4">
                  <v-card-title>
                    <h2>Wer hat was?</h2>
                  </v-card-title>
                  <v-card-text class="body-3">
                    Welches Produkt ist welchem Bewohner zugewiesen
                  </v-card-text>
                  <v-card-actions class="pl-4 pb-4">

                    <v-btn depressed color="primary" style="width: 160px !important;">
                      Statistik ansehen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </router-link>
            </v-col>
<!--            <v-col cols="4" class="stat-box">-->
<!--              <router-link to="/statistiken/kvg">-->
<!--                <v-card flat outlined class="px-4 py-4">-->
<!--                  <v-card-title>-->
<!--                    <h2>KVG</h2>-->
<!--                  </v-card-title>-->
<!--                  <v-card-text class="body-3">-->
<!--                    Bestelldaten eines bestimmten Zeitraums anzeigen-->
<!--                  </v-card-text>-->
<!--                  <v-card-actions class="pl-4 pb-4">-->
<!--                    <v-btn depressed color="primary" style="width: 160px !important;">-->
<!--                      Statistik ansehen-->
<!--                    </v-btn>-->
<!--                  </v-card-actions>-->
<!--                </v-card>-->
<!--              </router-link>-->
<!--            </v-col>-->

          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Statistiken',
}
</script>
<style lang="scss">
#statistiken {
  .v-card {
    &:hover {
      opacity: 0.7 !important;
    }
  }

  .stat-box * {
    text-decoration: none !important;

    .v-card__text {
      min-height: 60px;
    }
  }
}
</style>
