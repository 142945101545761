import Vue from 'vue'
import httpClient from "@/utils/httpClient";

httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const werhatwasState = Vue.observable({
    "produkteMitBewohner": [],
});

export const  werhatwasGetters = {
    produkteMitBewohner: () =>  werhatwasState.produkteMitBewohner,
}

export const werhatwasMutations = {
    setProdukteMitBewohner(produkteMitBewohner) {
        werhatwasState.produkteMitBewohner = produkteMitBewohner;
    },
}


export const werhatwasActions = {
    fetchWerhatwas(){
        httpClient({
            url: process.env.VUE_APP_API_URL+'get/statistik/wer-hat-was',
            method: 'GET'
        }).then(function (res) {
            werhatwasMutations.setProdukteMitBewohner(res)
        })

    },
}

export default {
    werhatwasState,
    werhatwasGetters,
    werhatwasMutations,
    werhatwasActions
}