<template>
  <div>
    <div v-for="(whPreviews, x) in previews" :key="'whPreviews_'+x" style="page-break-inside: avoid; page-break-after: always;">
      <v-row style="page-break-inside: avoid; margin-bottom: 10px;" class="header">
        <v-col cols="8">
          <div>
            <b>Jahresplan {{ year }}</b>
          </div>
          <div>
            {{ kunde.Name }} / {{ wohneinheiten.find(wh => wh.id === whPreviews[0].wohneinheit).name }}
          </div>
        </v-col>
        <v-col cols="4" style="text-align: right;">
          <img :src="require('@/assets/img/printlogo.png')" height="40"/>
        </v-col>
      </v-row>

      <v-row style="page-break-inside: avoid;" class="order-list order-list-header">
        <v-col cols="3">
          <b>Bestelldatum</b>
        </v-col>
        <v-col cols="3">
          <b>Auslieferung</b>
        </v-col>
        <v-col>
          <b>Bemerkung</b>
        </v-col>
      </v-row>

      <v-row v-for="(preview, y) in whPreviews" :key="'whPreview_'  + x + '_' + y" class="order-list" style="page-break-inside: avoid" :class="{ holiday: preview.isHoliday,  odd: preview.month%2}">
        <v-col cols="3" :class="{ error: preview.order.isHoliday }">
          {{ preview.order.date }}
        </v-col>
        <v-col cols="3" :class="{ error: preview.shipping.isHoliday }">
          {{ preview.shipping.date }}
        </v-col>
        <v-col>
          <span v-if="preview.order.isHoliday || preview.shipping.isHoliday">
            Feiertag
          </span>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

import {kundeGetters} from "../../../obsvervables/kunde";
import {wohneinheitenGetters} from "../../../obsvervables/wohneinheiten";

export default {
  name: 'PrintContentOrderPlanning',

  props: {
    previews: {
      type: Array,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...kundeGetters,
    ...wohneinheitenGetters,
  }
}
</script>

<style scoped lang="scss">
* {
  font-size: 14px;
}

.header * {
  font-size: 18px;
}

.col {
  padding-top: 6px;
  padding-bottom: 6px;

  &.error {
    color: red;
  }
}

.order-list {
  border-top: 1px solid #000000;

  &.order-list-header {
    border-top: none;
  }

  &.odd {
    background-color: #eeeeee;
  }

  .col {
    border-left: 1px solid #000000;

    &:first-child {
      border-left: none;
    }
  }

  &:nth-child(odd) {
    //background-color: #eeeeee;
  }

}
</style>