<template>
  <v-card>
    <v-card-title class="headline">
      Inkograd
    </v-card-title>
    <v-card-text class="body-2">
      <div class="caption mb-4">
        Ab welchem Monat zählt der Inkograd?
      </div>

      <v-divider light class="mb-4"></v-divider>
      <v-date-picker
          v-model="selectedStartDate"
          type="month"
          :show-current="false"
          :allowed-dates="allowedDates"
          locale="ch-de"
      />
      <v-divider light class="mb-4"></v-divider>
      <div class="caption mb-4">
        Welcher Inkograd?
      </div>
      <v-divider light class="mb-4"></v-divider>
      <v-select
          v-model="inkograd.inkograd"
          :items="grades"
          item-text="name"
          item-value="grade"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="danger" depressed @click="done">Abbrechen</v-btn>
      <v-btn color="primary" depressed  @click="create" :disabled="!selectedStartDate">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {inkoGradActions, inkoGradGetters} from "../../../obsvervables/inkograd";

export default {
  name: 'InkoGradEditor',

  data() {
    return {
      selectedStartDate: null,
      inkograd: {
        start: null,
        end: null,
        inkograd: 1
      }
    }
  },

  computed: {
    ...inkoGradGetters,
  },

  methods: {
    allowedDates(val) {
      if(!this.bewohnerInkoGradHistory) {
        return true
      }

      let timestamp = this.moment(val).unix()


      return  !this.bewohnerInkoGradHistory.find(ih => ih.start === timestamp)
    },

    create() {
      this.inkograd.start = this.moment(this.selectedStartDate, 'YYYYY-MM').format()
      this.done()

      inkoGradActions.addInkoGrad(this.inkograd).then(() => {
        this.$emit('edited')
      })

    },

    done() {
      this.$emit('done')
    }
  }
}
</script>