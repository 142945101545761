<template>
  <v-list-item class="list-prod">
    <v-list-item-content>
      <v-row>
        <v-col cols="6">
          <span class="caption">Bezeichnung</span>
        </v-col>
        <v-col cols="5">
          <span style="margin-left: -12px;" class="caption">Verpackungseinheit</span>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'SortimentListHeader'
}
</script>