import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import moment from "moment";
import { benutzerGetters } from "./benutzer";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const barometerState = Vue.observable({
    barometersLoaded: false,
    activeAccordion: false,
    selectedYear: +moment().year(),
    filterText: '',
    barometers: [],
    reloadKey: moment()
});

export const barometerGetters = {
    reloadKey: () => barometerState.reloadKey,

    barometersLoaded: () => barometerState.barometersLoaded,

    selectedYear: () => barometerState.selectedYear,

    barometers: () => barometerState.barometers,

    activeAccordion: () => barometerState.activeAccordion,

    activeWohneinheitBarometers: function(){
        return  barometerState.barometers.filter(b => b.bewohner.wohneinheitenID === benutzerGetters.activeWohneinheit());
    },

    filterText: () => barometerState.filterText
}

export const barometerMutations = {
    setReloadKey(key) {
        barometerState.reloadKey = key
    },

    setBarometersLoaded(status) {
        barometerState.barometersLoaded = status
    },

    setSelectedYear(year) {
        barometerState.selectedYear = year
    },

    setBarometers(barometers) {
        barometerState.barometers = barometers
    },

    setActiveAccordion(panel) {
        barometerState.activeAccordion = panel
    },

    setFilterText(text) {
        barometerState.filterText = text
    }
}

export const barometerActions = {
    loadBarometers() {
        barometerMutations.setBarometersLoaded(false)

        httpClient({
            url: process.env.VUE_APP_API_URL+'barometer',
            method: 'GET'
        }).then(function (response) {
            barometerMutations.setBarometers(response.data)
            barometerMutations.setBarometersLoaded(true)
        })
    },

    loadYearBarometers(year) {
        barometerMutations.setSelectedYear(year)
        barometerMutations.setBarometersLoaded(false)

        httpClient({
            url: process.env.VUE_APP_API_URL+'barometer/' + year,
            method: 'GET'
        }).then(function (response) {
            barometerMutations.setBarometers(response.data)
            barometerMutations.setBarometersLoaded(true)
        })
    }
}

export default {
    barometerActions,
    barometerGetters,
    barometerMutations
}