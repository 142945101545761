<template>
  <div class="product-sortable">
    <div class="product-sortable__index">
      <b>{{ product.index }}</b>
    </div>
    <div class="product-sortable__image">
      <img :alt="product.Bezeichnung1" :src="image.path+'?width=80'"/>
    </div>
    <div class="product-sortable__text">
      <span>{{ product.ArtikelNrLAG }}</span><br>
      <b>{{ product.Bezeichnung1 }}</b>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ProductSortable',

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      images: 'bilder/bilder'
    }),

    image() {
      var image = this.images[this.product.ArtikelNrLAG]

      if (image[0]['images'][0])
        return image[0]['images'][0]

      return null
    }
  }
}
</script>

<style scoped lang="scss">
.product-sortable {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
  border: 1px solid #f38b1c;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }

  .product-sortable__index {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f38b1c;
    color: #ffffff;
  }

  .product-sortable__image {
    display: flex;
    align-items: center;

    img {
      width: 55px;
      height: 100%;
      max-height: 55px;
      object-fit: contain;
    }
  }

  .product-sortable__text {
    padding: 5px;
  }
}
</style>