<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="action-icon">
        <v-hover v-slot="{ hover }" open-delay="0">
          <span v-bind="attrs" v-on="on" class="cursor-pointer text-center align-center">
              <v-img :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')" alt="" max-width="35" class="cursor-pointer"></v-img>
              <div class="block pt-0 caption text-center small">
                 Jahresplanung
              </div>
            </span>
        </v-hover>
      </div>
    </template>

    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col cols="11">
            Jahresplanung drucken
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="body-2 multiprintpopup">
        Wählen Sie bitte das gewünschte Jahr
        <v-divider class="my-4"></v-divider>
        <v-radio-group
            v-model="selectedYear"
            class="mb-4"
        >
          <v-radio
              label="aktuelles Jahr"
              :value="moment().format('y')"
          />
          <v-radio
              label="kommendes Jahr"
              :value="moment().add(1, 'years').format('y')"
          />
        </v-radio-group>

        Wählen Sie bitte die gewünschte Wohneinheit
        <v-divider class="my-4"></v-divider>

        <v-row>
          <v-col class="py-0">
            <v-combobox
                v-model="selectedWohneinheit"
                :items="wohneinheiten"
                item-text="name"
                label="Wohneinheit"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="dialog = false;">Abbrechen</v-btn>
        <v-btn color="primary" depressed @click="print" :loading="printing" :disabled="!selectedWohneinheit">Drucken
        </v-btn>
      </v-card-actions>
    </v-card>

    <vue-html-2-pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="jahresplanung"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="685px"
        ref="printOrderPlanning"
    >
      <section slot="pdf-content" v-if="previews">
        <PrintContentOrderPlanning :previews="previews" :year="selectedYear" />
      </section>
    </vue-html-2-pdf>
  </v-dialog>
</template>

<script>
import {benutzerGetters} from "../../../obsvervables/benutzer";
import {wohneinheitenGetters} from "../../../obsvervables/wohneinheiten";
import PrintContentOrderPlanning from "../print/PrintContentOrderPlanning";
import VueHtml2Pdf from "@/components/app/print/VueHtml2Pdf";
import httpClient from "../../../utils/httpClient";

export default {
  name: 'BestellungPlannningPrintModalDialog',
  components: {PrintContentOrderPlanning, VueHtml2Pdf},
  data() {
    return {
      dialog: false,
      printing: false,
      selectedWohneinheit: null,
      previews: null,
      selectedYear: this.moment().format('y')
    }
  },

  computed: {
    ...benutzerGetters,
    ...wohneinheitenGetters,
  },

  methods: {
    print() {
      this.printing = true

      httpClient({
        url: process.env.VUE_APP_API_URL + 'export/planning/orders',
        method: 'post',
        data: {
          year: this.selectedYear,
          wohneinheiten: [this.selectedWohneinheit.id]
        }
      })
          .then(response => {
            this.previews = response
            this.generatePDF().then(() => this.printing = false)
          })
    },

    async generatePDF() {
      await this.$refs.printOrderPlanning.generatePdf()
    }
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.printOrderPlanning.closePreview()
        this.previews = null
        this.selectedWohneinheit = null
      }
    }
  }
}
</script>

<style scoped>

</style>
