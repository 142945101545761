import { render, staticRenderFns } from "./Bewohnerdaten.vue?vue&type=template&id=b81b1542&scoped=true&"
import script from "./Bewohnerdaten.vue?vue&type=script&lang=js&"
export * from "./Bewohnerdaten.vue?vue&type=script&lang=js&"
import style0 from "./Bewohnerdaten.vue?vue&type=style&index=0&id=b81b1542&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b81b1542",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VForm,VHover,VImg,VRadio,VRadioGroup,VRow,VSelect,VTextField})
