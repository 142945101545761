<template>
  <div>
    <section class="pdf-item" style="max-width: 100%;padding-bottom: 10px;">
      <v-row>
        <v-col cols="8">
          <h2>
            Bestellplanung {{ wh.name }}
          </h2>
        </v-col>
        <v-col cols="4">
          <span style="float: right">
             <img :src="require('@/assets/img/printlogo.png')" height="40"/>
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </section>
    <div id="planungsprint">
      <v-card-text v-if="planungsdaten != 'error'">
        <v-row>
          <v-col cols="12">
            <h4 class="primary--text text-lg text-h2" style="font-weight: 300;font-size: 18px;color:#f38b1c">
              Hauptartikel
            </h4>
          </v-col>
        </v-row>
        <v-row class="planunglegends">
          <v-col cols="4" class="caption stk">
            Bezeichnung
          </v-col>

          <v-col cols="1" class="caption">
            Verbrauch (Stk)
          </v-col>
          <v-col cols="1" class="caption">
            Vorschlag (Beutel)
          </v-col>
          <v-col cols="1" class="caption">
            Rest<br> (Stk)
          </v-col>
          <v-col cols="1" class="caption">
            Angepasst<br> (Anz)
          </v-col>
          <v-col cols="1" class="caption">
            Lager/Zimmerbestand<br>
            (Beutel)
          </v-col>

          <v-col cols="3" class="caption text-right">
            <div class="float-right caption" style="text-align: right">
              Preis
            </div>
          </v-col>
        </v-row>
        <v-row v-for="prod in planungsdaten[wh.id]" :key="'pl-'+prod.ArtikelNrLAG">
          <v-col cols="1">
              <span v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0].images && bilder[prod.ArtikelNrLAG][0].images[0] ">
                 <img :src="bilder[prod.ArtikelNrLAG][0].images[0].path+'?width=80'" width="50" />
              </span>
          </v-col>
          <v-col cols="3">
            <product-caption :artnr="prod.ArtikelNrLAG" :name="prod.Bezeichnung1" :einheit="prod.Verkaufseinheit"></product-caption>

          </v-col>

          <v-col cols="1">
            {{ prod.stkverbrauch }}
          </v-col>
          <v-col cols="1">
            {{ prod.vorschlag }}
          </v-col>
          <v-col cols="1">
              <span
                  :class="(prod.angepasstemenge * prod.inhalt - prod.stkverbrauch) >= 0 ? 'success--text' : 'error--text'">
                  {{ prod.angepasstemenge * prod.inhalt - prod.stkverbrauch }}
              </span>
          </v-col>
          <v-col cols="1">
            {{ prod.angepasstemenge }}
          </v-col>
          <v-col cols="1">
            _______
          </v-col>
          <v-col cols="3" class="text-right">
            <div style="text-align: right" class="float-right">CHF
              <span>{{ (prod.preis * prod.angepasstemenge).toFixed(2) }}</span></div>
          </v-col>
        </v-row>
        <v-divider light></v-divider>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="4">
            <b>Zwischen-Total Hauptartikel</b>
          </v-col>
          <v-col cols="2">
            <div style="text-align: right" class="float-right">
              <b>CHF {{ getTotal(planungsdaten[wh.id]) }}</b>
            </div>
          </v-col>
        </v-row>
        <v-divider light></v-divider>
        <v-row>
          <v-col cols="2">
            <h4 class="primary--text text-lg text-h2" style="font-weight: 300;font-size: 18px;color:#f38b1c">
              Zusatzartikel
            </h4>

          </v-col>
        </v-row>
        <v-divider light></v-divider>
        <span v-if="!openwarenkorbe[wh.id]  || openwarenkorbe[wh.id]['zusatzartikel'].length < 1 ">keine Zusatzartikel vorhanden</span>
        <span v-else>
          <v-row v-for="prod in openwarenkorbe[wh.id]['zusatzartikel']" :key="'wk-'+prod.ArtikelNrLAG">
            <v-col cols="1">
              <span v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0].images && bilder[prod.ArtikelNrLAG][0].images[0] ">
                 <img :src="bilder[prod.ArtikelNrLAG][0].images[0].path+'?width=80'" width="50" />
              </span>
            </v-col>
            <v-col cols="3">
                <product-caption :artnr="prod.ArtikelNrLAG" :name="prod.Bezeichnung1" :einheit="prod.Verkaufseinheit"></product-caption>
            </v-col>
            <v-col cols="1"> </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="1"> </v-col>

            <v-col cols="1">
             <span>
               {{ prod.angepasstemenge }}
             </span>
            </v-col>
             <v-col cols="1">
                _______
              </v-col>

            <v-col cols="3">
               <div style="text-align: right" class="float-right">
                 CHF <span>{{ (prod.preis * prod.angepasstemenge).toFixed(2) }}</span>
               </div>
            </v-col>
          </v-row>
        </span>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="4">
            <b>Zwischen-Total Zusatzartikel</b>
          </v-col>
          <v-col cols="2" v-if="openwarenkorbe[wh.id]">
            <div style="text-align: right" class="float-right">
              <b>CHF {{ getTotal(openwarenkorbe[wh.id]['zusatzartikel']) }}</b>
            </div>
          </v-col>
          <v-col v-else cols="2">
            <b>CHF 0</b>
          </v-col>
        </v-row>
        <v-divider light class="mb-0"></v-divider>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="4">
            <b>TOTAL</b> (exkl. MwSt.)
          </v-col>
          <v-col cols="2" v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['zusatzartikel']">
            <div style="text-align: right" class="float-right">
              <b>CHF {{
                  (parseFloat(getTotal(planungsdaten[wh.id])) + parseFloat(getTotal(openwarenkorbe[wh.id]['zusatzartikel']))).toFixed(2)
                }}</b>
            </div>
          </v-col>
          <v-col v-else cols="2">
            <b>CHF {{ getTotal(planungsdaten[wh.id]) }}</b>
          </v-col>
        </v-row>
        <v-divider light class="mb-2"></v-divider>
        <v-row v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['bemerkungen']">
          <v-col cols="6">
            <h4 class="primary--text text-lg text-h2" style="font-weight: 300;font-size: 18px;color:#f38b1c">
              Bemerkungen
            </h4>

            <br><br>
            <span v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['bemerkungen']">
               {{ openwarenkorbe[wh.id]['bemerkungen'] }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </div>
</template>
<script>
export default {
  props: ['wh','openwarenkorbe','planungsdaten','bilder'],
  data() {
    return {}
  }
  , computed: {},
  methods: {
    getTotal(products) {
      var total = 0;
      for (var prod in products) {
        total += products[prod].preis * products[prod].angepasstemenge;
      }
      return total.toFixed(2);
    },

  }
}
</script>
<style>
#planungsprint * {

  font-size: 11px;

}

#planungsprint .mchip {
  background-color: #f38b1c;
  color: #fff !important;
}

#planungsprint .smallcap {
  font-size: 9px !important;
  font-weight: bold;
}

#planungsprint .caption {
  font-size: 9px;
  font-weight: bold;
}

</style>