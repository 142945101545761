<template>
  <div id="superadmin">
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12">
          <h1>
            Superadmin
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2>
                Ansicht ändern
              </h2>
            </v-card-title>
            <v-card-text style="height: 116px">
              <v-autocomplete
                  v-if="superadminKunden"
                  v-model="choosenSuperAdminKunde"
                  :items="superadminKunden"
                  no-data-text="kein Kunde gefunden"
                  clearable
                  label="Kunden"
                  item-text="Name"
                  item-value="AdressNrADR"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.AdressNrADR }} - {{ data.item.Name }}
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mt-5" color="primary" depressed @click="beAnotherUser()">Kundenansicht wechseln</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2>
                Benutzerübersicht
              </h2>
            </v-card-title>
            <v-card-text style="height: 120px">

            </v-card-text>
            <v-card-actions>
              <router-link :to="{ name: 'user-management' }">
                <v-btn class="mt-5" color="primary" depressed>Benutzerübersicht</v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2>
                Bevorstehende Bestellungen
              </h2>
            </v-card-title>
            <v-card-text style="height: 120px" class="body-3">
              Die anstehenden Bestellungen der nächsten 12 Wochen sortiert nach Lieferdatum.
            </v-card-text>
            <v-card-actions>
              <v-btn class="mt-5" color="primary" depressed @click="showOrderPreview()">Bestellungen anzeigen</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2>
                Produktsortierung
              </h2>
            </v-card-title>
            <v-card-text style="height: 120px">

            </v-card-text>
            <v-card-actions>
              <router-link :to="{ name: 'porductsorting' }">
                <v-btn class="mt-5" color="primary" depressed>bearbeiten</v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!--<v-img  max-width="200"></v-img>-->
        </v-col>
      </v-row>
    </v-container>
    <OrderPreview :show-modal="orderPreivewModal" v-on:closeOrderPreview="closeOrderPreview()"></OrderPreview>
  </div>
</template>
<script>

import {kundeGetters, kundeActions, kundeMutations} from '@/obsvervables/kunde';
import OrderPreview from "@/components/app/superadmin/OrderPreview";

export default {
  name: 'Superadmin',
  components: {OrderPreview},
  data() {
    return {
      orderPreivewModal: false,
      choosenSuperAdminKunde: false
    }
  },
  computed: {
    ...kundeGetters,

  },

  methods: {
    ...kundeActions,

    beAnotherUser() {
      if (this.choosenSuperAdminKunde) {
        this.setNewUserPerspective(this.choosenSuperAdminKunde);
      }
    },

    showOrderPreview() {
      this.orderPreivewModal = true;
    },

    closeOrderPreview() {
      this.orderPreivewModal = false;
    }
  },
  async created() {
    await this.fetchSuperAdminKunden();

    this.choosenSuperAdminKunde = this.kunde.AdressNrADR
  }

}
</script>

<style scoped>
h2 {
  color: #f38b1c;
}
</style>
