<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }" v-if="!manualMode">
      <v-btn v-bind="attrs" v-on="on" text icon :small="!smallIcon" :x-small="smallIcon" rounded class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="0">
          <v-img v-if="hover" class="mt-0 cursor-pointer"
                 :src="require('@/assets/img/ico2023/bearbeiten-aktiv.svg')" alt=""
                 max-width="30"></v-img>
          <v-img v-else class="mt-0 cursor-pointer"
                 :src="require('@/assets/img/ico2023/bearbeiten.svg')" alt="" max-width="30"></v-img>

        </v-hover>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Inkograd
      </v-card-title>
      <v-card-text class="body-2">
        <InkoGradHistory :editable="editable" @edited="edited=true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="close">Schliessen</v-btn>
        <InkoGradEditorModalDialog :bewohner_id="bewohnerId" @edited="edited=true" class="ml-2"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {inkoGradActions} from "../../../obsvervables/inkograd";
import InkoGradHistory from "./InkoGradHistory";
import InkoGradEditorModalDialog from "./InkoGradEditorModalDialog";

export default {
  name: 'InkoGradHistoryModalDialog',
  components: {InkoGradEditorModalDialog, InkoGradHistory},

  props: {
    bewohnerId: {
      type: Number,
      required: true
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    },

    editable: {
      type: Boolean,
      required: false,
      default: false
    },

    manualMode: {
      type: Boolean,
      required: false,
      default: false
    },

    reload: {
      type: Boolean,
      required: false,
      default: false
    },

    smallIcon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: false,
      edited: false
    }
  },

  methods: {
    close() {
      this.dialog = false

      if (this.reload && this.edited) {
        this.$emit('reload')
      }
    }
  },

  mounted() {
    this.dialog = this.show
  },

  watch: {
    dialog() {
      if (this.dialog) {
        inkoGradActions.loadInkoGradHistoryByBewohnerId(this.bewohnerId)
      }
    }
  }
}
</script>

<style scoped>

</style>
