export const bilder = {
  namespaced: true,
  state: {
    produktbilder: []
  },

  getters: {
    bilder(state) {
      return state.produktbilder
    }
  },

  actions: {
    setProduktBilder({ commit },value) {
      commit("setProduktBilder",value);
    }
  },
  mutations: {

    setProduktBilder(state,val){
      state.produktbilder = val;
    }
  }
};