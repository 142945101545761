import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {kundeMutations} from "@/obsvervables/kunde";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;



const benutzerState = Vue.observable({
    "benutzer": false,
    "clientUsers": [],
    accessOptions: null,
    activeAccessOptions: null,
    activeWohneinheit: null,
});

export const benutzerGetters = {
    benutzer: () => benutzerState.benutzer,
    activeWohneinheit: () => benutzerState.activeWohneinheit,
}

export const benutzerMutations = {
    setBenutzer(benutzer) {
        benutzerState.benutzer = benutzer;
    },

    setActiveWohneinheit(wh){
        benutzerState.activeWohneinheit = +wh
    },
}

export const tooladminGetters = {
    clientUsers: () => benutzerState.clientUsers.filter(user => user.superadmin != 1),
    accessOptions: () => benutzerState.accessOptions,
    activeAccessOptions: () => benutzerState.activeAccessOptions
}

export const tooladminMutations = {
    setToolAdmin(tooladmin) {
        benutzerState.clientUsers = tooladmin;
    },
    setAccessOptions(accessOptions) {
        benutzerState.accessOptions = accessOptions;
    },
    setActiveAccessOptions(activeAccessOptions) {
        benutzerState.activeAccessOptions = activeAccessOptions;
    },

    addClientUser(user) {
        benutzerState.clientUsers.push(user)
    }
}


export const benutzerActions = {
    fetchBenutzer(){
        if(!benutzerState.benutzer){
            benutzerMutations.setBenutzer(true)

            httpClient({

                url: process.env.VUE_APP_API_URL+'get/benutzer',
                method: 'GET'
            }).then(function (res) {
                benutzerMutations.setBenutzer(res)
                benutzerMutations.setActiveWohneinheit(res.default_wh)
            })
       }
    },


    async fetchToolAdminBenutzer(){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+'tooladmin/get/benutzer',
            method: 'GET'
        })

        if(res.type === 'success'){
            tooladminMutations.setToolAdmin(res.users)
            tooladminMutations.setAccessOptions(res.accessOptions)
            tooladminMutations.setActiveAccessOptions(res.activeAccessOptions)
        }
    },



    saveNewPassword(pw1,pw2){
        httpClient({
            url: process.env.VUE_APP_API_URL+'save/benutzer-pw',
            method: 'post',
            data: { 'pw1': pw1,'pw2': pw2,'benutzer': benutzerState.benutzer.id }
        }).then(function (res) {

        })
    },

    saveDefaultWh(id){
        return httpClient({
            url: process.env.VUE_APP_API_URL+'save/benutzer-default-wh',
            method: 'post',
            data: { 'id': id }
        }).then(function (res) {
            benutzerMutations.setActiveWohneinheit(id)
        })
    }
}

export default {
    benutzerState,
    tooladminGetters,
    benutzerGetters,
    benutzerMutations,
    tooladminMutations,
    benutzerActions
}
