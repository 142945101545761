<template>
  <div v-if="benutzer.superadmin === 1">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Benutzerübersicht
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
              clearable
              style="max-width: 500px"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
              v-model="filter"
              row
          >
            <v-radio
                label="alle"
                value="all"
            />

            <v-radio
                label="mind. einmal eingeloggt"
                value="loggedInOnce"
            />

            <v-radio
                label="nie eingeloggt"
                value="neverLoggedIn"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              v-if="this.users"
              :loading="loading"
              :headers="headers"
              :items="filteredUsers"
              :search="search"
              :options="options"
              sort-by="kunde"
          >
            <template v-slot:item.tool_access="{ item }">
              <div class="d-flex flex-row" style="gap: 6px;">
                <template v-for="(translation, key) in accessOptions">
                  <template v-if="key != 'tool:shared'">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <LogoVM v-if="key == 'tool:checklist'" class="access-option" :class="{ inactive: !item.tool_access.includes(key)}"/>
                          <LogoIP v-else-if="key == 'tool:inkoplaner'" class="access-option" :class="{ inactive: !item.tool_access.includes(key)}"/>
                          <LogoWunde v-else-if="key == 'tool:wunde'" class="access-option" :class="{ inactive: !item.tool_access.includes(key)}"/>
                        </span>
                      </template>
                      <span>{{ translation }}</span>
                    </v-tooltip>
                    <div style="color: #cccccc; width: 120px;" class="d-flex align-center">
                    <span v-if="item.last_login[key] !== undefined">
                      {{ item.last_login[key] }}
                    </span>
                      <span v-else>
                      kein Login
                    </span>
                    </div>
                  </template>
                </template>
              </div>
            </template>

            <template v-slot:no-data>
              keine Benutzer gefunden
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import httpClient from "../../utils/httpClient";
import {benutzerGetters} from "../../obsvervables/benutzer";
import LogoVM from "@/components/svg/LogoVM";
import LogoIP from "@/components/svg/LogoIP";
import LogoWunde from "@/components/svg/LogoWunde";

export default {
  name: 'UserManagement',
  components: {LogoWunde, LogoIP, LogoVM},
  data() {
    return {
      options: {
        itemsPerPage: 50,
      },
      headers: [
        {
          text: 'Vorname',
          value: 'vorname'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'E-mail',
          value: 'email'
        },
        {
          text: 'Kunde',
          value: 'kunde'
        },
        {
          text: 'AdressNr',
          value: 'AdressNrADR'
        },
        {
          text: 'Tool',
          value: 'tool_access',
          sortable: false,
        }
      ],
      loading: true,
      search: '',
      filter: 'all',
      users: null,
      accessOptions: [],
      activeAccessOptions: []
    }
  },

  computed: {
    ...benutzerGetters,

    filteredUsers() {
      if (this.filter === 'loggedInOnce') {
        return this.users.filter(u => Object.keys(u.last_login).length > 0)
      } else if (this.filter === 'neverLoggedIn') {
        return this.users.filter(u => Object.keys(u.last_login).length == 0)
      }

      return this.users
    }
  },

  mounted() {

    httpClient.get(process.env.VUE_APP_API_URL + 'superadmin/users')
        .then(response => {
          this.accessOptions = response.accessOptions
          this.activeAccessOptions = response.activeAccessOptions
          this.users = response.users
          this.loading = false
        })
  }
}
</script>

<style lang="scss" scoped>

.access-option {
  width: 24px;
  height: 24px;

  &.inactive {
    color: #cccccc;
  }
}
</style>