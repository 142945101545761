<template>
  <div class="barometer-year-select">
    <v-btn text icon class="mr-4" v-if="previousYear" @click="loadPreviousYear" :disabled="!barometersLoaded" height="32">
      <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697" viewBox="0 0 9.254 15.697">
        <path id="Pfad_1215" data-name="Pfad 1215" d="M139.074,569.512l7.133,7.151-7.133,7.133" transform="translate(147.62 584.502) rotate(180)" fill="none" stroke="#241911" stroke-miterlimit="10" stroke-width="1"/>
      </svg>
    </v-btn>
    <div class="text-h5 font-weight-light">
      {{ selectedYear }}
    </div>
    <v-btn text icon class="ml-4" v-if="nextYear" @click="loadNextYear" :disabled="!barometersLoaded" height="32">
      <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697" viewBox="0 0 9.254 15.697">
        <path id="Pfad_1215" data-name="Pfad 1215" d="M0,14.283,7.133,7.133,0,0" transform="translate(0.708 0.707)" fill="none" stroke="#241911" stroke-miterlimit="10" stroke-width="1"/>
      </svg>
    </v-btn>
  </div>
</template>

<script>
import {barometerActions, barometerGetters} from '@/obsvervables/barometer'

export default {
  name: 'BarometerYearSelect',

  computed: {
    ...barometerGetters,

    thisYear() {
      return +this.moment().year()
    },

    nextYear() {
      return this.selectedYear === this.thisYear+1? null: this.selectedYear+1
    },

    previousYear() {
      return this.selectedYear === 2022? null: this.selectedYear-1
    }
  },

  methods: {
    loadPreviousYear() {
      barometerActions.loadYearBarometers(this.previousYear)
    },

    loadNextYear() {
      barometerActions.loadYearBarometers(this.nextYear)
    }
  }
}
</script>

<style lang="scss" scoped>
  .barometer-year-select {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
</style>
