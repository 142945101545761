<template>
  <v-row :style="isPrint? 'padding: 0;': ''">
    <v-col cols="3" :xl="2" class="d-none d-xl-block" v-if="!isPrint">
      <v-card color="secondary" light outlined style="height: 100%">
        <v-list-item>
          <v-list-item-content class="body-2">
            <v-row>
              <v-col>
                <div class="pt-1 subtitle-1 heavy" style="font-size: 18px !important;font-weight: normal !important;">
                  {{ bewohner.vorname }} {{ bewohner.name }}
                </div>
                <div class="pt-0 pb-4 d-flex flex-row align-center justify-space-between">
                  {{ bewohner.zimmer }}
                  <BarometerPlanungDialog :bewohner="bewohner"/>
                </div>

                <v-divider light/>

                <div class="inkograde-title mt-8">
                  <span class="heavy">Inkograd</span>
                  <InkoGradHistoryModalDialog :bewohner-id="bewohner.bewohnerID" :editable="true" :reload="true" @reload="reload"/>
                </div>
                <InkoGradList :year="selectedYear" :inkograd-history="barometer.bewohner.inkogradHistory"/>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" :xl="isPrint? 12: 10">
      <v-row class="d-block d-xl-none">
        <v-col class="bewohner-info">
          <div>
            <span class="subtitle-1 heavy" style="font-size: 18px !important;font-weight: normal !important;">
              {{ bewohner.vorname }} {{ bewohner.name }}
            </span>
            <span class="mr-2">
              | {{ bewohner.zimmer }}
            </span>
            <BarometerPlanungDialog :bewohner="bewohner" v-if="!isPrint" />
          </div>

          <div class="inkograde-header" v-if="!isPrint">
            <InkoGradInfo :year="selectedYear" :inkograd-history="barometer.bewohner.inkogradHistory"/>
            <InkoGradHistoryModalDialog :bewohner-id="bewohner.bewohnerID" :editable="true" :reload="true" @reload="reload" :smallIcon="true"/>
          </div>
        </v-col>
      </v-row>
      <BarometerProductList
        :year="selectedYear"
        :products="barometer.products"
        :inkogradHistory="barometer.bewohner.inkogradHistory"
        :kostengutspracheHistory="barometer.bewohner.kostengutspracheHistroy"
        :isPrint="isPrint"
      />
      <v-divider light class="mt-10" v-if="!isPrint"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import BarometerProductList from "./BarometerProductList";
import InkoGradInfo from "../InkoGrad/InkoGradInfo";
import InkoGradList from "../InkoGrad/InkoGradList";
import InkoGradHistoryModalDialog from "../InkoGrad/InkoGradHistoryModalDialog";
import {barometerGetters, barometerMutations} from "../../../obsvervables/barometer";
import BarometerPlanungDialog from "./BarometerPlanungModalDialog";

export default {
  name: 'BewohnerBarometer',
  components: {BarometerPlanungDialog, InkoGradHistoryModalDialog, InkoGradList, InkoGradInfo, BarometerProductList},
  props: {
    barometer: {
      type: Object,
      required: true
    },
    isPrint: {
      type:Boolean,
      default: false
    }
  },

  computed: {
    ...barometerGetters,
    bewohner() {
      return this.barometer.bewohner
    }
  },

  methods: {
    reload() {
      barometerMutations.setReloadKey(this.moment())
    }
  }
}
</script>

<style lang="scss" scoped>
.bewohner-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inkograde-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inkograde-header {
  display: flex;
  align-items: center;
}
</style>
