import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const bewohnerDetailsState = Vue.observable({
    planingData: null
})

export const bewohnerDetailsGetters = {
    planingData: () => bewohnerDetailsState.planingData
}

export const bewohnerDetailsMutations = {
    setPlaningData(data) {
        bewohnerDetailsState.planingData = data
    }
}

export const bewohnerDetailsActions = {
    loadPlaningData(id) {
        httpClient({
            url: process.env.VUE_APP_API_URL+'bewohner/' + id + '/planung',
            method: 'GET',
        }).then((response) => {
            bewohnerDetailsMutations.setPlaningData(response.data)
        })
    }
}