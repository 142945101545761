import Vue from 'vue'
import httpClient from "@/utils/httpClient";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const meineprodukteState = Vue.observable({
    "meineprodukte": [],
    "meineprodukteIDs": []
});

export const meineprodukteGetters = {
    meineprodukte: () => meineprodukteState.meineprodukte,
    meineprodukteIDs: () => meineprodukteState.meineprodukteIDs
}

export const meineprodukteMutations = {
    setMeineprodukte(meineprodukte) {
        meineprodukteState.meineprodukte = meineprodukte;
    },
    setMeineProdukteIDs(meineprodukte){

        meineprodukteState.meineprodukteIDs = meineprodukte.map(function(el){
            return el.ArtikelNrLAG
        });
    }
}

export const meineprodukteActions = {
    fetchMeineprodukte(){
        if(meineprodukteState.meineprodukte.length < 1){
            httpClient({
                url: process.env.VUE_APP_API_URL+'get/meineprodukte',
                method: 'GET'
            }).then(function (res) {
                meineprodukteMutations.setMeineprodukte(res.meineprodukte)
                meineprodukteMutations.setMeineProdukteIDs(res.meineprodukte)
            })
        }
    },

    saveMeineProdukte(prodid,changeType){
        httpClient({
            url: process.env.VUE_APP_API_URL+'save/meineprodukte',
            method: 'post',
            data: { 'pid': prodid,'changetype': changeType}
        }).then(function (res) {
            if(res.type === 'success'){
                meineprodukteMutations.setMeineprodukte(res.meineprodukte)
                meineprodukteMutations.setMeineProdukteIDs(res.meineprodukte)

            }
        })
    }
}

export default {
    meineprodukteState,
    meineprodukteGetters,
    meineprodukteMutations,
    meineprodukteActions
}