<template>
  <div class="vue-html2pdf">
    <section
        class="layout-container"
        :class="{
				'show-layout' : showLayout,
				'unset-all' : !floatLayout
			}"
    >
      <section
          class="content-wrapper"
          :style="`width: ${pdfContentWidth};`"
          ref="pdfContent"
      >

        <slot name="pdf-content"/>
      </section>
    </section>

    <transition name="transition-anim">
      <section class="pdf-preview" v-if="pdfFile">
        <button @click.self="closePreview()">
          &times;
        </button>

        <iframe
            :src="pdfFile"
            width="100%"
            height="100%"
        />
      </section>
    </transition>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';

export default {
  props: {
    showLayout: {
      type: Boolean,
      default: false
    },

    floatLayout: {
      type: Boolean,
      default: false
    },

    enableDownload: {
      type: Boolean,
      default: true
    },

    previewModal: {
      type: Boolean,
      default: false
    },

    paginateElementsByHeight: {
      type: Number
    },

    filename: {
      type: String,
      default: `${new Date().getTime()}`
    },

    pdfQuality: {
      type: Number,
      default: 2,
    },

    pdfFormat: {
      default: 'a4',
    },

    pdfOrientation: {
      type: String,
      default: 'portrait'
    },

    pdfContentWidth: {
      default: '800px'
    },

    htmlToPdfOptions: {
      type: Object
    },

    manualPagination: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hasAlreadyParsed: false,
      progress: 0,
      pdfWindow: null,
      pdfFile: null
    }
  },

  watch: {
    progress (val) {
      this.$emit('progress', val)
    },

    paginateElementsByHeight () {
      this.resetPagination()
    },

    $props: {
      handler () {
        this.validateProps()
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    validateProps () {
      // If manual-pagination is false, paginate-elements-by-height props is required
      if (!this.manualPagination) {
        if (this.paginateElementsByHeight === undefined) {
          console.error('Error: paginate-elements-by-height is required if manual-pagination is false')
        }
      }
    },

    resetPagination () {
      const parentElement = this.$refs.pdfContent.firstChild
      const pageBreaks = parentElement.getElementsByClassName('html2pdf__page-break')
      const pageBreakLength = pageBreaks.length - 1

      if (pageBreakLength === -1) return

      this.hasAlreadyParsed = false

      // Remove All Page Break (For Pagination)
      for (let x = pageBreakLength; x >= 0; x--) {
        pageBreaks[x].parentNode.removeChild(pageBreaks[x])
      }
    },

    generatePdf () {
      this.$emit('startPagination')
      this.progress = 0
      this.$store.commit('loader/show');


      this.showPDF()
    },

    async showPDF () {

      let options = this.setOptions()
      const pdfContent = this.$refs.pdfContent
      var html2PdfSetup = html2pdf().set(options).from(pdfContent)

      await html2PdfSetup.toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(8)
          pdf.setTextColor('#cccccc')
          var text =  "Puras AG - Telefon 031 996 85 85 - contact@puras.ch"

          pdf.text(text, (0.3), (pdf.internal.pageSize.getHeight() - 0.3))
          pdf.text('Seite ' + i + ' von ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      })
      let pdfBlobUrl = null
      if (this.previewModal) {
        /*console.log( this.setOptions())
          console.log(this.$refs.pdfContent)
          const worker = html2pdf().set(this.setOptions()).from(this.$refs.pdfContent).toPdf().get("pdf").then(pdf => {
            const e = pdf.internal.collections.addImage_images;
            for (let i in e) {
              e[i].h <= 133 ? pdf.deletePage(+i + 1) : null;
            }
          });

          this.pdfFile =  await worker.output('blob');
  */


        this.pdfFile = await html2PdfSetup.output('bloburl')
        pdfBlobUrl = this.pdfFile
      }
      if (this.enableDownload) {

        pdfBlobUrl = await html2PdfSetup.save().output('bloburl')
      }
      if (pdfBlobUrl) {
        //const res = await fetch(pdfBlobUrl)
        //const blobFile = await res.blob()
        //this.$emit('hasDownloaded', blobFile)
      }
      //this.progress = 100

      this.$store.commit('loader/hide');

      //this.downloadPdf()
    },

    setOptions () {
      if (this.htmlToPdfOptions !== undefined && this.htmlToPdfOptions !== null) {
        return this.htmlToPdfOptions
      }

      return {
        margin: 0.5,
        filename: `${this.filename}.pdf`,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        dpi: 96,

        enableLinks: false,

        html2canvas: {
          scale: this.pdfQuality,
          dpi: 96,
          allowTaint: true,
          useCORS: true,
          scrollX: 0, scrollY: 0,
          imageTimeout: 0,
         // height: 20000 wenn mit IMAGES gibts probleme bei den Seiten und er schneidet tw. ab -> sollten die images dennoch gewünscht werden könnte man hier die
          //content höhe (da aufbau fast identisch ist) per JS herauslesen und diese höhe berechnen lassen

        },
        pagebreak: { mode: [ 'css'] },

        jsPDF: {
          unit: 'in',
          dpi: 96,
          format: this.pdfFormat,
          orientation: this.pdfOrientation,
          scrollX: 0, scrollY: 0,
          pagebreak: { mode: [ 'css'] }

        }
      }
    },

    closePreview () {
      this.pdfFile = null
    }
  }
}
</script>

<style lang="scss">
.vue-html2pdf {
  .layout-container {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    top: 0 !important;
    z-index: -9999;
    background: rgba(95, 95, 95, 0.8);
    display: block !important;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden !important;


    &.show-layout {
      left: 0vw;
      z-index: 9999;
    }

    &.unset-all {
      all: unset;
      width: auto;
      height: auto;
    }
  }

  .pdf-preview {
    position: fixed;
    width: 65%;
    min-width: 600px;
    height: 80vh;
    top: 100px;
    z-index: 9999999;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000048;

    button {
      position: absolute;
      top: -20px;
      left: -15px;
      width: 35px;
      height: 35px;
      background: #555;
      border: 0;
      box-shadow: 0px 0px 10px #00000048;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
      outline: none !important;
    }

    iframe {
      border: 0;
    }
  }

  .transition-anim-enter-active, .transition-anim-leave-active {
    transition: opacity 0.3s ease-in;
  }

  .transition-anim-enter, .transition-anim-leave-to{
    opacity: 0;
  }
}
</style>