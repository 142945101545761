<template>
  <div v-if="benutzer && benutzer.superadmin === 1">
    <div style="position: fixed;width: 100%; top: -5px; z-index: 10; left: 0px;">
      <v-container>
        <v-row>
          <v-col cols="1" class="pt-5 mt-0 fixed-break-pad" style="background-color: white">
            <router-link to="/planung">
              <v-img class="mt-1" :src="require('@/assets/img/ico/planung.png')" alt="" width="25"></v-img>
            </router-link>
          </v-col>
          <v-col cols="4" class="pt-2 mt-0 fixed-break-pad" style="background-color: white">

          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>

      <v-row>
        <v-col cols="10">
          <h1>
            Produktsortierung
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col col="3">
          <v-combobox
              v-model="filter.brand"
              label="Marke"
              :items="brands"
          ></v-combobox>
        </v-col>
        <v-col col="3">
          <v-combobox
              v-model="filter.type"
              label="Sortiment"
              :items="types"
              item-text="name"
              item-value="key"
          ></v-combobox>
        </v-col>
        <v-col></v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider/>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>Produkte sortiert</h2>
          <v-text-field
              v-model="searchTerm.sorted"
              clearable
              light
              placeholder="suchen"
              autocomplete="off"
              prepend-icon="mdi-magnify"
              @click:clear="searchTerm.sorted=null"
          ></v-text-field>
          <div class="product-count">
            {{ sortedProducts.length }} {{ sortedProducts.length > 1 ? 'Produkte' : 'Produkt' }}
          </div>
        </v-col>
        <v-col>
          <h2>Produkte nicht sortiert</h2>
          <v-text-field
              v-model="searchTerm.unsorted"
              clearable
              light
              placeholder="suchen"
              autocomplete="off"
              prepend-icon="mdi-magnify"
              @click:clear="searchTerm.unsorted=null"
          ></v-text-field>
          <div class="product-count">
            {{ unsortedProducts.length }} {{ unsortedProducts.length > 1 ? 'Produkte' : 'Produkt' }}
          </div>
        </v-col>
      </v-row>

      <v-row v-show="filterActive">
        <v-col class="drag-col">
          <draggable
              v-model="sortedProducts"
              group="sortingProducts"
              @change="updateSortedProduct"
              class="dnd-container"
              handle=".product-sortable"
          >
            <ProductSortable v-for="product in sortedProducts" :key="product.ArtikelNrLAG" :product="product"/>
          </draggable>
        </v-col>
        <v-col class="drag-col">
          <div class="dnd-container">
            <v-alert
                type="warning"
                elevation="1"
                border="bottom"
                colored-border
            >
              Artikel ohne Sortierungsinformationen werden im Sortiment jeweils am Ende der Liste angezeigt.
            </v-alert>
            <draggable
                v-model="unsortedProducts"
                group="sortingProducts"
                @change="updateUnsortedProduct"
                handle=".product-sortable"
            >
              <ProductSortable v-for="product in unsortedProducts" :key="product.ArtikelNrLAG" :product="product"/>
            </draggable>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ProductSortable from "../../components/app/superadmin/ProductSortable";
import {mapGetters} from "vuex";
import {benutzerGetters} from "../../obsvervables/benutzer";

export default {
  name: 'ProductSorting',
  components: {draggable, ProductSortable},

  data() {
    return {
      drawer: true,
      brands: ['MOLI', 'TENA', 'ATND', 'ABEN'],
      types: [
        {key: 'Standard', name: 'Hauptartikel'},
        {key: 'Zusatz', name: 'Zusatzartikel'},
        {key: 'nh', name: 'Fixierhose'}
      ],
      filter: {
        brand: 'MOLI',
        type: {key: 'Standard', name: 'Hauptartikel'}
      },
      lists: {
        sorted: null,
        unsorted: null
      },
      searchTerm: {
        sorted: null,
        unsorted: null
      }
    }
  },

  computed: {
    ...benutzerGetters,

    ...mapGetters({
      products: 'superadmin/sortableProducts'
    }),

    filterActive() {
      return this.filter.brand && this.filter.type
    },

    filteredProducts() {
      if (!this.products) {
        return []
      }

      return this.products.filter(p => p.InkoBrand == this.filter.brand && (p.InkoSortimentTyp === this.filter.type.key || (this.filter.type.key === 'nh' && p.isFixPants)))
    },

    sortedProducts: {
      get() {
        const products = this.filteredProducts.filter(p => p.index > 0)

        if (this.searchTerm.sorted) {
          return this.filterProducts(this.searchTerm.sorted, products)
        }

        return products.sort((a, b) => a.index > b.index)
      },
      set(list) {
        this.lists.sorted = list
      }
    },

    unsortedProducts: {
      get() {
        const products = this.filteredProducts.filter(p => p.index === 0)

        if (this.searchTerm.unsorted) {
          return this.filterProducts(this.searchTerm.unsorted, products)
        }

        return products
      },
      set(list) {
        this.lists.unsorted = list
      }
    },
  },

  mounted() {
    this.$store.dispatch('superadmin/loadSortableProducts')
  },

  methods: {
    updateSortedProduct(data) {

      if (data.removed) {
        this.$store.dispatch('superadmin/resetSortableProduct', data.removed.element.ArtikelNrLAG)
      } else if (data.added || data.moved) {
        this.$store.dispatch('superadmin/updateSortableProducts', this.lists.sorted)
      }
    },

    updateUnsortedProduct(data) {

    },

    filterProducts(term, products) {
      term = term.toLowerCase()

      return products.filter(p => p.ArtikelNrLAG.toString().includes(term) || p.Bezeichnung1.toLowerCase().includes(term))
    }
  }
}
</script>

<style scoped lang="scss">
.product-count {
  text-align: right;
}

.drag-col {
  max-height: 800px;
}

.dnd-container {
  height: 100%;
  overflow-y: auto;
  padding: 5px;
  background-color: hsl(0, 0%, 95%);
  border: 1px solid hsl(0, 0%, 85%);
}
</style>
