<template>
  <div id="account">
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12">
          <h1>
            Account
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2>Passwort neu setzen</h2>
            </v-card-title>
            <v-card-text class="body-3">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field class="mb-0"
                                    autocomplete="off"
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="neues Passwort"
                                    v-model="pw1"
                                    :rules="[rules.required, /*rules.min,*/rules.strength]"
                                    @click:append="show = !show"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                          autocomplete="off"
                          :type="show ? 'text' : 'password'"
                          name="input-10-2"
                          label="neues Passwort wiederholen"
                          v-model="pw2"
                          :error="checkPasswords"
                          @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label class="text-caption">
                        <v-icon color="grey">mdi-shield-lock</v-icon>
                        Geschätzte Sicherheitsstufe: <span>{{ score.level }}</span>
                      </label>
                      <v-progress-linear class="mt-2"
                                         :color="score.color"
                                         :value="score.value"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pb-4">
              <v-btn color="primary" class="mt-0 mb-4" depressed @click="savePassword();">neues Passwort setzen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card flat outlined class="px-4 py-4">
            <v-card-title>
              <h2> Standard-Wohneinheit setzen</h2>
            </v-card-title>
            <v-card-text class="body-3">
              <v-row>
                <v-col class="mt-4 mb-4">
                  Wählen Sie die Wohneinheit welche in Puras Inko Pro standardmässig geöffnet werden soll
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                      clearable
                      v-model="benutzer.default_wh"
                      :items="wohneinheiten"
                      label="Wohneinheit auswählen"
                      item-text="name"
                      item-value="id"
                      :loading="!benutzer || savingDefaultWh"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pb-4">
              <v-btn color="primary" class="mt-0 mb-4" depressed @click="saveDefaultUserWH()">Auswahl speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import {benutzerGetters, benutzerActions, benutzerMutations} from '@/obsvervables/benutzer';
import {wohneinheitenGetters, wohneinheitenActions} from "../obsvervables/wohneinheiten";

import zxcvbn from 'zxcvbn';

export default {
  name: 'Account',
  computed: {
    ...benutzerActions,
    ...benutzerGetters,
    ...wohneinheitenGetters,
    ...wohneinheitenActions,

    checkPasswords() {
      return this.pw1 !== this.pw2;
    },

    score() {
      const result = zxcvbn(this.pw1);

      switch (result.score) {
        case 4:
          return {
            color: "orange",
            value: 100,
            level: 4
          };
        case 3:
          return {
            color: "orange",
            value: 75,
            level: 3
          };
        case 2:
          return {
            color: "orange",
            value: 50,
            level: 2
          };
        case 1:
          return {
            color: "orange",
            value: 25,
            level: 1
          };
        default:
          return {
            color: "orange",
            value: 0,
            level: 0
          };
      }
    }
  },

  data() {
    return {
      show: false,
      show2: false,
      savingDefaultWh: false,
      pw1: '',
      pw2: '',
      rules: {
        required: value => !!value || 'Bitte Passwort ausfüllen.',
        //min: v => v.length >= 3 || 'Mindestens 3 Zeichen',
        strength: value => {
          //Min. 8 characters with at least one capital letter, a number and a special character.
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\(\)\/\=\+\-])(?=.{8,})/;
          return (
              pattern.test(value) ||
              "Mindesten 8 Zeichen. Davon mindestens ein Gross- und ein Klein-Buchstabe, eine Nummer und ein Sonderzeichen (!@#$%^&*.,()/=+-)"
          );
        }
        //strength: v => zxcvbn(v).score >= 1 || 'Das Passwort scheint nicht gerade sicher. Es wäre besser wenn Du etwas anderes wählen würdest.'
      },

    }
  },

  created() {
    this.fetchWohneinheiten;
  },

  methods: {
    savePassword() {
      benutzerActions.saveNewPassword(this.pw1, this.pw2);
    },

    saveDefaultUserWH() {
      this.savingDefaultWh = true

      benutzerActions.saveDefaultWh(this.benutzer.default_wh)
          .then(() => this.savingDefaultWh = false)
          .catch(() => this.savingDefaultWh = false)
    }
  }
}
</script>
