<template>
  <v-navigation-drawer id="rightNav" light permanent fixed color="secondary" expand-on-hover right style="z-index:6;border:none !important;background-position: right">
    <v-list class="mb-0 pa-0">
      <v-list-item class="py-0 px-2 mb-0">
        <v-list-item-avatar v-if="benutzer.Vorname && benutzer.Name" class="mt-5" height="40" width="40" min-width="40" min-height="40">
          <v-btn class="mx-2" fab dark color="primary">
            {{ benutzer.Vorname.substring(0, 1) }}{{ benutzer.Name.substring(0, 1) }}
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="pl-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ benutzer.Vorname }} {{ benutzer.Name }}
          </v-list-item-title>
          <v-list-item-subtitle class="email"> {{ benutzer.EMail }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list nav dense>
      <div class="top-nav">
        <v-list-item link to="/account">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'account' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Account</v-list-item-title>
        </v-list-item>
        <v-divider light class="my-4"></v-divider>
        <v-list-item link to="/planung">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'planung' ? require('@/assets/img/ico2023/planung-aktiv.svg') : require('@/assets/img/ico2023/planung.svg')"
                alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Planung</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/bewohnerverwaltung">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'bewohnerverwaltung' ? require('@/assets/img/ico2023/bewohner-aktiv.svg') : require('@/assets/img/ico2023/bewohner.svg')"
                alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Bewohner</v-list-item-title>
        </v-list-item>

        <v-divider light class="my-4"></v-divider>

        <v-list-item link to="/sortiment">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'sortiment' ? require('@/assets/img/ico2023/sortiment-aktiv.svg') : require('@/assets/img/ico2023/sortiment.svg')"
                alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Puras Sortiment</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/wohneinheiten">
          <v-list-item-icon>
            <img class="whimg"
                   :src="this.$route.name == 'wohneinheiten' ? require('@/assets/img/ico2023/wohneinheit-aktiv.svg') : require('@/assets/img/ico2023/wohneinheit.svg')"
                   alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Wohneinheiten</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/lieferoptionen">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'lieferoptionen' ? require('@/assets/img/ico2023/lieferoptionen-aktiv.svg') : require('@/assets/img/ico2023/lieferoptionen.svg')"
                alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Lieferoptionen</v-list-item-title>
        </v-list-item>
        <v-divider light class="my-4"></v-divider>
        <v-list-item link to="/statistiken">
          <v-list-item-icon>
            <img
                :src="this.$route.path.indexOf('statistiken') != -1 ? require('@/assets/img/ico2023/statistik-aktiv.svg') : require('@/assets/img/ico2023/statistik.svg')"
                alt="" width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Statistiken</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'barometer' }">
          <v-list-item-icon>
            <img
                :src="this.$route.path.indexOf('barometer') != -1 ? require('@/assets/img/ico2023/inkobarometer-aktiv.svg') : require('@/assets/img/ico2023/inkobarometer.svg')"
                alt="" contain max-width="45"/>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Inko-Barometer</v-list-item-title>
        </v-list-item>
        <v-divider v-if="superadmin === 1 || tooladmin === 1" light class="my-4"></v-divider>
        <v-list-item v-if="superadmin == 1" link to="/superadmin">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'superadmin' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"
                alt="" />
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Superadmin</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="tooladmin == 1 || superadmin == 1" link to="/tooladmin">
          <v-list-item-icon>
            <img
                :src="this.$route.name == 'tooladmin' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"
                alt="" />
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Benutzerverwaltung</v-list-item-title>
        </v-list-item>
        <v-divider light class="my-4"></v-divider>
        <v-list-item link @click="logoutUser()">
          <v-list-item-icon>
            <img :src="require('@/assets/img/ico2023/logout.svg')" alt="" />
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1">Logout</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {logoutUser} from '@/utils/auth'
import {benutzerGetters, benutzerActions} from '@/obsvervables/benutzer';
import {kundeGetters, kundeActions} from '@/obsvervables/kunde';

export default {
  name: 'RightNavigation',
  props: ["superadmin","tooladmin"],
  computed: {
    ...benutzerGetters,
    ...kundeGetters
  },
  methods: {
    logoutUser() {
      logoutUser();
      this.$router.push('/login')
    },
    ...benutzerActions,
    ...kundeActions
  },
  created() {
    this.fetchBenutzer()
    this.fetchKunde();
  }
}
</script>
<style lang="scss">


.v-navigation-drawer--is-mouseover{
  width: 260px !important;
}

#rightNav{
  top: 116px !important;
  border: none !important;
  padding: 0 7px;
  box-sizing: content-box;

  .v-navigation-drawer__border{
    background: none !important;
  }

  .v-image{
    width: 28px !important;
    margin-right: 10px !important;
    height: 28px !important;
  }

  .v-list-item {
    padding: 0;
    text-align: left;
  }

  .v-list-item__icon{
    padding: 0 4px;
    width: 100%;
    max-width: 30px;

    .v-image__image {
      background-size: 100% auto;
    }

    img {
      max-width: 36px;
      max-height: 100%;
      width: 100%;
    }
  }

  .whimg{
    .v-image__image{
      width: 21px !important;
      margin-right: 10px !important;
      height: 21px !important;
    }
  }

  .v-avatar {
    button {
      height: 40px;
      width: 40px;
      font-size: 15px;
    }
  }

  .title {
    font-size:21px !important;
    margin-bottom: 5px;
    font-family:'Avenir LT Std Heavy' !important;
  }

  .email {
    font-family: "Avenir LT Std";
    font-weight: 300;
    font-size: 14px;
  }

  &.v-navigation-drawer--is-mouseover {
    padding: 0 20px 0 30px !important;
  }

  &.v-navigation-drawer--right {
    //fix hover bug
    right: -1px
  }

}
//
//.top-nav {
//  width: 100%;
//
//}
//
//.bottom-nav {
//  width: 100%;
//  position: absolute;
//  top: 435px;
//}
//
//#rightNav {
//  margin-top: 116px;
//  border: none !important;
//
//  @media screen and (max-height: 850px) {
//    min-width: 68px;
//    overflow-y: auto;
//  }
//
//  .v-navigation-drawer__border {
//    background: none !important;
//  }
//
//  .v-image {
//    width: 28px !important;
//    margin-right: 10px !important;
//    height: 28px !important;
//  }
//
//  .v-list-item__icon {
//    padding-right: 10px !important;
//  }
//
//  .whimg {
//    .v-image__image {
//      width: 21px !important;
//      margin-right: 10px !important;
//      height: 21px !important;
//    }
//  }
//}
</style>
