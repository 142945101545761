<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h1>
          Bevorstehende Bestellung
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="pt-2">
        <router-link to="/statistiken">&laquo; zurück zur Übersicht</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" v-if="upcomingwarenkorbe">
        <div v-if="Object.keys(upcomingwarenkorbe).length > 0">
          <v-row class="planunglegends">
            <v-col cols="4" class="caption stk">
              Bezeichnung
            </v-col>

            <v-col cols="1" class="caption">
              Anzahl
            </v-col>
            <v-col cols="2" class="caption">
              <span class="float-right">
                Preis
              </span>
            </v-col>
          </v-row>
          <div v-for="(b,index) in upcomingwarenkorbe" :key="'up-'+index">
            <v-divider light></v-divider>
            <h3 class="my-6">
              {{ moment(b.date).locale("de").format('dddd D.M.YYYY') }}
            </h3>
            <v-divider light class="mb-6"></v-divider>
            <v-row v-for="(prod,index2) in b.produkte" :key="'upp-'+index+'-'+index2">
              <v-col cols="1">
                  <span v-if="bilder[prod.artNr] && bilder[prod.artNr][0]['images'][0] ">
                 <img alt=""
                      :src="bilder[prod.artNr][0]['images'][0].path+'?width=80'"
                      height="45"/>
                </span>
              </v-col>
              <v-col cols="3">
                <product-caption :artnr="prod.artNr" :name="prod.bezeichnung"
                                 :einheit="prod.Verkaufseinheit"></product-caption>
              </v-col>

              <v-col cols="1" class="">
                {{ prod.menge }}
              </v-col>
              <v-col cols="2" class="">
          <span class="float-right">
            CHF {{ (prod.menge * prod.stkPreis).toFixed(2) }}
          </span>
              </v-col>
            </v-row>

            <v-row v-for="(prod,index2) in b.zusatzartikel" :key="'upz-'+index+'-'+index2">
              <v-col cols="1">
                  <span v-if="bilder[prod.artNr] && bilder[prod.artNr][0]['images'][0] ">
                 <img alt=""
                      :src="bilder[prod.artNr][0]['images'][0].path+'?width=80'"
                      height="45"/>
                </span>
              </v-col>
              <v-col cols="3">
                <product-caption :artnr="prod.artNr" :name="prod.bezeichnung"
                                 :einheit="prod.Verkaufseinheit"></product-caption>
              </v-col>
              <v-col cols="1" class="">
                {{ prod.menge }}
              </v-col>
              <v-col cols="2" class="">
          <span class="float-right">
                CHF {{ (prod.menge * prod.stkPreis).toFixed(2) }}
          </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-divider light></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <b>TOTAL</b>
              </v-col>
              <v-col cols="5">
          <span class="float-right">
            CHF {{ getOrderTotal(b) }}
          </span>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-divider light></v-divider>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else>
          <v-row>
            <v-col class="pt-5">
          <span class="body-1">
              Keine anstehenden Bestellungen
          </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-else>
        Keine bevorstehenden Bestellungen
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import {warenkorbGetters, warenkorbActions, warenkorbMutations} from '@/obsvervables/warenkorb';

export default {
  name: 'Upcoming',

  computed: {
    ...warenkorbGetters,
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  methods: {
    ...warenkorbActions,
    ...warenkorbMutations,
    getOrderTotal(b) {
      var total = 0;
      for (var prod in b.produkte) {

        total += b.produkte[prod].stkPreis * b.produkte[prod].menge;
      }
      for (var prod in b.zusatzartikel) {

        total += b.zusatzartikel[prod].stkPreis * b.zusatzartikel[prod].menge;
      }

      return total.toFixed(2);
    }
  },
  created() {
    this.fetchUpcomingWarenkorbe();
  }

}
</script>