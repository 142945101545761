<template>
  <div v-if="!allowKostengutsprache && history.length === 0">
    <p class="fs-18 mb-10">Die Erfassung einer Kostengutsprache ist nur bei einer totalen Inkontinenz möglich.</p>
  </div>
  <div v-else>
    <!--<v-row>
        <v-col cols="10">
            <p class="fs-18 mb-10" v-if="allowKostengutsprache">Erfassen Sie hier die aktuelle Kostengutsprache. Sollte sich
                die
                Gutsprache ändern,
                klicken
                Sie unten auf «Kostengutsprache Änderung erfassen».</p>
            <p class="fs-18 mb-10" v-if="!allowKostengutsprache">Die Erfassung einer Kostengutsprache ist nur bei einer
                totalen
                Inkontinenz möglich.</p>
        </v-col>
    </v-row>-->
    <v-row class="ml-0 pl-0" >
      <v-col cols="12" class="pl-0">
        <template v-if="loading">
          <div class="loading-wrapper">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </template>
        <template v-if="!loading">
          <v-row>
            <v-col cols="3" class="pl-0 pr-0">
              <KostengutspracheDialog
                  @save="addKostengutsprache($event)" :min-date="getMinDate()"
                  :max-value="maxValue" :bewohner="formValues"
                  v-if="allowKostengutsprache">
                <template #button="slotProps">
                  <div class="add-icon-container mt-0" @click="slotProps.open()">
                    <v-btn small fab light depressed color="white" class="addbtn">
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <div class="text">Neue Kostengutsprache erfassen</div>
                  </div>
                </template>
              </KostengutspracheDialog>
            </v-col>
            <v-col cols="9" class="pl-3" >
              <v-row v-for="(item, index) in history" :class="{'b-bottom': index < history.length-1}">
                <v-col cols="2" offset="1" class="pl-0">
                  {{ item.amount }}
                </v-col>
                <v-col cols="4" class="pl-0" style="margin-left: -8px">
                  {{ asMoment(item.start).format('MMM YYYY')}}
                </v-col>
                <v-col cols="4" class="pl-1" style="margin-left: -8px">
                  {{ item.end }}
                </v-col>
                <div class="flex-grow-1">
                    <div class="float-right pr-0 pt-3" style="margin-right: 8px;">
                       <ConfirmDialog title="Kostengutsprache löschen"
                                      @confirm="deleteKostengutsprache(item.id)">
                    <template #button>
                      <v-hover v-slot="{ hover }">
                        <img :src="require('@/assets/img/ico/schliessen.svg')" width="12" alt=""/>
                      </v-hover>
                    </template>
                    Möchten Sie diese Kostengutsprache wirklich löschen? Sollte die Kostengutsprache
                    wechseln, bitte fügen
                    Sie einen neuen Eintrag hinzu.
                  </ConfirmDialog>
                    </div>
                </div>

              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {kostengutspracheActions, kostengutspracheGetters} from "@/obsvervables/kostengutsprache";
import {inkoGradGetters} from "@/obsvervables/inkograd";
import KostengutspracheDialog from "@/components/app/bewohner/Bewohnerverwaltung/dialogs/KostengutspracheDialog.vue";
import moment from 'moment';
import ConfirmDialog from '@/components/app/bewohner/Bewohnerverwaltung/dialogs/ConfirmDialog.vue'
import {globalRules} from "@/composables/rules";
import {useBewohnerHelper} from "@/composables/bewohnerHelper";

export default {
  name: 'Kostengutsprache',
  components: {ConfirmDialog, KostengutspracheDialog},
  props: {
    formValues: {
      required: true
    },
    inkograd: {
      required: true,
    },
    allowKostengutsprache: {
      required: true,
    },
    edit: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      bewohnerHelper: useBewohnerHelper,
      maxValue: null,
      rules: globalRules,
    }
  },
  computed: {
    ...kostengutspracheGetters,
    ...inkoGradGetters,
    loading: {
      get() {
        return this.kostengutspracheHistory === null ? true : false;
      }
    },
    history: {
      get() {
        let history = []
        if (this.kostengutspracheHistory) {
          let sortedHistory = [...this.kostengutspracheHistory].sort((a, b) => moment(a.start).isAfter(moment(b.start)) ? 1 : -1);
          for (let i = 0; i < sortedHistory.length; i++) {
            let month = {
              id: sortedHistory[i].id,
              start: moment.unix(sortedHistory[i].start),
              end: sortedHistory[i].end ? moment.unix(sortedHistory[i].end).locale('de').format('MMM YYYY') : null,
              amount: sortedHistory[i].amount + " CHF"
            }

            if (sortedHistory[i + 1]) {
              month.end = month.end ? month.end : moment.unix(sortedHistory[i + 1].start).subtract(1, 'months').locale('de').format('MMM YYYY')
            } else {
              month.end = month.end ? month.end : moment.unix(month.start).isAfter(moment()) ? 'unbestimmt' : 'heute';
            }
            // moment.locale('de')
            // let curDate = moment(sortedHistory[i].start)
            // month.end = curDate.add(11 - curDate.month(), 'months').locale('de').format('MMM YYYY')

            history.push(month)
          }
        }
        if (history.length > 0) {
          this.formValues.kostengutsprache_start = null;
        }

        return history
      }
    }
  },
  methods: {
    ...kostengutspracheActions,
    addKostengutsprache(data) {
      this.add(data);
    },

    deleteKostengutsprache(id) {
      this.delete(id);
    },

    asMoment(date = null, locale = "de") {
      return date ? moment(date).locale(locale) : moment().locale(locale);
    },

    getMinDate() {
      if (this.bewohnerKostengutspracheHistory && this.bewohnerKostengutspracheHistory.length > 0) {
        let sortedHistory = [...this.bewohnerKostengutspracheHistory].sort((a, b) => moment(a.start).isAfter(moment(b.start)) ? 1 : -1);
        if (sortedHistory[sortedHistory.length - 1].end) {
          return moment(sortedHistory[sortedHistory.length - 1].end).add(1, 'month');
        }
        return moment(sortedHistory[sortedHistory.length - 1].start).add(1, 'month');
      } else if (this.getCurrentInkogradObject && this.getCurrentInkogradObject.inkograd == 5) {
        return moment(this.getCurrentInkogradObject.start)
      } else if (this.bewohnerHelper.getNextTotalInkogradFromStore(this.formValues.bewohnerID)) {
        return moment(this.bewohnerHelper.getNextTotalInkogradFromStore(this.formValues.bewohnerID).start)
      }

      return moment().startOf('year');
    },


    getMaxDate() {
      return moment().endOf('year');
    },

    setDate(date) {
      this.formValues.kostengutsprache_start = moment(date, "YYYY-MM", false).format("YYYY-MM-DD");
    }
  },
  mounted() {
    try {
      this.maxValue = this.getCurrentInkogradObject.allowance[0].HVB_Pflege.toFixed(2)
    } catch (error) {
      this.maxValue = this.getMaxKostengutsprache ? this.getMaxKostengutsprache.toFixed(2) : 0;
    }
  }
}
</script>

<style scoped lang="scss">
.loading-wrapper {
  min-height: 150px;
  position: relative;

  .v-progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.add-icon-container {
  cursor: pointer;
  margin-top: 20px;

  .v-btn {
    display: block;
    float: left;
  }

  .text {
    display: block;
    padding: 2px 0px;
    float: left;
    width: 160px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
  }
}

.addbtn {
  margin-right: 8px !important;
}

</style>

