<template>
  <div>
    <drag v-for="p in products" :key="'p-'+p.ArtikelNrLAG" :data="p" class="product">

      <v-hover v-slot="{ hover }" open-delay="0" >
        <v-list-item  :class="[meineprodukteIDs.indexOf(p.ArtikelNrLAG) > -1 ? 'secondary' : '']">
<div style="margin-right: 20px;">
          <span v-if="bilder[p.ArtikelNrLAG] && bilder[p.ArtikelNrLAG][0].images && bilder[p.ArtikelNrLAG][0].images[0] ">
             <v-img :src="bilder[p.ArtikelNrLAG][0].images[0].path+'?width=70'" width="70"></v-img>
          </span>
            <span v-else>
             <v-img :src="require('@/assets/img/no-image-ip.svg')" width="70" ></v-img>
          </span>
</div>
          <v-list-item-content>
            <v-row>
              <v-col cols="9" style="position: relative">
                <product-caption :p="p" :artnr="p.ArtikelNrLAG" :name="p.Bezeichnung1" :einheit="p.Verkaufseinheit"></product-caption>
              </v-col>
              <v-col cols="3" v-if="ismylist == 'true'">
                <v-hover v-slot="{ hover }" open-delay="0" >
                  <v-img v-if="hover" class="float-right mt-1 cursor-pointer" @click="removeFromMeineProdukte(p.ArtikelNrLAG)" :src="require('@/assets/img/ico2021_samesize/loeschen-hover.svg')" alt="" max-width="30"></v-img>
                  <v-img v-else class="float-right mt-1 cursor-pointer" @click="removeFromMeineProdukte(p.ArtikelNrLAG)" :src="require('@/assets/img/ico2021_samesize/loeschen.svg')" alt="" max-width="30"></v-img>
                </v-hover>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
    </drag>
  </div>
</template>
<script>
import {Drag} from "vue-easy-dnd";
import {prodtypActions, prodtypGetters} from "@/obsvervables/producttabs";
export default {
  components:{
    Drag
  },
  name: 'SortimentList',
  props:['products','meineprodukteIDs','ismylist'],
  computed:{
    bilder:{
      get(){ return this.$store.state['bilder'].produktbilder },
    },
  },
  methods:{
    removeFromMeineProdukte(artNr){
      this.$emit('removeFromMeineProdukte',{'data':{'ArtikelNrLAG':artNr}});
    }
  }
}
</script>
<style scoped>
.odd{
  background-color: #fdf9f7;
}

.v-list-item {
  border-radius: 10px;
  margin-bottom: 5px;
padding: 16px;
}
</style>
