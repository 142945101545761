import Vue from 'vue'
import Vuex from 'vuex'
import { loader } from './modules/loader';
import { benutzer } from './modules/benutzer';
import { planungslock } from './modules/planungslock';
import { bilder } from './modules/bilder';
import {planungstate} from "@/store/modules/planungstate";
import superadmin from "@/store/modules/superadmin"
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  modules: {
    loader,
    benutzer,
    bilder,
    planungslock,
    planungstate,
    superadmin
  }
});