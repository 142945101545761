<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-hover v-slot="{ hover }" open-delay="0">
        <div class="d-flex body-2 mr-0"
             v-bind="attrs"
             v-on="on"
        >
          <div class="flex small-button" style="padding-top: 2px;">
            Planung ansehen
          </div>

        </div>
      </v-hover>
    </template>

    <v-card>
      <v-card-title>
        Planung
      </v-card-title>
      <v-card-subtitle>
        {{ bewohner.vorname }} {{ bewohner.name }}
      </v-card-subtitle>
      <v-card-text v-if="planingData">

        <v-divider light></v-divider>
        <div v-if="planingData.hasOwnProperty('n') || planingData.hasOwnProperty('d')">
          <v-row v-for="shift in shifts" :key="shift" class="my-0 py-0">
            <v-col cols="2" class="my-0 py-0 pt-4">
              <v-img :src="require('@/assets/img/ico2021_samesize/bedarf-'+shift+'.svg')" alt="" class="mt-5" max-width="30"></v-img>
            </v-col>
            <v-col cols="10" class="my-0 py-0">
              <v-row v-if="planingData[shift]">
                <v-col>
                  <v-row v-for="product in planingData[shift]" :key="'product-d-' + product.ArtikelNrLAG">
                    <v-col cols="3">
            <span v-if="bilder[product.ArtikelNrLAG] && bilder[product.ArtikelNrLAG][0].images && bilder[product.ArtikelNrLAG][0].images[0] ">
              <v-img :src="bilder[product.ArtikelNrLAG][0].images[0].path+'?width=80'" width="50" height="50" :contain="true"></v-img>
            </span>
                      <span v-else>
                        <v-img :src="require('@/assets/img/no-image-ip.svg')" width="80" ></v-img>
            </span>
                    </v-col>
                    <v-col cols="7" class="pl-4">
                      <product-caption :p="product" :artnr="product.ArtikelNrLAG" :name="product.Bezeichnung1 " :einheit="product.Verkaufseinheit"></product-caption>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-center">
                      {{ product.stk }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="ma-0 pa-0 pr-3">
              <v-divider light></v-divider>
            </v-col>
          </v-row>
        </div>
        <div v-else class="mt-6">
          Zur Zeit sind keine Pordukte bei {{ bewohner.vorname }} {{ bewohner.name }} geplant.
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            depressed
            @click="dialog = false"
        >
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {bewohnerDetailsGetters, bewohnerDetailsActions} from "../../../obsvervables/BewohnerDetails";

export default {
  name: 'BarometerPlanungModalDialog',

  props: {
    bewohner: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      'shifts': ['d', 'n']
    }
  },

  computed: {
    ...bewohnerDetailsGetters,
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  watch: {
    dialog() {
      if (this.dialog) {
        bewohnerDetailsActions.loadPlaningData(this.bewohner.bewohnerID)
      }
    }
  }
}
</script>

<style scoped>

</style>
