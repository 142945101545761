export const planungslock = {
  namespaced: true,
  state: {
    showLockWarning: false
  },
  actions: {

    setShowLockWarning({ commit },value) {
      commit("setShowLockWarning",value);
    }
  },
  mutations: {

    setShowLockWarning(state,val){
      state.showLockWarning = val;
    }
  }
};