import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import moment from "moment";


const kostengutspracheState = Vue.observable({
    loading: true,
    bewohnerId: null,
    kostengustpracheHistory: null,
})

export const kostengutspracheGetters = {
    bewohnerId: () => kostengutspracheState.bewohnerId,

    kostengutspracheHistory: () => kostengutspracheState.kostengustpracheHistory
}

export const kostengutspracheMutations = {
    setBewohnerId(bewohnerId) {
        kostengutspracheState.bewohnerId = bewohnerId
    },

    setKostengustpracheHistory(kostengustpracheHistory) {
        kostengutspracheState.kostengustpracheHistory = kostengustpracheHistory
    },

    addKostengutsprache(kostengutsprache) {
        kostengutspracheState.kostengustpracheHistory.push(kostengutsprache)

        kostengutspracheState.kostengustpracheHistory.sort(function (a, b) {
            return moment.unix(a.start) - moment.unix(b.start);
        })
    },

    removeKostengutsprache(id) {
        let index = kostengutspracheState.kostengustpracheHistory.findIndex(i => i.id === id)

        if(index > -1) {
            kostengutspracheState.kostengustpracheHistory.splice(index, 1)
        }
    }
}

export const kostengutspracheActions = {
    loadHistoryByBewohnerId(bewohnerId) {
        // reset history
        kostengutspracheMutations.setKostengustpracheHistory(null)

        kostengutspracheMutations.setBewohnerId(bewohnerId)

        httpClient({
            url: process.env.VUE_APP_API_URL+'kostengutsprache/' + bewohnerId,
            method: 'GET'
        }).then((response) => {
            kostengutspracheMutations.setKostengustpracheHistory(response.data)
        })
    },

    add(kostengutsprache) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_URL + 'kostengutsprache/' + kostengutspracheGetters.bewohnerId(),
                method: 'POST',
                data: kostengutsprache
            }).then((response) => {
                kostengutspracheMutations.addKostengutsprache(response.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    delete(id) {
        httpClient({
            url: process.env.VUE_APP_API_URL+'kostengutsprache/' + kostengutspracheGetters.bewohnerId() + '/' + id,
            method: 'DELETE',
        }).then(() => {
            kostengutspracheMutations.removeKostengutsprache(id)
        })
    }
}