import Vue from 'vue'
import httpClient from "@/utils/httpClient";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const bewohnerprodukteState = Vue.observable({
    "bewohnerprodukte": []
});

export const bewohnerprodukteGetters = {
    bewohnerprodukte: () => bewohnerprodukteState.bewohnerprodukte
}

export const bewohnerprodukteMutations = {
    setBewohnerprodukte(bewohnerprodukte) {
        bewohnerprodukteState.bewohnerprodukte = bewohnerprodukte;
    }
}

export const bewohnerprodukteActions = {
    fetchBewohnerprodukte(){
        //if(bewohnerprodukteState.bewohnerprodukte.length < 1){
            httpClient({
                url: process.env.VUE_APP_API_URL+'get/bewohnerprodukte',
                method: 'GET'
            }).then(function (res) {
                bewohnerprodukteMutations.setBewohnerprodukte(res.bewohnerprodukte)
            })
        //}
    },

    async saveBewohnerprodukte(prodid,bewohnerID,dayornight,changeType,slot,whid,count){
        const res = await httpClient({
            url: process.env.VUE_APP_API_URL+'save/bewohnerprodukte',
            method: 'post',
            data: { 'pid': prodid,'bid': bewohnerID,'dayornight':dayornight,'changetype':changeType,'slot':slot,'whid': whid,'count': count}
        })

        if(res.type === 'success'){
            bewohnerprodukteMutations.setBewohnerprodukte(res.bewohnerprodukte);

        }
    }
}

export default {
    bewohnerprodukteState,
    bewohnerprodukteGetters,
    bewohnerprodukteMutations,
    bewohnerprodukteActions
}