<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          text
          v-bind="attrs"
          v-on="on"
      >
        <span style="margin-right: 10px;">Inkograd</span>
        <div v-if="hasInkograd">
          <InkoGradIndicator :inkograd="currentInkograd.inkograd" />
        </div>
      </v-btn>
    </template>

    <div v-if="!hasInkograd">
      hat keinen Inkograd
    </div>
    <div v-else>
      <div v-for="inkograd, i in inkogradHistorySubsidised" :key="'inkograd-'+i" class="mt-4">
        <div class="mb-1">
          {{ moment.unix(inkogradHistorySubsidised[i].start).locale('de').format('MMM YYYY') }}
          <span v-if="inkogradHistorySubsidised[i].end">- {{
              moment.unix(inkogradHistorySubsidised[i].end).locale('de').format('MMM YYYY')
            }}</span>
          <span v-else-if="inkogradHistorySubsidised[i+1]">- {{
              moment.unix(inkogradHistorySubsidised[i + 1].start).subtract(1, 'months').locale('de').format('MMM YYYY')
            }}</span>
        </div>
        <InkoGradIndicator :inkograd="inkogradHistorySubsidised[i].inkograd" />
      </div>
    </div>
  </v-tooltip>
</template>

<script>

import InkoGradIndicator from "./InkoGradIndicator";
export default {
  name: 'InkoGradInfo',
  components: {InkoGradIndicator},
  props: {
    year: {
      type: Number,
      required: false,
      default: null,
    },
    inkogradHistory: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasInkograd() {
      return this.inkogradHistory.length > 0
    },

    currentInkograd() {
      return this.inkogradHistory[this.inkogradHistory.length - 1]
    },

    inkogradHistorySubsidisedLastYear() {
      let lastYearInko = this.inkogradHistory.filter(i => i.inkograd > 1 && (this.moment.unix(i.start).format('YYYY') == this.year - 1))

      return lastYearInko.length > 0 ? lastYearInko[lastYearInko.length - 1] : null
    },

    inkogradHistorySubsidised() {
      let history = []

      if (this.year) {
        history = this.inkogradHistory.filter(i => i.inkograd > 1 && (this.moment.unix(i.start).format('YYYY') == this.year))

        if (this.inkogradHistorySubsidisedLastYear) {
          history.unshift(this.inkogradHistorySubsidisedLastYear)
        }
      } else {
        history = this.inkogradHistory
      }

      return history
    }
  }
}
</script>

<style scoped>
.v-btn__content {
  align-items: center;
  background-color: tomato;
}
</style>
