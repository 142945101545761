import Vue from 'vue'
import httpClient from "@/utils/httpClient";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const lieferoptionenState = Vue.observable({
    "lieferoptionen": {},
    "intervalle":{}
});

export const lieferoptionenGetters = {
    lieferoptionen: () => lieferoptionenState.lieferoptionen,
    intervalle: () => lieferoptionenState.intervalle
}

export const lieferoptionenMutations = {
    setLieferoptionen(lieferoptionen) {
        lieferoptionenState.lieferoptionen = lieferoptionen;
    },
    setIntervalle(intervalle) {
        lieferoptionenState.intervalle = intervalle;
    },

}

export const lieferoptionenActions = {
    fetchLieferoptionen(){
         httpClient({
            url: process.env.VUE_APP_API_URL+'get/lieferoptionen',
            method: 'GET'
        }).then(function (res) {
            if(res.lieferoptionen){
                lieferoptionenMutations.setLieferoptionen(res.lieferoptionen)
            }

            lieferoptionenMutations.setIntervalle(res.intervalle)
        })

    },

    //wenn whid -> dann intervall auf wohneinheit
    async saveLieferoptionen(field,val,whid=false){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+'save/lieferoptionen',
            method: 'post',
            data: { 'field': field, 'val': val,'whid': whid }
        })

        if(res.type === 'success'){
            lieferoptionenMutations.setLieferoptionen(res.lieferoptionen)
            lieferoptionenMutations.setIntervalle(res.intervalle)
        }else{
            this.fetchLieferoptionen();
        }
    }
}

export default {
    lieferoptionenState,
    lieferoptionenGetters,
    lieferoptionenMutations,
    lieferoptionenActions
}