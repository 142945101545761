<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h1>
          Demo
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="pt-2">
        <router-link to="/statistiken">&laquo; zurück zur Übersicht</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Ein kleiner Vorgeschmack was noch kommt...
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn depressed @click="fillData" color="primary">
          Neue Zufalls-Daten
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="4">
        <line-chart :chart-data="datacollection"></line-chart>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4" >
        <bar-chart :chart-data="datacollection"></bar-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9" class="mt-8">
        <v-divider light class="py-6"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <pie-chart :chart-data="datacollection"></pie-chart>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4" >
        <radar-chart :chart-data="datacollection"></radar-chart>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import LineChart from "@/components/charts/LineChart";
import BarChart from "@/components/charts/BarChart";
import PieChart from "@/components/charts/PieChart";
import RadarChart from "@/components/charts/RadarChart";
export default {
  name: 'Demo',
  components:{
    BarChart,
    LineChart,
    PieChart,
    RadarChart
  },
  data(){
    return {
      datacollection: {}
    }
  },


  methods:{
    fillData () {
      this.datacollection = {
        labels: ['Januar','Februar','März','April','Mai'],
        datasets: [
          {
            label: 'Bestellungen',
            borderColor: '#ffc995',
            backgroundColor: '#f4ede7',
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Planung',
            backgroundColor: '#FFC995',
            borderColor: '#f4ede7',
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }
        ],
        options:{}
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  },

  mounted() {
    this.fillData()

  },

}
</script>