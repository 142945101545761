<template>
  <div>
    <v-btn color="primary" @click="gradEditor=true"  depressed v-if="bewohnerInkoGradHistory !== null" :key="'ig-'+bewohnerInkoGradHistory">
        <span>
           <span v-if="bewohnerInkoGradHistory.length === 0" class="pr-1">
             neuer
           </span>
            Inkograd
            <span v-if="bewohnerInkoGradHistory.length > 0">
              ändern
            </span>
        </span>
    </v-btn>
    <v-dialog v-model="gradEditor" persistent max-width="400">
      <InkoGradEditor @done="gradEditor=false" @edited="edited"/>
    </v-dialog>
  </div>
</template>

<script>
import InkoGradEditor from "./InkoGradEditor";
import InkoGradHistory from "./InkoGradHistory";
import {inkoGradGetters} from "@/obsvervables/inkograd";

export default {
  name: 'InkoGradEditorModalDialog',
  components: {InkoGradHistory, InkoGradEditor},

  computed:{
    ...inkoGradGetters
  },

  data() {
    return {
      gradEditor: false
    }
  },

  methods: {
    edited() {
      this.$emit('edited')
    }
  }
}
</script>
