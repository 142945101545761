import Vue from 'vue'
import httpClient from '@/utils/httpClient'
import moment from 'moment'

const inkoGradState = Vue.observable({
  bewohnerId: null,
  bewohnerInkoGradHistory: null,
  grades: [
    {
      name: 'Inkograd unbekannt',
      grade: 0
    },
    {
      name: 'keine Inkontinenz',
      grade: 1
    },
    {
      name: 'leichte Inkontinenz',
      grade: 2
    },
    {
      name: 'mittlere Inkontinenz',
      grade: 3
    },
    {
      name: 'schwere Inkontinenz',
      grade: 4
    },
    {
      name: 'totale Inkontinenz',
      grade: 5
    }
  ]
})

export const inkoGradGetters = {
  bewohnerId: () => inkoGradState.bewohnerId,

  bewohnerInkoGradHistory: () => inkoGradState.bewohnerInkoGradHistory,

  getCurrentInkogradObject () {
    if (this.bewohnerInkoGradHistory.length > 0) {
      return this.bewohnerInkoGradHistory[this.bewohnerInkoGradHistory.length - 1]
    } else {
      return null
    }
  },

  getMaxKostengutsprache () {
    return inkoGradState.maxKostengutsprache
  },

  grades: () => inkoGradState.grades
}

export const inkoGradMutations = {
  setBewohnerId (bewohnerId) {
    inkoGradState.bewohnerId = bewohnerId
  },

  setBewohnerInkoGradHistory (bewohnerInkoGradHistory) {
    inkoGradState.bewohnerInkoGradHistory = bewohnerInkoGradHistory
  },

  addBewohnerInkoGrad (inkoGrad) {
    inkoGradState.bewohnerInkoGradHistory.push(inkoGrad)

    inkoGradState.bewohnerInkoGradHistory.sort(function (a, b) {
      return moment.unix(a.start) - moment.unix(b.start)
    })
  },

  removeBewohnerInkoGrad (id) {
    let index = inkoGradState.bewohnerInkoGradHistory.findIndex(i => i.id === id)

    if (index > -1) {
      inkoGradState.bewohnerInkoGradHistory.splice(index, 1)
    }
  },

  setMaxKostengutsprache(value) {
    inkoGradState.maxKostengutsprache = value;
  }
}

export const inkoGradActions = {

  getMaxKostengutspracheByInkograd (inkograd) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'inkograd/max-kostengutsprache/' + inkograd,
        method: 'GET'
      }).then((response) => {
        inkoGradMutations.setMaxKostengutsprache(response)
      })
    })
  },

  loadInkoGradHistoryByBewohnerId (bewohnerId) {
    // reset history
    inkoGradMutations.setBewohnerInkoGradHistory(null)

    inkoGradMutations.setBewohnerId(bewohnerId)

    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'inkograd/' + bewohnerId,
        method: 'GET'
      }).then((response) => {
        inkoGradMutations.setBewohnerInkoGradHistory(response.data)
      })
    })
  },

  addInkoGrad (inkoGrad) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'inkograd/' + inkoGradGetters.bewohnerId(),
        method: 'POST',
        data: inkoGrad
      }).then((response) => {
        inkoGradMutations.addBewohnerInkoGrad(response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  deleteInkoGrad (id) {
    return new Promise((resolve, reject) => {
      httpClient({
        url: process.env.VUE_APP_API_URL + 'inkograd/' + inkoGradGetters.bewohnerId() + '/' + id,
        method: 'DELETE',
      }).then((response) => {
        inkoGradMutations.removeBewohnerInkoGrad(id)
        resolve(response)
      })
    })
  }
}