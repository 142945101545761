import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#F38B1C',
                secondary: '#F4EDE7',
                white: '#FFFFFF',
                primarydarken: '#d47b1e',
                primarylighten1: '#FCDDBB',
                primarylighten2: '#F4EDE7',
                accent: '#000000',
                error: '#b71c1c',
            },
        },
    },
})


import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
