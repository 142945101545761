<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }" open-delay="0">
        <v-btn
            v-bind="attrs"
            v-on="on"
            text
            class="print-btn"
            :disabled="!wohneinheit"
        >
          <v-img :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')" alt="" style="margin:0 auto;" max-width="30" class="cursor-pointer"></v-img>
          <div class="block pt-0 caption text-center normal">
            einzelne Bewohner drucken
          </div>
        </v-btn>
      </v-hover>
    </template>

    <v-card v-if="wohneinheit && wohneinheit.bewohner">
      <v-card-title class="headline">
        Drucken
      </v-card-title>
      <v-card-text class="body-2 multiprintpopup">
        wählen Sie bitte die gewünschten Bewohner<br>
        <small>(maximal {{ maxBewohner }} Bewohner)</small>
        <br>
        <br>
        <b>{{ wohneinheit.wohneinheitenName }}</b>
        <v-divider class="my-4"></v-divider>

        <v-alert
            v-if="maxBewohnerReached"
            dense
            border="left"
            type="warning"
        >
          maximale Anzahl von {{ maxBewohner }} Bewohner erreicht
        </v-alert>

        <v-row>
          <v-col sm class="py-0 my-0" cols="10">
            <v-checkbox label="Alle auswählen" dense v-model="selectedAllBewohner" @change="toggleSelectAll"></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense v-for="b in wohneinheit.bewohner" :key="'mdnprint_'+b.bewohnerID">
          <v-col sm class="py-0 my-0" cols="10">
            <v-checkbox v-model="selectedBewohnerIds" :label="b.vorname+' '+b.name" :value="b.bewohnerID" dense :disabled="maxBewohnerReached && !selectedBewohnerIds.includes(b.bewohnerID)"></v-checkbox>
          </v-col>
          <v-col sm class="py-0 my-0" cols="2">
            {{ b.zimmer }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="close">Abbrechen</v-btn>
        <v-btn color="success" depressed @click="print" :disabled="selectedBewohnerIds.length <= 0">Drucken</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'StatisticsBewohnerPrintModalDialog',

  props: {
    wohneinheit: {
      default: () => [],
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      maxBewohner: 40, // max bewohner selectable for print
      selectedBewohnerIds: [],
      selectedAllBewohner: false
    }
  },

  computed: {
    maxBewohnerReached() {
      return this.selectedBewohnerIds.length >= this.maxBewohner
    }
  },

  methods: {
    close() {
      this.selectedAllBewohner = false
      this.selectedBewohnerIds = []
      this.dialog = false
    },

    toggleSelectAll() {
      if (this.selectedAllBewohner) {
        // reset all selected bewohner first
        this.selectedBewohnerIds = []

        this.wohneinheit.bewohner.forEach((b, i) => {
          if (i >= this.maxBewohner)
            return false

          this.selectedBewohnerIds.push(b.bewohnerID)
        })
      } else {
        this.selectedBewohnerIds = []
      }
    },

    async print() {
      this.dialog = false

      let selectedBewohner = this.wohneinheit.bewohner.filter(b => this.selectedBewohnerIds.indexOf(b.bewohnerID) > -1)

      // reset selected ids
      this.selectedBewohnerIds = []
      this.selectedAllBewohner = false
      this.$emit('print', selectedBewohner)
    }
  },

  watch: {
    selectedBewohnerIds() {
      this.selectedAllBewohner = this.selectedBewohnerIds.length >= this.maxBewohner || this.selectedBewohnerIds.length === this.wohneinheit.bewohner.length
    }
  }
}
</script>

<style scoped>

.multiprintpopup .v-input--selection-controls {
  margin-top: 0px !important;
}

.multiprintpopup .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
