import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {wohneinheitenMutations} from "@/obsvervables/wohneinheiten";
import {lieferoptionenMutations} from "@/obsvervables/lieferoptionen";

// httpClient.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('authToken')}`;
// httpClient.defaults.headers.common['uid'] = `${sessionStorage.getItem('uid')}`;

const kundeState = Vue.observable({
    "kunde": false,
    "superadminKunden": false
});

export const kundeGetters = {
    kunde: () => kundeState.kunde,
    superadminKunden: () => kundeState.superadminKunden
}

export const kundeMutations = {
    setKunde(kunde) {
        kundeState.kunde = kunde;
    },
    setSuperAdminKunden(kunden) {
        kundeState.superadminKunden = kunden;
    }
}

export const kundeActions = {
    fetchKunde(){
        //if(!kundeState.kunde){
            httpClient({
                url: process.env.VUE_APP_API_URL+'get/kunde',
                method: 'GET'
            }).then(function (res) {
                kundeMutations.setKunde(res)
            })
        //}
    },

    async fetchSuperAdminKunden(){
        //if(!kundeState.kunde){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+'get/superadminkunde',
            method: 'GET'
        })

        if(res.type === 'success'){
            kundeMutations.setSuperAdminKunden(res.kunden)
        }

        //}
    },

    saveKunde(){
        httpClient({
            url: process.env.VUE_APP_API_URL+'save/kunde',
            method: 'post',
            data: { 'kunde': kundeState.kunde }
        }).then(function (res) {
            if(res.type === 'success'){
                kundeMutations.setKunde(res.kunde)
            }
        })
    },

    async setNewUserPerspective(newid){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+'set/newuserperspective',
            method: 'post',
            data: { 'newid': newid}
        })

        if(res.type === 'success'){
           window.location.href = '/login';
        }else{
            this.fetchLieferoptionen();
        }
    }
}

export default {
    kundeState,
    kundeGetters,
    kundeMutations,
    kundeActions
}