<template>
  <v-dialog v-model="dialog" persistent max-width="400" @keydown.esc="abort">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="error" depressed @click="confirm">Löschen</v-btn>
        <v-btn color="primary" depressed @click="abort">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmtModalDialog',

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      text: null,
    }
  },

  methods: {
    show(title, text) {
      this.dialog = true
      this.title = title
      this.text = text

      return new Promise((resolve, reject) => {
        this.resolve = resolve,
        this.reject = reject
      })
    },

    abort() {
      this.resolve(false),
      this.dialog = false
    },

    confirm() {
      this.resolve(true),
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>