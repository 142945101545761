<template>
  <div>
    <div class="header-overlay">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/planung">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align" style="background-color: white">
                <div>
                  <router-link to="/planung" class="kunden-name" :class="{'small-text': kunde.length > 30}">
                    {{ kunde }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="mt-0 fixed-break-pad text-vertical-align" style="background-color: white">
            <v-row>
              <v-col cols="7" class="text-vertical-align">
                <BarometerSearchFilter/>
              </v-col>
              <v-col cols="5" class="text-vertical-align">
                <BarometerYearSelect/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="pt-0">
      <v-row>
        <v-col cols="10">
          <h1>
            Inko-Barometer
          </h1>
        </v-col>
        <v-col cols="2" class="d-flex align-end justify-end">
          <BarometerPrintModalDialog/>
        </v-col>
      </v-row>
      <div v-if="barometersLoaded" class="pt-3">
        <v-expansion-panels v-model="panel" accordion flat v-if="barometers.length > 0" :multiple="isSearchActive">
          <v-expansion-panel v-for="(wh,accIndex) in wohneinheiten" :key="wh.name+'-'+wh.id" v-if="wohnEinheitenBaromerters[wh.id] && wohnEinheitenBaromerters[wh.id].length > 0">
            <v-divider light v-if="accIndex==0"></v-divider>
            <v-expansion-panel-header v-show="!isSearchActive">
              <v-row dense class="text-vertical-align">
                <v-col cols="3">
                  <h2 class="pt-5 pb-4">{{ wh.name }}</h2>
                </v-col>
                <v-col cols="6" class="pt-0">
                </v-col>
                <v-col cols="3" class="pr-7 col col-3">
                  <div class="float-right">
                    <h3 class="bew-count normal float-left mr-3">
                      {{ wohnEinheitenBaromerters[wh.id].length }}
                    </h3>
                    <div class=" float-right">
                      <v-img
                          :src="wohnEinheitenBaromerters[wh.id].length > 0 ? require('@/assets/img/ico2021/bewohner.svg') : require('@/assets/img/ico2021/keine-bewohner.svg')"
                          alt="" width="19"></v-img>
                    </div>
                  </div>

                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <BewohnerBarometer v-for="(barometer, i) in wohnEinheitenBaromerters[wh.id]" :key="'barometer-'+barometer.bewohner.bewohnerID" :barometer="barometer"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else style="text-align: center">
          <div class="mt-16">
            Es wurden keine Daten gefunden oder es sind keine Inkograde bei den Bewohnern erfasst.
          </div>
          <div class="caption mt-4">
            Um den Inko-Barometer verwenden zu können, müssen zu Bewohnern zuerst Inkograde erfasst werden.
          </div>

          <v-btn color="primary" depressed :to="{ name: 'bewohner' }" class="mt-8">Bewohnererfassung</v-btn>
        </div>
      </div>
      <div v-else class="mt-16" style="text-align: center">
        Daten werden geladen
      </div>
    </v-container>
  </div>
</template>

<script>
import {bewohnerGetters, bewohnerActions} from '@/obsvervables/bewohner';
import {wohneinheitenGetters, wohneinheitenActions} from '@/obsvervables/wohneinheiten';
import {produkteActions} from '@/obsvervables/produkte';
import {barometerActions, barometerGetters, barometerMutations} from '@/obsvervables/barometer'
import {benutzerActions, benutzerGetters, benutzerMutations} from "../obsvervables/benutzer";
import _ from "lodash";
import BewohnerBarometer from "../components/app/Barometer/BewohnerBarometer";
import BarometerYearSelect from "../components/app/Barometer/BarometerYearSelect";
import BarometerSearchFilter from "../components/app/Barometer/BarometerSearchFilter";
import BarometerPrintModalDialog from "../components/app/Barometer/BarometerPrintModalDialog";
import {ActiveWohneinheitMixin} from "../mixins/ActiveWohneinheitMixin";

export default {
  name: 'InkoBarometer',
  mixins: [ActiveWohneinheitMixin],
  components: {BarometerPrintModalDialog, BarometerSearchFilter, BarometerYearSelect, BewohnerBarometer},
  props: ['kunde'],

  data() {
    return {
      panel: false,
    }
  },

  computed: {
    ...bewohnerGetters,
    ...wohneinheitenGetters,
    ...barometerGetters,
    ...benutzerGetters,

    wohnEinheitenBaromerters() {
      let whBarometers = []

      let filterText = this.filterText

      if (filterText && filterText.length > 0) {
        filterText = filterText.toLowerCase()
        // activate panels when filter changes
        this.panel = Array.from(Array(this.wohneinheiten.length).keys())
      }

      for (let i in this.wohneinheiten) {
        let whId = this.wohneinheiten[i].id
        let barometers = this.barometers.filter(b => b.bewohner.wohneinheitenID == whId)

        if (filterText && filterText.length > 0) {
          barometers = barometers.filter(b => b.bewohner.name.toLowerCase().includes(filterText) || b.bewohner.vorname.toLowerCase().includes(filterText) || b.bewohner.zimmer.toLowerCase().includes(filterText))
        }

        whBarometers[whId] = barometers
      }

      return whBarometers
    },

    isSearchActive() {
      return !!(this.filterText && this.filterText.length > 0)
    }
  },

  created() {
    wohneinheitenActions.fetchWohneinheiten()
    bewohnerActions.fetchBewohner();
    produkteActions.fetchProdukte();
    produkteActions.fetchDeletedProdukte()
    barometerActions.loadBarometers();
  },
  mounted() {
    if (this.activeWohneinheit) {
      this.initActiveWohneinheit()
    } else if(this.wohneinheiten) {
      this.panel = this.activeAccordion
      benutzerMutations.setActiveWohneinheit(this.wohneinheiten[this.activeAccordion] ? this.wohneinheiten[this.activeAccordion].id : null)
    }
  },


  watch: {
    panel() {
      if(this.panel > -1) {
        //scroll active panel into view
        setTimeout(() => {
          this.$vuetify.goTo('.v-expansion-panel--active', {offset: 20});
        }, 400);
      }
      if (this.wohneinheiten && this.panel !== this.activeWohneinheit && this.panel !== false) {
        if(this.wohneinheiten[this.panel]) {
          barometerMutations.setActiveAccordion(this.panel)
          benutzerMutations.setActiveWohneinheit(+this.wohneinheiten[this.panel].id)
        }
      }
    },

    reloadKey: function () {
      let currentYear = +this.moment().year()

      if (this.selectedYear === currentYear) {
        barometerActions.loadBarometers()
      } else {
        barometerActions.loadYearBarometers(this.selectedYear)
      }
    },

    isSearchActive: function () {
      if (this.isSearchActive) {
        this.panel = Array.from(Array(this.wohneinheiten.length).keys())
      } else {
        this.panel = this.activeAccordion
      }
    }
  }
}
</script>


