<template>
  <div id="planung">
    <div class="header-overlay">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/planung">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align" style="background-color: white">
                <div>
                  <router-link to="/planung" class="kunden-name" :class="{'small-text': kunde != undefined && kunde.length > 30}">
                    {{ kunde }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="mt-0 fixed-break-pad text-vertical-align" style="background-color: white">
            <v-text-field class="search-field" v-model="searchTerm"
                          append-icon="mdi-magnify" hide-details="auto"
                          clearable light placeholder="Suche nach Name, Vorname, Zimmer" autocomplete="off"
                          :disabled="wohneinheiten.length <= 0" @click:clear="searchTerm=''"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="10">
          <h1>
            Inkoplaner
          </h1>
        </v-col>
        <v-col cols="2" class="d-flex align-end justify-end">
          <BestellungPlannningPrintModalDialog/>
        </v-col>
      </v-row>
      <v-row dense class="title-row">
        <v-col cols="4" class="pb-1"></v-col>
        <v-col cols="3" class="pb-1" style="margin-left: -4px;">
          <span class="heavy caption">
            späteste Bestellungsaufgabe
          </span>
        </v-col>
        <v-col cols="2" class="pb-1" style="margin-left: -4px">
          <span class="heavy caption">nächste Lieferung</span>
        </v-col>
      </v-row>
      <v-expansion-panels v-model="panel" accordion flat v-show="wohneinheiten.length > 0" :multiple="isSearchActive">
        <v-expansion-panel v-for="(wh,accIndex) in wohneinheiten" :key="wh.name+'-'+wh.id"
                           v-if="wohnEinheitenBewohner[wh.id] && wohnEinheitenBewohner[wh.id].length > 0">
          <v-expansion-panel-header v-show="!isSearchActive">
            <v-row dense class="text-vertical-align">
              <v-col cols="4">
                <h2 class="pt-4 pb-3">{{ wh.name }}</h2>
              </v-col>
              <v-col cols="6" class="pt-0 pb-0">
                <div class="pt-0 pb-0 block" v-if="openwarenkorbe && openwarenkorbe[wh.id]">
                  <v-row class="text-vertical-align">
                    <v-col cols="6" class="pt-0 pb-0 text-vertical-align">
                      <span class="body-2 pt-0 pb-0" :key="'diff0-'+rerendercountdown"
                            :hidden="diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) > 0  ? false: true">
                        <!--- TIME NOW() nur über php verweden damit Datumsvergleiche stimmen auch wenn client Time anders)-->
                          {{ moment(openwarenkorbe[wh.id]['bestelldatum']).locale("de").format('dddd DD.MM.YY HH:mm') }}<br><span
                          class="caption light" :key="'diff0-'+rerendercountdown">noch {{
                          diffTime(moment(), openwarenkorbe[wh.id]['bestelldatum'])
                        }}</span>
                      </span>
                      <span class="body-2 pt-0 pb-0"
                            v-if="openwarenkorbe[wh.id]['status'] == 'planung' && diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) < 1 && openwarenkorbe[wh.id]['ausloesung'] == 'manuell'">
                        Bestellung verpasst
                      </span>
                    </v-col>
                    <v-col cols="5" class="pt-0 pb-0 text-vertical-align">
                      <span class="body-2 pt-0 pb-0">
                        <span v-if="openwarenkorbe[wh.id]['status'] != 'abgelehnt'">
                            {{ moment(openwarenkorbe[wh.id]['lieferdatum']).locale("de").format('dddd DD.MM.YY') }}
                        </span>
                        <span v-else>
                          Bestellung abgelehnt

                        </span>

                      </span>
                    </v-col>
                    <v-col cols="1" class="pt-0 pb-0" v-if="openwarenkorbe[wh.id]['ausloesung'] != 'automatisch'">
                        <span v-if="openwarenkorbe[wh.id]['status'] != 'wartend'">
                          <v-img :src="require('@/assets/img/ico2021/betsellung-nicht-freigegeben.svg')" alt=""
                                 min-width="26" max-width="26"></v-img>
                        </span>
                      <span v-else>
                            <v-img :src="require('@/assets/img/ico2021/bestellung-freigegeben.svg')" alt=""
                                   min-width="26" max-width="26"></v-img>
                        </span>
                    </v-col>
                    <v-col cols="1" class="pt-0 pb-0 text-vertical-align" v-else>
                      <span class="body-2" v-if="openwarenkorbe[wh.id]['ausloesung'] == 'automatisch'">
                        Bestellung automatisch
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="2" class="pr-7">
                <div class="float-right">
                  <h3 class="bew-count normal float-left mr-3">
                    {{ getBewohnerFromWohneinheit(wh.id).length }}
                  </h3>
                  <div class=" float-right">
                    <v-img
                        :src="getBewohnerFromWohneinheit(wh.id).length > 0 ? require('@/assets/img/ico2021/bewohner.svg') : require('@/assets/img/ico2021/keine-bewohner.svg')"
                        alt="" width="19"></v-img>
                  </div>
                </div>

              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager v-if="planungsdaten">
            <v-row v-if="!isSearchActive">
              <v-col cols="7">
                <v-tabs v-model="tab[wh.id]" color="secondary" class="whtabs">
                  <v-tab class="body-1 book" @click="saveProdtab(0);saveTypeProdukte('Standard')">
                    Bewohner-Planung
                  </v-tab>
                  <v-tab class="body-1 book" @click="saveProdtab(1);saveTypeProdukte('Zusatz')">
                    Bestell-Vorschlag
                  </v-tab>
                  <v-tab class="body-1 book">
                    Bestellungen
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="5" v-if="tab[wh.id] == 0" class="pr-6">
                <div class="action-icon">
                  <v-hover v-slot="{ hover }" open-delay="0">
                   <span @click="printWHList(wh)" class="cursor-pointer text-center align-center">
                      <v-img
                          :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')"
                          alt="" max-width="35" class="cursor-pointer"></v-img>
                      <div class="block pt-0 caption text-center small">
                         Einräumliste
                      </div>
                    </span>
                  </v-hover>
                </div>
                <div class="action-icon">
                  <v-hover v-slot="{ hover }" open-delay="0">
                    <span @click="openMultipleDayNightPopup(wh)" class="cursor-pointer text-center align-center">
                        <v-img
                            :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')"
                            alt="" max-width="35" class="cursor-pointer"></v-img>
                        <div class="block pt-0 caption text-center small">
                           Tag-/Nacht-Karten
                        </div>
                      </span>
                  </v-hover>
                </div>
              </v-col>
              <v-col cols="5" v-if="tab[wh.id] == 1" class="pr-10">
                <div class="inline-block" style="width: 100px; float:right">
                  <v-hover v-slot="{ hover }" open-delay="0">
                    <span @click="printPlanung(wh)" class="cursor-pointer text-center align-center">
                      <v-img
                          :src="hover ? require('@/assets/img/ico2021_samesize/drucken-hover.svg') : require('@/assets/img/ico2021_samesize/drucken.svg')"
                          alt="" style="margin:0 auto;" max-width="30" class="cursor-pointer"></v-img>
                      <div class="block pt-0 caption text-center normal">
                        Bestell-Vorschlag
                      </div>
                    </span>
                  </v-hover>
                </div>
              </v-col>
            </v-row>
            <!--
            ******************************************************************************************************************************
            BEWOHNER PLANUNG
            ******************************************************************************************************************************
            --> <span :key="'tbi'+rerender"></span>
            <v-tabs-items v-model="tab[wh.id]">
              <v-tab-item>
                <v-card flat :key="'rerenderdrop'+rerender">
                  <v-card-text class="pa-0 ma-0">
                    <div v-if="openwarenkorbe[wh.id]">
                      <BewohnerDrop @showProdMenu="showProdMenu" @bewohnerNetzhose="openBewohnerNetzhose"
                                    @bewohnerNote="openBewohnerNote" @createPdf="downloadPdfInp(wh,...arguments)"
                                    v-for="b in wohnEinheitenBewohner[wh.id]"
                                    :wartend="openwarenkorbe[wh.id]['status'] == 'wartend'"
                                    :key="b.name+'-'+b.bewohnerID" :bewohner="b" :whid="wh.id"
                                    :bewohnerprodukte="bewohnerprodukte" :produkte="produkte"></BewohnerDrop>
                    </div>
                    <div v-else>Lade Daten / keine Daten</div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <!--
                ******************************************************************************************************************************
                PLANDATEN
                ******************************************************************************************************************************
                -->
                <v-card flat v-if="openwarenkorbe[wh.id]">
                  <v-card-text
                      v-if="planungsdaten != 'error' && openwarenkorbe[wh.id]['status'] != 'wartend' && openwarenkorbe[wh.id]['status'] != 'abgelehnt'">

                    <v-row>
                      <v-col cols="4" v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['status'] != 'wartend'">
                        <v-btn small :key="'dismissbtn2-'+rerendercountdown"
                               :hidden="openwarenkorbe[wh.id]['ausloesung'] == 'automatisch' ? true : false"
                               class="mt-0  pt-4 pb-3 white--text greybtn heavy" depressed @click="rejectWk(wh.id)"
                               light :disabled="diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) < 1">
                          <v-icon small class="pr-4">mdi-cart-remove</v-icon>
                          <span class="heavy">Keine Bestellung, wir haben genug Material</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['status'] != 'wartend'">
                        <v-btn small :key="'orderbtn1-'+rerendercountdown"
                               :hidden="openwarenkorbe[wh.id]['ausloesung'] == 'automatisch' ? true : false"
                               class="mx-2 mt-0  pt-4 pb-3 white--text heavy" depressed
                               @click="orderWk(wh.id,openwarenkorbe[wh.id]['bemerkungen'])" light color="primary"
                               :disabled="(diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) < 1)  || !isOrderValid">
                          <v-icon small class="pr-4">mdi-cart-check</v-icon>
                          <span class="heavy">Für {{
                              moment(openwarenkorbe[wh.id]['lieferdatum']).locale("de").format('dddd DD.MM.YY')
                            }} bestellen</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isOrderValid">
                      <v-col>
                        <v-alert
                            dense
                            outlined
                            type="error"
                        >
                          Bestellung enthält Produkte welche nicht mehr im Puras Sortiment erhältlich sind.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="9" class="pt-3" v-if="intervalle && intervalle[wh.id]">
                        Berechnete Bestellmenge für {{ (intervalle[wh.id].wochenintervall * 7) }} Tage aufgrund Ihrer
                        Bewohner-Planung.
                      </v-col>

                    </v-row>
                    <v-divider light class="mb-2"></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <h4 class="primary--text text-lg text-h5" style="font-weight: 300">
                          Hauptartikel
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row class="planunglegends">

                      <v-col cols="4" class="caption stk">
                        Bezeichnung
                      </v-col>

                      <v-col cols="1" class="caption">
                        Verbrauch (Stk)
                      </v-col>
                      <v-col cols="1" class="caption">
                        Vorschlag (Beutel)
                      </v-col>
                      <v-col cols="1" class="caption">
                        Rest<br> (Stk)
                      </v-col>
                      <v-col cols="2" class="caption">
                        Angepasste Bestellmenge (Anz)
                      </v-col>
                      <v-col cols="2" class="caption">
                        <div style="float:right">
                          Preis
                        </div>

                      </v-col>
                    </v-row>
                    <v-row v-for="prod in planungsdaten[wh.id]" :key="'pl-'+prod.ArtikelNrLAG">
                      <v-col cols="1">
                          <span v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0]['images'][0] ">
                         <img alt=""
                              :src="bilder[prod.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                              height="45"/>
                        </span>
                      </v-col>
                      <v-col cols="3">
                        <product-caption :artnr="prod.ArtikelNrLAG" :p="prod" :name="prod.Bezeichnung1"
                                         :einheit="prod.Verkaufseinheit"></product-caption>
                      </v-col>
                      <v-col cols="1">
                        {{ prod.stkverbrauch }}
                      </v-col>
                      <v-col cols="1">
                        {{ prod.vorschlag }}
                      </v-col>
                      <v-col cols="1">
                        <span
                            :class="(prod.angepasstemenge * prod.inhalt - prod.stkverbrauch) >= 0 ? 'success--text' : 'error--text'">
                            {{ prod.angepasstemenge * prod.inhalt - prod.stkverbrauch }}
                        </span>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field v-if="!prod.geloescht || prod.geloescht === 0" @input="setPlanungsdaten(wh.id)"
                                      dense light autocomplete="off" min="0" oninput="validity.valid||(value='');"
                                      max="1000" v-model="prod.angepasstemenge" class="body-2 mt-0 pt-0"
                                      type="number"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <div style="float:right">
                          CHF <span>{{ (prod.preis * prod.angepasstemenge).toFixed(2) }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row>
                      <v-col cols="6"></v-col>
                      <v-col cols="3">
                        <div style="float:right">
                          <b>Zwischen-Total Hauptartikel</b>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div style="float:right">
                          <b>CHF {{ getTotal(planungsdaten[wh.id]) }}</b>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row>
                      <v-col cols="2">
                        <h4 class="primary--text text-lg text-h5" style="font-weight: 300">
                          Zusatzartikel
                        </h4>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        Sie können Zusatzartikel hier hin ziehen:
                        <span class="primary--text cursor-pointer" @click="showProdMenu($event)">Zusatzartikel-Menu öffnen</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <drop @drop="addToWK($event,wh.id,'add')" class="copy proddrop pt-2"
                              style="width: 100%;min-height: 100px">
                          <span>
                            <v-row v-for="prod in openwarenkorbe[wh.id]['zusatzartikel']"
                                   :key="'wk-'+prod.ArtikelNrLAG">
                                <v-col cols="1">
                                    <span
                                        v-if="bilder[prod.ArtikelNrLAG] && bilder[prod.ArtikelNrLAG][0]['images'][0] ">
                                   <img alt=""
                                        :src="bilder[prod.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                                        height="45"/>
                                  </span>
                                </v-col>
                                <v-col cols="3">
                                   <product-caption :artnr="prod.ArtikelNrLAG" :name="prod.Bezeichnung1"
                                                    :einheit="prod.Verkaufseinheit"></product-caption>
                                </v-col>
                                <v-col cols="2">
                                    <v-hover v-slot="{ hover }" open-delay="0">
                                      <v-img v-if="hover" @click="removeFromWk(prod.ArtikelNrLAG,wh.id)"
                                             :src="require('@/assets/img/ico2021_samesize/loeschen-hover.svg')" alt=""
                                             max-width="30" class="mt-0 cursor-pointer"></v-img>
                                      <v-img v-else @click="removeFromWk(prod.ArtikelNrLAG,wh.id)"
                                             :src="require('@/assets/img/ico2021_samesize/loeschen.svg')" alt=""
                                             max-width="30" class="mt-0 cursor-pointer"></v-img>
                                    </v-hover>
                                </v-col>
                                <v-col cols="1"> </v-col>
                              <v-col cols="2">
                                <v-text-field min="0" oninput="validity.valid||(value='');"
                                              @input="addToWK(prod.ArtikelNrLAG,wh.id,prod.angepasstemenge)"
                                              v-model="prod.angepasstemenge" class="body-2 mt-0 pt-0"
                                              type="number"></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                 <div style="float:right">
                                 CHF <span>{{ (prod.preis * prod.angepasstemenge).toFixed(2) }}</span>
                                 </div>
                              </v-col>
                            </v-row>
                          </span>
                        </drop>
                      </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row>
                      <v-col cols="6"></v-col>
                      <v-col cols="3">
                        <div style="float:right">
                          <b>Zwischen-Total Zusatzartikel</b>
                        </div>
                      </v-col>
                      <v-col cols="2" v-if="openwarenkorbe[wh.id]">
                        <div style="float:right">
                          <b>CHF {{ getTotal(openwarenkorbe[wh.id]['zusatzartikel']) }}</b>
                        </div>
                      </v-col>
                      <v-col v-else cols="2">
                        <div style="float:right">
                          <b>CHF 0</b>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider light class="mb-0"></v-divider>
                    <v-row>
                      <v-col cols="6"></v-col>
                      <v-col cols="3">
                        <div style="float:right">
                          <b>TOTAL</b> (exkl. MwSt.)
                        </div>
                      </v-col>
                      <v-col cols="2" v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['zusatzartikel']">
                        <div style="float:right">
                          <b>CHF {{
                              (parseFloat(getTotal(planungsdaten[wh.id])) + parseFloat(getTotal(openwarenkorbe[wh.id]['zusatzartikel']))).toFixed(2)
                            }}</b>
                        </div>
                      </v-col>
                      <v-col v-else cols="2">
                        <b>CHF {{ getTotal(planungsdaten[wh.id]) }}</b>
                      </v-col>
                    </v-row>
                    <v-divider light class="mb-2"></v-divider>
                    <v-row v-if="!isOrderValid">
                      <v-col>
                        <v-alert
                            dense
                            outlined
                            type="error"
                        >
                          Bestellung enthält Produkte welche nicht mehr im Puras Sortiment erhältlich sind.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6"  :class=" openwarenkorbe[wh.id].ausloesung === 'automatisch' ? 'd-none' : ''">
                        <h4 class="primary--text text-lg text-h5" style="font-weight: 300">
                          Bemerkungen
                        </h4>
                        <v-textarea class="body-1 mt-6 opacity-75"
                                    :name="'bemerkungen-'+wh.id"
                                    rows="2"
                                    v-model="openwarenkorbe[wh.id]['bemerkungen']"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="6" v-if="openwarenkorbe[wh.id] && openwarenkorbe[wh.id]['status'] != 'wartend'">
                        <v-btn small :key="'orderbtn2-'+rerendercountdown"
                               :hidden="openwarenkorbe[wh.id]['ausloesung'] == 'automatisch' ? true : false"
                               class="mx-2 mt-16 float-right pt-4 pb-3  white--text" depressed
                               @click="orderWk(wh.id,openwarenkorbe[wh.id]['bemerkungen'])" light color="primary"
                               :disabled="((diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) < 1)) || !isOrderValid">
                          <v-icon small class="pr-4">mdi-cart-check</v-icon>
                          Für {{ moment(openwarenkorbe[wh.id]['lieferdatum']).locale("de").format('dddd DD.MM.YY') }}
                          bestellen
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!--
                  ******************************************************************************************************************************
                  PLANDATEN -> WK WARTEND
                  ******************************************************************************************************************************
                  -->

                  <v-card-text v-if="openwarenkorbe[wh.id]['status'] == 'abgelehnt'">
                    <v-card class="pa-6 " outlined>
                      Diese Bestellung wurde manuell abgelehnt.
                      <v-row>
                        <v-col cols="4">
                          <v-btn class="mt-8 y-5 white--text" depressed @click="cancelWk(wh.id)"
                                 :disabled="diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) > 0 ? false : true"
                                 light color="primary" :key="'diff2-'+rerendercountdown">
                            <v-icon class="pr-4">mdi-cart-remove</v-icon>
                            Ablehnung aufheben
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>

                  </v-card-text>

                  <v-card-text v-if=" openwarenkorbe[wh.id]['status'] == 'wartend'">
                    <v-card v-if="planungsdaten == 'error'" class="pa-6" outlined>
                      Bitte füllen Sie zuerst alle nötigen
                      <router-link to="lieferoptionen">Lieferoptionen</router-link>
                      aus
                    </v-card>
                    <v-card v-if="openwarenkorbe[wh.id]['status'] == 'wartend'" class="pa-6 " outlined>
                      <span class="text-body-2 black--text">
                        Sie haben zur Zeit einen Warenkorb im Bestell-Prozess. Die Lieferung ist am <u>{{
                          moment(openwarenkorbe[wh.id]['lieferdatum']).locale("de").format('dddd DD.MM.YY')
                        }}</u>.
                        <span class="text-body-2 black--text" :key="'diff3-'+rerendercountdown"
                              v-if="diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) > 0">
                            <br><br>
                            <b>Aktuell</b><br>
                            Änderungen möglich bis: {{
                            moment(openwarenkorbe[wh.id]['bestelldatum']).locale("de").format('dddd DD.MM.YY HH:mm')
                          }} Uhr (noch <b><span :key="'diff1-'+rerendercountdown">{{
                            diffTime(moment(), openwarenkorbe[wh.id]['bestelldatum'])
                          }}</span></b>)<br>
                            Lieferdatum: {{
                            moment(openwarenkorbe[wh.id]['lieferdatum']).locale("de").format('dddd DD.MM.YY')
                          }}<br><br>
                          <span
                              v-if="openwarenkorbe[wh.id]['future-bestelldatum'] && openwarenkorbe[wh.id]['future-lieferdatum']">
                            <b>Nächstes Bestelldatum</b>: <span class="primary--text text-decoration-underline">{{
                              moment(openwarenkorbe[wh.id]['future-bestelldatum']).locale("de").format('dddd DD.MM.YY HH:mm')
                            }}</span><br>
                          </span>
                        </span>
                        <span v-else>
                          Die Vorlaufszeit zur Bestellabgabe ist abgelaufen. Sie können keine Änderungen mehr an dieser Bestellung vornehmen. Bei Fragen kontaktieren Sie uns bitte.
                        </span>
                      <br>
                      </span>
                      <v-row>
                        <v-col cols="3">
                          <v-btn @click="$nextTick(()=>changeToBestellungen(wh.id))" class="mt-8 y-5" depressed
                                 color="danger">
                            <v-icon class="pr-4">mdi-shopping-search</v-icon>
                            Bestellung ansehen
                          </v-btn>
                        </v-col>
                        <v-col cols="4" :key="'colupdate-'+rerendercountdown">
                          <v-btn class="mt-8 y-5 white--text" depressed @click="cancelWk(wh.id)"
                                 :disabled="diffTimeStamp(moment(),openwarenkorbe[wh.id]['bestelldatum']) > 0 ? false : true"
                                 light color="primary" :key="'diff2-'+rerendercountdown">
                            <v-icon class="pr-4">mdi-cart-remove</v-icon>
                            Warenkorb zurücknehmen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <!--
                ******************************************************************************************************************************
                BESTELL HISTORY
                ******************************************************************************************************************************
                -->
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col v-if="orderedwarenkorbe[wh.id]">
                        <Bestellungen :whid="wh.id" :bestellungen="orderedwarenkorbe[wh.id]" :bilder="bilder"
                                      :superadmin="$attrs.superadmin"/>
                      </v-col>
                      <v-col v-else>
                        Noch keine Bestellungen vorhanden
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="infobox secondary">
        <v-row  class="py-2">
          <v-col cols="4" class="text-vertical-align py-0">
            <div class="text-h3">
              Haben Sie Fragen?<br/>Wir unterstützen Sie.
            </div>
          </v-col>
          <v-col cols="8" class="py-0">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-row class="text-vertical-align person">
                  <v-col cols="12" class="py-0 text-vertical-align">
                    <v-img class="image rounded-circle" :src="require('@/assets/img/heike-gail-puras.jpg')"
                           width="130"></v-img>
                    <div class="text">
                      <b>Heike Gail</b><br>
                      Kontinenzberaterin<br>
                      031 996 85 83<br>
                      <a href="mailto:Heike.Gail@puras.ch">
                        <v-hover v-slot="{ hover }" open-delay="0">
                          <v-img class="mt-2" :class="hover ? 'opacity-50' : ''" :src="require('@/assets/img/mail.svg')"
                                 width="25"></v-img>
                        </v-hover>
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-row class="text-vertical-align person">
                  <v-col cols="12" class="py-0 text-vertical-align">
                    <v-img class="image rounded-circle" :src="require('@/assets/img/karin-stadelmann-puras.jpg')"
                           width="130"></v-img>
                    <div class="text">
                      <b>Karin Stadelmann</b><br>
                      Fachexpertin<br>
                      031 996 86 81<br>
                      <a href="mailto:karin.stadelmann@puras.ch">
                        <v-hover v-slot="{ hover }" open-delay="0">
                          <v-img class="mt-2" :class="hover ? 'opacity-50' : ''" :src="require('@/assets/img/mail.svg')"
                                 width="25"></v-img>
                        </v-hover>
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider class="black bold"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" class="address py-0">
            <span>Puras AG</span>
            <span>Normannenstrasse 8</span>
            <span>3018 Bern</span>
            <span>031 996 85 85</span>
            <span><a href="mailto:contact@puras.ch">contact@puras.ch</a></span>
          </v-col>
          <v-col cols="4" class="footernav py-0 text-right">
            <span><a href="https://puras.ch/de/agb.html" target="_blank">AGB</a></span>
            <span><a href="https://puras.ch/de/impressum.html" target="_blank">Impressum</a></span>
            <span><a href="https://puras.ch/de/datenschutz.html" target="_blank">Datenschutz</a></span>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div>
      <vue-html-2-pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                      :paginate-elements-by-height="1400"
                      filename="x" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
                      pdf-orientation="portrait" pdf-content-width="685px" ref="bewohnerPDF">
        <section slot="pdf-content">
          <print-content-bewohner slot="pdf-contet" :bewohner="printBewohner" :day="printDay" :night="printNight"
                                  :wh="printWH" :produkte="produkte"></print-content-bewohner>
        </section>
      </vue-html-2-pdf>

      <vue-html-2-pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                      :paginate-elements-by-height="1400"
                      filename="x" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
                      pdf-orientation="portrait" pdf-content-width="685px" ref="PlanungPDF">
        <section slot="pdf-content">
          <print-content-planung slot="pdf-contet" :bilder="bilder" :wh="printWH" :planungsdaten="planungsdaten"
                                 :openwarenkorbe="openwarenkorbe"></print-content-planung>
        </section>
      </vue-html-2-pdf>

      <vue-html-2-pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                      :paginate-elements-by-height="1400"
                      filename="x" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
                      pdf-orientation="portrait" pdf-content-width="685px" ref="WhPDF">
        <section slot="pdf-content" v-if="printBewohner[0]">
          <print-content-wh :wh="printWH" :bewohner="printBewohner" :produkte="produkte"
                            :bewohnerprodukte="bewohnerprodukte"></print-content-wh>
        </section>
      </vue-html-2-pdf>
    </div>
    <!--
    ******************************************************************************************************************************
    BEWOHNER NOTIZ
    ******************************************************************************************************************************
    -->
    <v-dialog v-model="showBewohnerNote" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30" color="primary" class="pr-8">mdi-tooltip-edit-outline</v-icon>
            </v-col>
            <v-col cols="11" class="pl-8">
              Notiz für {{ noteBewohner.vorname }} {{ noteBewohner.name }} | {{ noteBewohner.zimmer }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          <v-textarea class="body-1 mt-6 opacity-75" rows="4" v-model="noteBewohner.note"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showBewohnerNote = false;">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="saveBewohner(noteBewohner,true);showBewohnerNote = false;">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--
    ******************************************************************************************************************************
    BEWOHNER NETZHOSE
    ******************************************************************************************************************************
    -->
    <v-dialog v-model="showBewohnerNetzhose" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="1">
              <v-icon size="30" color="primary" class="pr-8">mdi-alpha-n-circle-outline</v-icon>
            </v-col>
            <v-col cols="11" class="pl-8">
              Fixierhose für {{ netzhoseBewohner.vorname }} {{ netzhoseBewohner.name }} | {{ netzhoseBewohner.zimmer }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2">
          <v-select clearable :items="netzhosen" v-model="choosenNetzhose" label="Fixierhose" item-text="Bezeichnung1"
                    item-value="ArtikelNrLAG">
            <template v-slot:item="{ item }">
              <span v-if="bilder[item.ArtikelNrLAG] && bilder[item.ArtikelNrLAG][0]['images'][0] "
                    style="padding-right: 15px">
                <v-img
                    :src="bilder[item.ArtikelNrLAG][0]['images'][0].path+'?width=80'"
                    width="50"></v-img>
              </span>
              <span>{{ item.Bezeichnung1 }}</span>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showBewohnerNetzhose = false;">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="changeNetzhose();showBewohnerNetzhose = false;">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--
    ******************************************************************************************************************************
    MULTIPLE DAY NIGHT POP UPD
    ******************************************************************************************************************************
    -->

    <v-dialog v-model="showMultipleDayNightPopup" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              Tag-/Nacht-Karten Drucken
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          Wählen Sie bitte die gewünschten Bewohner um mehrere Tag-/Nacht-Karten zu drucken
          <v-divider class="my-4"></v-divider>

          <v-row>
            <v-col sm class="py-0 my-0" cols="10">
              <v-checkbox v-model="selectAllMultiPrint" label="Alle auswählen" dense></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense v-for="b in printBewohnerNachWH" :key="'mdnprint_'+b.bewohnerID">
            <v-col sm class="py-0 my-0" cols="10">
              <v-checkbox v-model="printMulipleDayNightIDs" :label="b.vorname+' '+b.name" :value="b.bewohnerID"
                          dense></v-checkbox>
            </v-col>
            <v-col sm class="py-0 my-0" cols="2">
              {{ b.zimmer }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showMultipleDayNightPopup = false;">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="printMultipleDayNightPdf()">Drucken</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {wohneinheitenGetters, wohneinheitenActions} from '@/obsvervables/wohneinheiten';
import {bewohnerGetters, bewohnerActions} from '@/obsvervables/bewohner';
import {produkteGetters, produkteActions} from '@/obsvervables/produkte';
import {planungsdatenGetters, planungsdatenActions} from '@/obsvervables/planungsdaten';
import {bewohnerprodukteGetters, bewohnerprodukteActions} from '@/obsvervables/bewohnerprodukte';
import {meineprodukteGetters} from '@/obsvervables/meineprodukte';
import {lieferoptionenGetters, lieferoptionenActions, lieferoptionenMutations} from '@/obsvervables/lieferoptionen';
import {prodtypGetters, prodtypActions, prodtypMutations} from '@/obsvervables/producttabs';
import {warenkorbGetters, warenkorbActions, warenkorbMutations} from '@/obsvervables/warenkorb';
import _ from 'lodash';

import BewohnerDrop from "@/components/app/bewohner/BewohnerDrop";
import Bestellungen from "@/components/app/planung/Bestellungen";
import moment from "moment";
import {Drop} from "vue-easy-dnd";

import VueHtml2Pdf from "@/components/app/print/VueHtml2Pdf";
import PrintContentBewohner from "@/components/app/print/PrintContentBewohner";
import PrintContentWh from "@/components/app/print/PrintContentWh";
import PrintContentPlanung from "@/components/app/print/PrintContentPlanung";
import ProductCaption from "@/components/app/products/ProductCaption";
import BestellungPlannningPrintModalDialog from "../components/app/planung/BestellungPlannningPrintModalDialog";
import {ActiveWohneinheitMixin} from "../mixins/ActiveWohneinheitMixin";
import {benutzerActions, benutzerGetters, benutzerMutations} from "../obsvervables/benutzer";

export default {
  name: 'Planung',
  props: ['kunde'],
  mixins: [ActiveWohneinheitMixin],
  components: {
    BestellungPlannningPrintModalDialog,
    ProductCaption,
    BewohnerDrop, Drop, Bestellungen,
    VueHtml2Pdf,
    PrintContentBewohner,
    PrintContentWh,
    PrintContentPlanung
  },
  data() {
    return {
      searchTerm: '',
      showBewohnerNote: false,
      showMultipleDayNightPopup: false,
      noteBewohner: {bewohnerID: 0, note: ''},
      showBewohnerNetzhose: false,
      netzhoseBewohner: {bewohnerID: 0, netzhose: ''},
      choosenNetzhose: 0,
      printBewohner: 0,
      printWH: 0,
      panel: false,
      printDay: [],
      printNight: [],
      adressen: [],
      printMulipleDayNightIDs: [],
      tab: [],
      timeout: false,
      rerender: 1,
      rerendercountdown: 1,
      timer: null,
    }
  },
  computed: {
    ...prodtypGetters,
    ...wohneinheitenGetters,
    ...bewohnerGetters,
    ...bewohnerprodukteGetters,
    ...meineprodukteGetters,
    ...planungsdatenGetters,
    ...lieferoptionenGetters,
    ...warenkorbGetters,
    ...produkteGetters,
    ...benutzerGetters,

    selectAllMultiPrint: {
      get: function () {
        return this.bewohner ? this.getBewohnerFromWohneinheit(this.printWH.id).length == this.printMulipleDayNightIDs.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.getBewohnerFromWohneinheit(this.printWH.id).forEach(function (bewohner) {
            selected.push(bewohner.bewohnerID);
          });
        }
        this.printMulipleDayNightIDs = selected;
      }
    },

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    },

    netzhosen() {
      return this.produkte.filter(function (prod) {
        return prod.isFixPants == 1;
      });
    },

    printBewohnerNachWH() {
      return this.getBewohnerFromWohneinheit(this.printWH.id);
    },

    wohnEinheitenBewohner() {
      let wohnEinheitenBewohner = []

      let searchTerm = this.searchTerm

      if (searchTerm && searchTerm.length > 0) {
        searchTerm = searchTerm.toLowerCase()
        // activate panels when filter changes
        this.panel = Array.from(Array(this.wohneinheiten.length).keys())
      }

      for (let i in this.wohneinheiten) {
        let whId = this.wohneinheiten[i].id
        let bewohner = this.bewohner.filter(b => b.wohneinheitenID == whId)
        bewohner = this.orderByZimmer(bewohner);

        if (searchTerm && searchTerm.length > 0) {
          bewohner = bewohner?.filter(b => b.name.toLowerCase().includes(searchTerm) || b.vorname?.toLowerCase().includes(searchTerm) || b.zimmer?.toLowerCase().includes(searchTerm))
        }

        /**
         * @author Renzo Müller <renzo@docono.io>
         * bug: deleted bewohner were shown when edited a note or added certain products
         * bugfix: ensure deleted bewohner are not shown
         **/
        wohnEinheitenBewohner[whId] = bewohner.filter(b => b.deleted === 0)
      }

      return wohnEinheitenBewohner
    },

    isSearchActive() {
      return !!(this.searchTerm && this.searchTerm.length > 0)
    },

    isOrderValid() {
      if (this.panel !== undefined && this.wohneinheiten[this.panel]) {
        let wh = this.wohneinheiten[this.panel]

        // check if no product is deleted
        if (this.planungsdaten[wh.id]) {
          if (this.planungsdaten[wh.id].filter(p => p.geloescht === 1).length > 0) {
            return false
          }
        }


        // check if no zusatzartikel is deleted
        // not needed currtently
        // let zusatzartikel = Object.values(this.openwarenkorbe[wh.id].zusatzartikel)
        // if (zusatzartikel.filter(z => this.produkte.find(p => z.ArtikelNrLAG === p.ArtikelNrLAG)).length < zusatzartikel.length) {
        //   return false
        // }
      } else {
        return false
      }

      return true
    }
  },
  methods: {
    ...wohneinheitenActions,
    ...bewohnerActions,
    ...bewohnerprodukteActions,
    ...planungsdatenActions,
    ...lieferoptionenActions,
    ...lieferoptionenMutations,
    ...prodtypActions,
    ...prodtypMutations,
    ...warenkorbActions,
    ...warenkorbMutations,
    ...produkteActions,
    ...benutzerActions,

    showProdMenu(e) {
      for (var i = 0; i < this.$parent.$children.length; i++) {
        if (this.$parent.$children[i].$el.id.indexOf('prodnav') != -1) {
          this.$parent.$children[i].$el.children[0].children[0].click();
        }
      }
    },

    openMultipleDayNightPopup(wh) {
      this.printWH = wh;
      this.printMulipleDayNightIDs = [];
      if (this.printBewohner != 0) {
        this.printBewohner.splice(0);
        this.printDay.splice(0);
        this.printNight.splice(0);
      }

      this.showMultipleDayNightPopup = true;
    },

    openBewohnerNote(bewohner) {
      this.showBewohnerNote = true;
      this.noteBewohner = bewohner;
    },

    openBewohnerNetzhose(bewohner) {
      this.showBewohnerNetzhose = true;
      this.choosenNetzhose = bewohner.netzhose;
      this.netzhoseBewohner = bewohner;
    },

    changeNetzhose() {
      this.netzhoseBewohner.netzhose = this.choosenNetzhose;
      this.saveBewohner(this.netzhoseBewohner);
    },

    //Planung ausdrucken
    async printPlanung(wh) {
      this.printWH = wh;
      await this.$refs.PlanungPDF.generatePdf();
      this.rerender = this.rerender + 1;

    },

    //Wohneinheit Planungs liste
    async printWHList(wh) {

      this.printBewohner = this.getBewohnerFromWohneinheit(wh.id);
      this.printWH = wh;

      // console.log(document.getElementsByTagName('canvas'));

      await this.$refs.WhPDF.generatePdf();
      this.rerender = this.rerender + 1;
    },

    delayFunc(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    //einzelner Bewohner Tag/Nacht
    async downloadPdfInp(wh, printBewohner, day, night) {

      this.printBewohner = printBewohner;
      this.printDay = day;
      this.printNight = night;
      this.printWH = wh;

      await this.$refs.bewohnerPDF.generatePdf();
      this.rerender = this.rerender + 1;
    },

    //mehrere Bewohner Tag/Nacht über Auswahlfeld
    async printMultipleDayNightPdf() {
      this.showMultipleDayNightPopup = false;
      this.printBewohner = [];
      this.printDay = [];
      this.printNight = [];
      this.printWH = false;

      var self = this;

      this.printBewohner = this.bewohner.filter(function (b) {
        return self.printMulipleDayNightIDs.indexOf(b.bewohnerID) != -1;
      });

      for (var pb in this.printBewohner) {

        if (self.bewohnerprodukte[self.printBewohner[pb].bewohnerID + '-d'] != undefined) {
          self.printDay.push(
              self.bewohnerprodukte[self.printBewohner[pb].bewohnerID + '-d']
          )
        }

        if (self.bewohnerprodukte[self.printBewohner[pb].bewohnerID + '-n'] != undefined) {
          self.printNight.push(
              self.bewohnerprodukte[self.printBewohner[pb].bewohnerID + '-n']
          )
        }
      }
      //all images loaded? dirty fix?
      await this.delayFunc(1200);

      await this.$refs.bewohnerPDF.generatePdf();
      this.rerender = this.rerender + 1;

    },


    getTotal(products) {
      var total = 0;
      for (var prod in products) {
        total += products[prod].preis * products[prod].angepasstemenge;
      }
      return total.toFixed(2);
    },

    changeToBestellungen(whid) {
      this.tab[whid] = 2;
      this.rerender = this.rerender + 1;
    },

    getBewohnerFromWohneinheit: function (wohneinheitID) {

      var bew = this.bewohner.filter(function (b) {
        return b.wohneinheitenID == wohneinheitID
      });

      bew = this.orderByZimmer(bew);

      return bew;
    },

    setPlanungsdaten: function (whid) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      var self = this;
      this.timeout = setTimeout(function () {
        self.savePlanungsdaten(whid);
      }, 750)
    },

    orderByZimmer(bew) {
      const reg = /[0-9]+/g
      return bew.sort((a, b) => {

        if (a.zimmer == null || b.zimmer == null) {
          return -1
        }
        let v0 = a.zimmer.replace(reg, v => v.padStart(10, '0'))
        let v1 = b.zimmer.replace(reg, v => v.padStart(10, '0'))
        return v0.localeCompare(v1)
      })
    },

    addToWK: function (e, whid, count) {

      //:accepts-data="(n) => n.InkoSortimentTyp == 'Zusatz'"
      if (e.data && e.data.InkoSortimentTyp != 'Zusatz') {
        this.$store.commit('loader/setmsg', 'Achtung Sie können nur Zusatzprodukte der Bestellung hinzufügen. Wechseln Sie bitte zum Tab "Bewohner-Planung".')
        this.$store.commit('loader/setmsgtype', 'error')
        this.$store.commit('loader/setmsgvisible', true)
        return false;
      }


      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      var self = this;
      this.timeout = setTimeout(function () {
        let artNr = e.data ? e.data.ArtikelNrLAG : e;
        self.addToOpenWarenkorb(artNr, whid, count, self.openwarenkorbe[whid]['bemerkungen']);
      }, 750)
    },


    diffTime: function (heute, bestelldatum) {

      var b = moment(heute);
      var a = moment(bestelldatum);

      var duration = moment.duration(a.diff(b));

      return Math.floor(duration.asDays()) + ' Tage ' + duration.hours() + 'h ' + duration.minutes() + 'm'
    },

    diffTimeStamp: function (heute, bestelldatum) {
      var b = moment(heute);
      var a = moment(bestelldatum);
      var duration = moment.duration(a.diff(b));

      return duration._milliseconds;
    },


    async orderWk(whid, bemerkungen) {
      await this.orderOpenWarenkorb(whid, bemerkungen);
      this.fetchOpenWarenkorbe();
      this.fetchOrderedWarenkorbe();
    },

    async rejectWk(whid) {
      await this.rejectOpenWarenkorb(whid);
      this.fetchOpenWarenkorbe();
      this.fetchOrderedWarenkorbe();
    },

    async cancelWk(whid) {
      await this.cancelOpenWarenkorb(whid);
      this.fetchOpenWarenkorbe();
      this.fetchOrderedWarenkorbe();
    },

    removeFromWk: function (artNr, whid) {
      this.removeFromOpenWarenkorb(artNr, whid, this.openwarenkorbe[whid]['bemerkungen']);
    },
  },
  created() {

    this.fetchWohneinheiten();
    this.fetchBenutzer();
    this.fetchBewohner();
    this.fetchBewohnerprodukte();
    this.fetchPlanungsdaten();
    this.fetchLieferoptionen();
    this.fetchOpenWarenkorbe();
    this.fetchOrderedWarenkorbe();
    this.fetchProdukte();
  },

  mounted() {
    //update alle 30sek time
    var self = this;
    this.rerender = this.rerender + 1;
    this.timer = setInterval(function () {
      self.rerendercountdown = self.rerendercountdown + 1;
    }, 30000)

    this.initActiveWohneinheit()
  },

  watch: {
    panel() {
      if(this.panel > -1) {
        //scroll active panel into view
        setTimeout(() => {
          this.$vuetify.goTo('.v-expansion-panel--active', {offset: this.panel == 0 ? 35 : 15});
        }, 400);
      }
      if (this.wohneinheiten && this.panel !== this.activeWohneinheit && this.panel !== false) {
        if (this.wohneinheiten[this.panel]) {
          benutzerMutations.setActiveWohneinheit(+this.wohneinheiten[this.panel].id)
        }
      }
    },

    isSearchActive: function () {
      if (this.isSearchActive) {
        this.panel = Array.from(Array(this.wohneinheiten.length).keys())
      } else {
        this.initActiveWohneinheit()
      }
    }
  }
}
</script>
<style lang="scss">


.multiprintpopup .v-input--selection-controls {
  margin-top: 0px !important;
}

.multiprintpopup .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.greybtn {
  background-color: #afafaf !important;
}

.whtabs {
  .v-tabs-slider-wrapper {
    display: none !important;
  }

  .v-tab {
    background: #fff;

    &:hover {
      opacity: 0.8 !important;
    }

  }

  .v-tab--active {
    color: #2b2b2b;
    border: 1px #3c3c3c solid;


    * {
      color: #fff !important;
    }
  }
}

.infobox {
  margin-top: 100px;
  padding: 20px;
  border-radius: 10px;
  max-width: 1060px;
}

.person {
  .image {
    max-width: 130px;
    margin-right: 1.5rem;
  }
  .text {
    font-size: 1.125rem;
    line-height: 1.3333em;
  }
}

.address {
  margin-top: 3px;
  font-size: 0.75rem;

  span {
    display: inline-block;
    font-family: "Avenir LT Std Heavy" !important;
    font-weight: 300;

    &:not(:last-child):after {
      content: "–";
      display: inline-block;
      margin: 0 10px;
    }

    a {
      font-family: "Avenir LT Std Heavy" !important;
      font-weight: 300;
      color: black;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid black;
      }
    }
  }
}

.footernav {
  margin-top: 3px;
  font-size: 0.75rem;

  span {
    display: inline-block;
    font-family: "Avenir LT Std Heavy" !important;
    font-weight: 300;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }

    a {
      font-family: "Avenir LT Std Heavy" !important;
      font-weight: 300;
      color: black;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid black;
      }
    }
  }
}

.v-divider.bold {
  border-width: 1.5px 0 0 0 !important;
}

</style>
