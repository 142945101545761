<template>
  <div id="forgotpw">
    <v-container>
      <v-row>
        <v-col cols="5">
            <v-img width="200" :src="require('@/assets/img/logo-inkopro.svg')" alt="" class="mt-16"></v-img>
          <h1>Ihr neues Passwort</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-if="msg" v-html="msg">

          </span>
          <br><br><br>
          <a href="/">
            <v-btn color="primary" depressed >zurück zu Inko Pro</v-btn>

          </a>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { loginUser } from '@/utils/auth'
import httpClient from "@/utils/httpClient";
import {bewohnerprodukteMutations} from "@/obsvervables/bewohnerprodukte";
export default {
  name: 'Login',
  data() {
    return {
      msg: '<b>BITTE WARTEN </b>Ihr neues Passwort wird generiert....'
    }
  },
  methods: {

    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async sendNewPassword(){

      await this.timeout(2500);

      const urlParams = new URLSearchParams(window.location.search);

      const res = await httpClient({
        url: process.env.VUE_APP_API_BASEURL+'send/newpw',
        method: 'post',
        data: { 't': urlParams.get('t')}
      })

      this.msg = res.msg;


    }

  },
  created() {
    this.sendNewPassword();
  }
}
</script>
<style scoped>
  #forgotpw{
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/login-bg-2021.jpg");
    background-size: cover;
    margin: 0 !important;
  }
  h1{
    margin-bottom: 60px;
    margin-top: 50px;
    font-size: 55px;
    color: #000;
    line-height: 60px;
    font-weight: 300;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0;
  }
</style>
